// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import React from 'react';
import { Container } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import { DataTableColumnType } from 'types/datatable';
import { UserReportTableType } from 'types/user/userTable';
import MENU from 'helpers/constants/menuConstants';
import GeneralSettings from 'helpers/constants/generalSettings';

import ActionButton from 'components/UI/buttons/actionButton';
import DangerButton from 'components/UI/buttons/dangerButton';

import ListPagation from 'components/UI/table/listPagination';
import { formatDateFromDatetime } from 'helpers/utils/date';
import LinkConstants from 'helpers/constants/linkConstants';
import { openTab } from 'helpers/utils/url';

import ReportedService from 'services/report';
import UserService from 'services/user';
import notify, { NotifyLevels } from 'services/notify';
import BlockReasonArea, { ReasonModes } from 'components/UI/management/blockReason';

import CommonListResults from 'components/UI/table/Results/commonList';
import styles from './contentUsers.module.scss';
import 'styles/helpers/tables/userListOverride.scss';

type ListProps = {
  title: string;
}

interface State {
  users: Array<UserReportTableType>;
  openProfileLink: string;
  paginationBlocked: boolean;
  selectedUser: string;
  pageCount: number;
  loadingItems: boolean;
  showReasonArea: boolean;
}

const { USE_NEWTAB } = GeneralSettings.REPORTED_USERS;

export default class ReportedUsers extends React.Component<ListProps, State> {
  columns: Array<DataTableColumnType>;

  reportedService: ReportedService;

  userService: UserService;

  constructor(props: ListProps) {
    super(props);
    this.state = {
      users: [],
      openProfileLink: '',
      paginationBlocked: false,
      selectedUser: '-1',
      pageCount: 1,
      loadingItems: false,
      showReasonArea: false,
    };
    this.reportedService = new ReportedService();
    this.userService = new UserService();
    this.handlePageChange = this.handlePageChange.bind(this);
    this.onApprove = this.onApprove.bind(this);
    this.onGottenBlockReason = this.onGottenBlockReason.bind(this);
    this.columns = this.formatTable();
  }

  componentDidMount(): void {
    this.loadUsers();
  }

  onApprove(userId: string): void {
    // @todo add in backend
    const status = this.userService.approveUser(userId);
    if (status === undefined) {
      notify('Approve user applied!', NotifyLevels.SUCCESS);
    }
  }

  async onBlock(userId: string, reason: string): Promise<any> {
    const status = await this.userService.blockUser(userId, reason);
    if (status === undefined) {
      notify('Block user applied!', NotifyLevels.SUCCESS);
    }
  }

  onGottenBlockReason(reason: string): void {
    this.setState({ showReasonArea: false });
    if (reason === '') { return; }
    const { selectedUser } = this.state;
    this.onBlock(selectedUser, reason);
  }

  getSelectedUser(): UserReportTableType {
    const { users, selectedUser } = this.state;
    return users.filter((user) => user.id === selectedUser)[0];
  }

  triggerBlockItemReasonDialog(userId: string): void {
    this.setState({
      showReasonArea: true,
      selectedUser: userId,
    });
  }

  async loadUsers(newPage?: number): Promise<any> {
    // only search for new query if not empty, else from saved query
    const curPage = newPage || 1;
    this.setState({ loadingItems: true });
    const response = await this.reportedService.getUsers(curPage);

    if (response && response.data) {
      this.setState({
        users: response.data,
        pageCount: response.pagination.pageCount,
      });
    }
    this.setState({ loadingItems: false });
  }

  formatTable(): Array<DataTableColumnType> {
    const formatUserName = (cell: string, row): React.ReactNode => (
      <div role="button" tabIndex={0} onClick={(): void => this.directToUserManagement(row.id)}>{formatDateFromDatetime(cell)}</div>
    );
    const formatEmail = (cell: string, row): React.ReactNode => (
      <div role="button" tabIndex={0} onClick={(): void => this.directToUserManagement(row.id)}>{formatDateFromDatetime(cell)}</div>
    );
    const formatRegistrationDate = (cell: string, row): React.ReactNode => (
      <div role="button" tabIndex={0} onClick={(): void => this.directToUserManagement(row.id)}>{formatDateFromDatetime(cell)}</div>
    );

    const approveCol = (cell, row): React.ReactNode => (
      <ActionButton
        title="Approve"
        onClick={(): void => { this.onApprove(row.id); }}
        isTextBold
        customStyle={GeneralSettings.REPORTED_USERS.APPROVE_BUTTON}
      />
    );

    const blockCol = (cell, row): React.ReactNode => (
      <DangerButton
        title="Block"
        onClick={(): void => { this.triggerBlockItemReasonDialog(row.id); }}
        isTextBold
        customStyle={GeneralSettings.REPORTED_USERS.BLOCK_BUTTON}
      />
    );

    return [{
      dataField: 'username',
      text: 'User Name',
      formatter: formatUserName,
      classes: styles.userTableCol,
    },
    {
      dataField: 'email',
      text: 'Email',
      formatter: formatEmail,
      classes: styles.userTableCol,
    },
    {
      dataField: 'creationDate',
      text: 'Creation Date',
      formatter: formatRegistrationDate,
      classes: styles.userTableCol,
    },
    {
      dataField: 'appv',
      text: '',
      formatter: approveCol,
      classes: styles.approveCol,
    },
    {
      dataField: 's',
      text: '',
      formatter: blockCol,
      classes: styles.blockCol,
    }];
  }

  directToUserManagement(id: string): void {
    const profileUrl = `${MENU.ADMIN_MAIN.USER_MANAGEMENT_PROFILE.split(':')[0]}:${id}`;
    this.setState({
      openProfileLink: profileUrl,
      selectedUser: id,
    });
  }

  handlePageChange(pageNum: number): void {
    this.loadUsers(pageNum);
  }

  render(): React.ReactNode {
    const {
      users,
      pageCount,
      openProfileLink,
      loadingItems,
      paginationBlocked,
      showReasonArea,
    } = this.state;
    const { title } = this.props;

    if (openProfileLink !== '') {
      this.setState({ openProfileLink: '' });
      if (USE_NEWTAB) {
        openTab(openProfileLink);
      } else {
        return (
          <Redirect
            to={{ pathname: openProfileLink, state: { user: this.getSelectedUser(), from: LinkConstants.REPORT_USERS } }}
          />
        );
      }
    }

    const userListEmpty = (
      <CommonListResults loadingItems={loadingItems} loadingTitle="Loading Users..." noResultsTitle="No users found" />
    );

    const userList = (
      <div>
        <div className={styles.reportUserWrapper}>
          <BootstrapTable
            keyField="fullname"
            data={users}
            columns={this.columns}
            BootstrapTable="bootstrap4"
            headerClasses={styles.userTableHeader}
            rowClasses={styles.userTableRow}
          />
        </div>
        <ListPagation
          firstPage={1}
          pageCount={pageCount}
          onClick={this.handlePageChange}
          isBlocked={paginationBlocked}
        />
      </div>
    );

    const showList = users && users.length > 0 ? userList : userListEmpty;

    return (
      <Container fluid className={styles.reportUsers}>
        <h3>{title}</h3>
        {showList}
        <BlockReasonArea
          page="content_users"
          mode={ReasonModes.Single}
          dialogTitle="Block content item"
          showArea={showReasonArea}
          onBlockReasonAreaUpdate={this.onGottenBlockReason}
        />
      </Container>
    );
  }
}
