/*  eslint-no-unused-vars */
import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import AlbumPictureManagementModal from 'components/AlbumManagement/AlbumPictureManagement';
import { PictureItem } from 'types/content-item';
import ListPagation from 'components/UI/table/listPagination';
import notify, { NotifyLevels } from 'services/notify';
import ReportService from 'services/report';
import AlbumService from 'services/album';

import CommonListResults from 'components/UI/table/Results/commonList';
import BlockReasonArea, { ReasonModes } from 'components/UI/management/blockReason';

import styles from './contentMedia.module.scss';
import ContentMediaItem from './ContentMediaItem';
import MediaMenuSection from './HeaderMenu';

type ContentProps = {}

type ContentState = {
  allContentSelected: boolean;
  allContentDesellected: boolean;
  pictureModalShow: boolean;
  pictures: Array<PictureItem>;
  selectedPictures: Array<PictureItem>;
  paginationBlocked: boolean;
  currentPage: number;
  pageCount: number;
  selectedItem: PictureItem;
  showReasonArea: boolean;
  reasonAreaMode: string;
  loadingItems: boolean;
}

export default class ContentMediaManagement extends React.Component<ContentProps, ContentState> {
  reportService: ReportService;

  albumService: AlbumService;

  constructor(props: ContentProps) {
    super(props);
    this.state = {
      selectedPictures: [],
      pictureModalShow: false,
      pictures: [],
      allContentSelected: false,
      allContentDesellected: false,
      paginationBlocked: false,
      currentPage: 1,
      pageCount: 1,
      selectedItem: {
        pictureId: '',
        pictureSource: '',
        userId: '',
        albumId: '',
      },
      showReasonArea: false,
      reasonAreaMode: ReasonModes.Single,
      loadingItems: false,
    };
    this.reportService = new ReportService();
    this.albumService = new AlbumService();
    this.handlePictureManagementClose = this.handlePictureManagementClose.bind(this);
    this.handleContentItemEnter = this.handleContentItemEnter.bind(this);
    this.handleContentItemSelect = this.handleContentItemSelect.bind(this);
    this.handleContentItemUnselect = this.handleContentItemUnselect.bind(this);
    this.triggerApproveItem = this.triggerApproveItem.bind(this);
    this.handleApproveItems = this.handleApproveItems.bind(this);
    this.triggerBlockItemReasonDialog = this.triggerBlockItemReasonDialog.bind(this);
    this.handleBlockItem = this.handleBlockItem.bind(this);
    this.onGottenBlockReason = this.onGottenBlockReason.bind(this);
    this.handleBlockItems = this.handleBlockItems.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.desellectAll = this.desellectAll.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount(): void {
    this.loadReportedImageItems();
  }

  onGottenBlockReason(reason: string, mode: string): void {
    this.setState({ showReasonArea: false });
    if (reason === '') { return; }
    const { selectedPictures } = this.state;
    if (mode === ReasonModes.Single) {
      const selectedPicture = selectedPictures[0];
      const userId = selectedPicture.userId || '';
      const albumId = selectedPicture.albumId || '';
      this.handleBlockItem(userId, albumId, selectedPicture.pictureId, reason, true);
    } else {
      this.handleBlockItems(reason);
    }
  }

  triggerBlockItemReasonDialog(mode = ReasonModes.Single): void {
    this.setState({ showReasonArea: true, reasonAreaMode: mode });
  }

  desellectAll(): void {
    this.setState({
      selectedPictures: [],
      allContentSelected: false,
      allContentDesellected: true,
    });
  }

  handleSelectAll(): void {
    const { pictures } = this.state;
    this.setState({
      allContentSelected: true,
      allContentDesellected: false,
      selectedPictures: pictures,
    });
  }

  handlePictureManagementClose(reloadItems: boolean): void{
    this.setState({ pictureModalShow: false });
    if (reloadItems) {
      this.loadReportedImageItems();
    }
  }

  handleContentItemEnter(item: PictureItem): void{
    this.setState({ pictureModalShow: true, selectedItem: item });
  }

  handleContentItemSelect(picture: PictureItem): void{
    const { allContentSelected } = this.state;
    if (allContentSelected) {
      this.desellectAll();
    }
    if (this.isDuplicate(picture)) { return; }

    this.setState((prevState) => ({
      ...prevState,
      selectedPictures: prevState.selectedPictures ? [...prevState.selectedPictures, picture] : prevState.selectedPictures,
    }));
    const { selectedPictures, pictures } = this.state;
    const allItemsSelected = selectedPictures.length > 0 && selectedPictures.length + 1 === pictures.length;
    if (allItemsSelected) {
      this.handleSelectAll();
    }
  }

  handleContentItemUnselect(picture: PictureItem): void{
    const { selectedPictures } = this.state;
    if (selectedPictures.length <= 0) { return; }
    const filteredPictures = selectedPictures.filter((item) => item.pictureId !== picture.pictureId);
    this.setState((prevState) => ({
      ...prevState,
      selectedPictures: filteredPictures,
    }));
  }

  isDuplicate(picture: PictureItem): boolean {
    const { selectedPictures } = this.state;
    return selectedPictures.find((item) => item.pictureId === picture.pictureId) !== undefined;
  }

  async handleApproveItems(): Promise<any> {
    const { selectedPictures } = this.state;
    // approve content items in queue
    selectedPictures.forEach(async (item, index) => {
      await this.triggerApproveItem(item.userId, item.albumId, item.pictureId);
      if (selectedPictures.length === index + 1) {
        this.loadReportedImageItems();
      }
    });
  }

  // remove completely
  async handleBlockItem(userId: string, albumId: string, pictureId: string, reason = '', reloadItems = false): Promise<any> {
    const response = await this.albumService.blockImageItem(userId, albumId, pictureId, reason);
    if (response === undefined) {
      notify('Block item applied!', NotifyLevels.SUCCESS);
    }
    if (reloadItems) {
      this.loadReportedImageItems();
    }
  }

  async handleBlockItems(queueReason: string): Promise<any> {
    const { selectedPictures } = this.state;
    selectedPictures.forEach(async (item, index) => {
      await this.handleBlockItem(item.userId, item.albumId, item.pictureId, queueReason);
      if (selectedPictures.length === index + 1) {
        this.loadReportedImageItems();
      }
    });
  }

  // remove from reported content
  async triggerApproveItem(userId: string, albumId: string, pictureId: string, reloadItems = false): Promise<any> {
    const response = await this.albumService.approveReportedImageItem(userId, albumId, pictureId);
    if (response === undefined) {
      notify('Delete Image from content items applied!', NotifyLevels.SUCCESS);
    }
    this.setState({ pictureModalShow: false });
    if (reloadItems) {
      this.loadReportedImageItems();
    }
  }

  handlePageChange(curPage: number): void {
    this.loadReportedImageItems(curPage);
  }

  async loadReportedImageItems(newPage?: number): Promise<any> {
    const curPage = newPage || 1;
    this.setState({ loadingItems: true });
    const response = await this.reportService.getImageItems(curPage);
    if (response.data !== undefined && response.data.length > 0) {
      const { pagination } = response;
      this.setState({
        pictures: response.data,
        currentPage: pagination.currentPage,
        pageCount: pagination.pageCount,
      });
    }
    this.setState({ loadingItems: false });
  }

  render(): React.ReactNode {
    const {
      allContentSelected,
      allContentDesellected,
      selectedPictures,
      pictures,
      pictureModalShow,
      paginationBlocked,
      pageCount,
      showReasonArea,
      reasonAreaMode,
      selectedItem,
      loadingItems,
    } = this.state;
    const width = { xs: 4, md: 3, lg: 2 };
    const MenuManagement = (
      <MediaMenuSection
        allSelected={allContentSelected}
        onSelectAllClick={this.handleSelectAll}
        onApproveClick={this.handleApproveItems}
        onUnselectAllClick={this.desellectAll}
        onBlockClick={(): void => this.triggerBlockItemReasonDialog(ReasonModes.Multiple)}
        selectedItemsCount={selectedPictures.length}
        maxItemsCount={pictures.length}
      />
    );

    const contentItemsEmpty = (
      <CommonListResults loadingItems={loadingItems} loadingTitle="Loading Content items..." noResultsTitle="No media found" />
    );

    const contentItems = pictures.map((item) => (
      <Col key={item.pictureId} xs={width.xs} md={width.md} lg={width.lg} className={styles.contentCol}>
        <ContentMediaItem
          picture={item}
          allSelected={allContentSelected}
          allDesellected={allContentDesellected}
          onItemEnter={this.handleContentItemEnter}
          onItemSelect={this.handleContentItemSelect}
          onItemUnselect={this.handleContentItemUnselect}
          onBlockItem={(): void => {
            this.handleContentItemSelect(item);
            this.triggerBlockItemReasonDialog(ReasonModes.Single);
          }}
        />
      </Col>
    ));
    const showItems = pictures && pictures.length > 0 ? contentItems : contentItemsEmpty;
    const userId = selectedItem.userId || '';
    return (
      <Container fluid>
        <Row className={styles.contentSelectRow}>
          <Col xs={3} md={3} lg={3} xl={2} className={styles.contentSelectRowCol}>
            <span>Most reported photos:</span>
          </Col>
          <Col xs={9} md={9} lg={9} xl={10} className={styles.menuCol}>
            {MenuManagement}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Row className={styles.contentRow}>
              {showItems}
            </Row>
          </Col>
        </Row>
        <ListPagation
          firstPage={1}
          pageCount={pageCount}
          onClick={this.handlePageChange}
          isBlocked={paginationBlocked}
        />
        <AlbumPictureManagementModal
          picture={selectedItem}
          userId={userId}
          albumId={selectedItem.albumId}
          pageType="report"
          onHide={this.handlePictureManagementClose}
          show={pictureModalShow}
        />
        <BlockReasonArea
          page="content_media"
          mode={reasonAreaMode}
          dialogTitle="Block content item"
          showArea={showReasonArea}
          onBlockReasonAreaUpdate={this.onGottenBlockReason}
        />
      </Container>
    );
  }
}
