import React from 'react';
import { Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import styles from './notAuthorized.module.scss';

const PageNotAuthorized = (): React.ReactElement => (
  <Container className={styles.nonAuthorizedContainer}>
    <h3>Please login to access Panel</h3>
    <span className={styles.loginLink}>
      <NavLink
        activeClassName={styles.navLinkActive}
        className={styles.navLink}
        to="/"
      >
        Login
      </NavLink>
    </span>
  </Container>
);

export default PageNotAuthorized;
