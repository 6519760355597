import React, { useState } from 'react';
import { useHistory } from 'react-router';

import DangerButton from 'components/UI/buttons/dangerButton';
import ActionButton from 'components/UI/buttons/actionButton';
import ReasonDialog from 'components/UI/dialogs/reasonDialog';

import { DangerAreaType, DangerButtonIds } from 'types/form/dangerArea';
import UserService from 'services/user';
import AlbumService from 'services/album';

import GeneralSettings from 'helpers/constants/generalSettings';
import MENU from 'helpers/constants/menuConstants';

import DangerDetailsRow from './detailsRow';
import styles from './dangerArea.module.scss';

interface DangerAreaProps {
  buttons: Array<DangerAreaType>;
  page: string;
  userId: string;
  albumId?: string;
  onDangerAreaUpdate: () => void;
}

enum Actions {
  BlockUser = 'blockUser',
  UnblockUser = 'unblockUser',
  DeleteUser = 'deleteUser',
  DeletePicture = 'deletePicture',
  DeleteCover = 'deleteCover',
  BlockAlbum = 'blockAlbum'
}

const DangerArea: React.FC<DangerAreaProps> = ({
  buttons,
  userId,
  albumId = '',
  onDangerAreaUpdate,
}) => {
  const width = {
    label: 8,
    value: 4,
  };
  const [showReason, setShowReason] = useState(false);
  const [reasonTitle, setReasonTitle] = useState('');
  const [action, setAction] = useState('');

  const history = useHistory();

  const userService = new UserService();
  const albumService = new AlbumService();

  const triggerAction = (e: React.MouseEvent, buttonId: string): void => {
    e.preventDefault();
    let title = ''; // modal header
    let act = '';
    let reasonVis = false;
    switch (buttonId) {
      // User
      case DangerButtonIds.BlockUser:
        title = 'Block user';
        act = Actions.BlockUser;
        reasonVis = true;
        break;
      case DangerButtonIds.UnblockUser:
        title = 'Unblock user';
        act = Actions.UnblockUser;
        reasonVis = true;
        break;
      case DangerButtonIds.DeleteUser:
        title = 'Delete user';
        act = Actions.DeleteUser;
        reasonVis = true;
        break;
      case DangerButtonIds.DeletePicture:
        title = 'Block picture';
        act = Actions.DeletePicture;
        reasonVis = true;
        break;
      // Album
      case DangerButtonIds.DeleteCover:
        title = 'Delete cover';
        act = Actions.DeleteCover;
        reasonVis = true;
        break;
      case DangerButtonIds.BlockAlbum:
        title = 'Block album';
        act = Actions.BlockAlbum;
        reasonVis = true;
        break;
      default:
        break;
    }
    setReasonTitle(title);
    setAction(act);
    setShowReason(reasonVis);
  };

  const handleReasonConfirm = async (reasonText: string): Promise<any> => {
    setShowReason(false);
    let status;
    switch (action) {
      case Actions.BlockUser:
        status = await userService.blockUser(userId, reasonText);
        if (status === undefined) {
          onDangerAreaUpdate();
        }
        break;

      case Actions.UnblockUser:
        status = await userService.unBlockUser(userId, reasonText);
        if (status === undefined) {
          onDangerAreaUpdate();
        }
        break;
      case Actions.DeletePicture:
        status = await userService.deletePicture(userId, reasonText);
        if (status === undefined) {
          onDangerAreaUpdate();
        }
        break;

      case Actions.DeleteUser:
        await userService.deleteUser(userId, reasonText);
        history.push(MENU.ADMIN_MAIN.USER_MANAGEMENT);
        break;

      case Actions.BlockAlbum:
        status = await albumService.blockAlbum(userId, albumId, reasonText);
        if (status === undefined) {
          onDangerAreaUpdate();
        }
        break;

      default:
        break;
    }
  };

  const handleReasonClose = (): void => {
    setShowReason(false);
  };

  const btnSizeSm = GeneralSettings.DANGER_AREA.BUTTON_SM_SIZE.padding;

  const ButtonItems = buttons.map((item) => {
    const commonButton = (
      <DangerButton
        title={item.buttonTitle}
        isDisabled={item.isDisabled}
        onClick={(e: React.MouseEvent): void => { triggerAction(e, item.id); }}
        customStyle={{ padding: btnSizeSm }}
      />
    );
    const enableButton = (
      <ActionButton
        title={item.buttonTitle}
        onClick={(e: React.MouseEvent): void => { triggerAction(e, item.id); }}
        isEnableButton
        customStyle={{ padding: btnSizeSm }}
      />
    );
    const showButton = item.buttonType && item.buttonType === 'enable' ? enableButton : commonButton;
    return (
      <DangerDetailsRow
        key={item.id}
        title={item.title}
        direction="none"
        value={(showButton)}
        colWidthLabel={{ xs: width.label, md: width.label, lg: width.label }}
        colWidthValue={{ xs: width.value, md: width.value, lg: width.value }}
        rowClassName="mb-2"
      />
    );
  });

  return (
    <div className={styles.dangerArea}>
      <h3 className={styles.dangerAreaTitle}>Danger Area</h3>
      {ButtonItems}
      <ReasonDialog
        title={reasonTitle}
        show={showReason}
        onHide={handleReasonClose}
        onConfirm={handleReasonConfirm}
      />
    </div>
  );
};

export default DangerArea;
