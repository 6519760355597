import React from 'react';
import ListResults, { Levels } from 'components/UI/table/Results';

interface CommonListResultsProps {
  loadingItems: boolean;
  loadingTitle?: string;
  noResultsTitle?: string;
  showCondition?: boolean;
}

// Common implementation ListResults to ready use in lists, pages
const CommonListResults: React.FC<CommonListResultsProps> = ({
  loadingItems = false,
  loadingTitle = 'Loading items...',
  noResultsTitle = 'No results found',
  showCondition = true,
}) => {
  const msg = loadingItems ? loadingTitle : noResultsTitle;
  const level = loadingItems ? Levels.Loading : Levels.Alert;
  const userListEmpty = showCondition ? (
    <ListResults notice={msg} level={level} />
  ) : null;
  return (
    <>
      {userListEmpty}
    </>
  );
};

export default CommonListResults;
