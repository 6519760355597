/* eslint-disable class-methods-use-this */
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import React from 'react';
import { Container } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import { DataTableColumnType } from 'types/datatable';
import { UserTableType } from 'types/user/userTable';
import formatAlertStatus from 'components/UserManagement/AlertStatus';
import MENU from 'helpers/constants/menuConstants';
import capitalizeFirstChar from 'helpers/utils/string';
import { formatThousandValue } from 'helpers/utils/number';
import ListPagation from 'components/UI/table/listPagination';
import { formatDateFromDatetime } from 'helpers/utils/date';
import UserManagementSearchForm from 'components/UserManagement/Search';
import UserService from 'services/user';
import ValidatedImage from 'assets/content/validated.svg';

import CommonListResults from 'components/UI/table/Results/commonList';
import styles from './userManagementList.module.scss';
import 'styles/helpers/tables/userListOverride.scss';

type ListProps = {
  title: string;
}

interface State {
  users: Array<UserTableType>;
  openProfileLink: string;
  paginationBlocked: boolean;
  selectedUser: string | number;
  searchQuery: string;
  pageCount: number;
  currentPage: number;
  searchStarted: boolean;
  usersTotal: number;
  loadingItems: boolean;
  itemsCount: number;
}

export default class UserManagementList extends React.Component<ListProps, State> {
  columns: Array<DataTableColumnType>;

  userService: UserService;

  constructor(props: ListProps) {
    super(props);
    this.state = {
      users: [],
      openProfileLink: '',
      paginationBlocked: false,
      selectedUser: -1,
      searchQuery: '',
      pageCount: 1,
      currentPage: 1,
      searchStarted: false,
      usersTotal: 0,
      loadingItems: false,
      itemsCount: 0,
    };
    this.userService = new UserService();
    this.columns = [];
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.formatTable();
  }

  componentDidMount(): void {
    this.loadUsersTotal();
  }

  onSearchSubmit(searchTerm: string): void {
    this.setState({ searchStarted: true });
    if (searchTerm.length >= 3) {
      this.loadUsers(searchTerm, 1);
    } else {
      this.loadLatestRegisterdUsers();
    }
  }

  getSelectedUser(): UserTableType {
    const { users, selectedUser } = this.state;
    return users.filter((user) => user.id === selectedUser)[0];
  }

  async loadUsersTotal(): Promise<any> {
    const response = await this.userService.getUsersTotal();
    if (response && typeof response === 'number') {
      this.setState({
        usersTotal: response,
      });
    }
  }

  async loadUsers(searchTerm: string, newPage?: number): Promise<any> {
    const { searchQuery } = this.state;
    const search = searchTerm !== '' ? searchTerm : searchQuery;
    const curPage = newPage || 1;
    this.setState({ searchQuery: search, loadingItems: true });
    const response = await this.userService.getUsers(search, curPage);

    if (response && response.data) {
      this.setState({
        users: response.data,
        pageCount: response.pagination.pageCount,
        currentPage: response.pagination.currentPage,
        itemsCount: response.total || 0,
      });
    }
    this.setState({ loadingItems: false });
  }

  async loadLatestRegisterdUsers(): Promise<any> {
    this.setState({ loadingItems: true });
    const response = await this.userService.getLatestRegisteredUsers(20);
    if (response && response.data) {
      this.setState({
        users: response.data,
        pageCount: 1,
        currentPage: 1,
        itemsCount: response.total || 0,
        searchQuery: ''
      });
    }
    this.setState({ loadingItems: false });
  }

  formatTable(): void {
    const formatAlert = (cell: string[]): React.ReactNode => formatAlertStatus(cell);

    const validated = false; // @todo add in backend field
    const validatedBadge = validated ? (
      <div className={styles.validatedBadge}>
        <img src={ValidatedImage} alt="validated" />
      </div>
    ) : (<span />);

    const formatFullName = (cell: string): React.ReactNode => (
      <div>
        {cell}
        {validatedBadge}
      </div>
    );

    const formatCapitalizedValue = (cell: string): React.ReactNode => (
      <div>{capitalizeFirstChar(cell)}</div>
    );

    const formatRegistrationDate = (cell: string): React.ReactNode => (
      <div>{formatDateFromDatetime(cell)}</div>
    );

    this.columns = [
      {
        dataField: 'fullname',
        text: 'Full name',
        formatter: formatFullName,
        classes: styles.userTableCol,
      },
      {
        dataField: 'username',
        text: 'Username',
        classes: styles.userTableCol,
      },
      {
        dataField: 'phone',
        text: 'Phone number',
        classes: styles.userTableCol,
      },
      {
        dataField: 'creationDate',
        text: 'Creation date',
        formatter: formatRegistrationDate,
        classes: styles.userTableCol,
      },
      {
        dataField: 'storagePlan',
        text: 'Storage plan',
        classes: styles.userTableCol,
        formatter: formatCapitalizedValue,
      },
      {
        dataField: 'status',
        text: 'Status',
        classes: styles.userTableCol,
        formatter: formatCapitalizedValue,
      },
      {
        dataField: 'alertStatus',
        text: 'Alert status',
        formatter: formatAlert,
        classes: styles.userTableCol,
      }];
  }

  directToUserManagement(id: string | number): void {
    const profileUrl = `${MENU.ADMIN_MAIN.USER_MANAGEMENT_PROFILE.split(':')[0]}:${id}`;
    this.setState({
      openProfileLink: profileUrl,
      selectedUser: id,
    });
  }

  handlePageChange(pageNum: number): void {
    const { currentPage } = this.state;
    if (currentPage !== pageNum) {
      this.loadUsers('', pageNum);
    }
  }

  render(): React.ReactNode {
    const {
      users,
      searchQuery,
      openProfileLink,
      paginationBlocked,
      usersTotal,
      itemsCount,
      searchStarted,
      loadingItems,
    } = this.state;
    if (openProfileLink) {
      return (
        <Redirect
          to={{ pathname: openProfileLink, state: { user: this.getSelectedUser(), searchQuery } }}
        />
      );
    }

    const TotalAmount = (
      <div className={styles.totalAmount}>
        <span>Total amount of users in App: </span>
        <b>{formatThousandValue(usersTotal)}</b>
      </div>
    );

    const rowEvents = {
      onClick: (e, row): void => {
        this.directToUserManagement(row.id);
      },
    };

    const userListEmpty = (
      <CommonListResults
        loadingItems={loadingItems}
        loadingTitle="Loading Users..."
        noResultsTitle="No users found"
        showCondition={searchStarted}
      />
    );

    const userList = (
      <div>
        <BootstrapTable
          keyField="id"
          data={users}
          columns={this.columns}
          BootstrapTable="bootstrap4"
          headerClasses={styles.userTableHeader}
          rowEvents={rowEvents}
          rowClasses={styles.userTableRow}
        />
        <ListPagation
          firstPage={1}
          pageCount={Math.ceil(itemsCount / 20)}
          onClick={this.handlePageChange}
          isBlocked={paginationBlocked}
        />
      </div>
    );

    const showList = users && users.length > 0 ? userList : userListEmpty;

    return (
      <Container fluid>
        {TotalAmount}
        <UserManagementSearchForm
          onSubmit={this.onSearchSubmit}
        />
        <h3>User list</h3>
        {showList}
      </Container>
    );
  }
}
