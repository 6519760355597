import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { Container } from 'react-bootstrap';

import LayoutHeader from './header/header';
import LayoutContent from './content';
import LayoutFooter from './footer';

import styles from './defaultLayout.module.scss';

function DefaultLayout({ children }: InferProps<typeof DefaultLayout.propTypes>): React.ReactElement {
  const layoutClass = `justify-content-md-center ${styles.layoutContainer}`;
  return (
    <Container className={layoutClass} fluid>
      <LayoutHeader />
      <LayoutContent>{children}</LayoutContent>
      <LayoutFooter />
    </Container>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default DefaultLayout;
