import * as React from 'react';

import {
  createStyles, makeStyles, Theme,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import CRMTableRow from './CRMTableRow';
import Toolbar from '@mui/material/Toolbar';
import { alpha } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';

interface CrmUserTableProps {
  rows: any;
  activeFilter: (b: boolean) => void
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: 'action', numeric: false, disablePadding: false, label: 'َ',
  },
  {
    id: 'userId', numeric: false, disablePadding: false, label: 'َUser ID',
  },
  {
    id: 'username', numeric: false, disablePadding: true, label: 'َUsername',
  },
  {
    id: 'fullname', numeric: false, disablePadding: false, label: 'Full name',
  },
  {
    id: 'phoneNumber', numeric: false, disablePadding: false, label: 'Phone number',
  },
  {
    id: 'email', numeric: false, disablePadding: false, label: 'Email',
  },
  {
    id: 'category', numeric: false, disablePadding: false, label: 'Category',
  },
  {
    id: 'subCategory', numeric: false, disablePadding: false, label: 'Sub-category',
  },
  {
    id: 'age', numeric: false, disablePadding: false, label: 'Age',
  },
  {
    id: 'gender', numeric: false, disablePadding: false, label: 'Gender',
  },
  {
    id: 'country', numeric: false, disablePadding: false, label: 'Country',
  },
  {
    id: 'job', numeric: false, disablePadding: false, label: 'Job',
  },
  {
    id: 'information', numeric: false, disablePadding: false, label: 'Information',
  },
  {
    id: 'tags', numeric: false, disablePadding: false, label: 'Tags',
  },
  {
    id: 'social', numeric: false, disablePadding: false, label: 'Social media',
  },
  {
    id: 'date', numeric: false, disablePadding: false, label: 'Date of signup',
  },
];

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));
interface Data {
  action: string;
  userId: string;
  username: string;
  fullname: string;
  phoneNumber: string;
  email: string;
  category: any;
  subCategory: string;
  age: number;
  gender: string;
  country: string;
  job: string;
  information: string;
  tags: string;
  social: string;
  date: string;
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
}
interface EnhancedTableToolbarProps {
  numSelected: number;
  activeFilter: (b: boolean) => void;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected, activeFilter } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
      className="justify-content-end"
    >
      <Tooltip title="Filter list">
        <IconButton onClick={() => activeFilter(true)}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

function EnhancedTableHead(props: EnhancedTableProps) {

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            <TableSortLabel>
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const CrmUserTable: React.FC<CrmUserTableProps> = ({ rows, activeFilter }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar activeFilter={activeFilter} numSelected={0} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead classes={classes} />
            <TableBody>
              {rows.map((row: any, index) => <CRMTableRow key={index} row={row} index={index} />)}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default CrmUserTable;
