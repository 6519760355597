import React, { ReactNode } from 'react';
import styles from './authLayout.module.scss';

interface ContentProps {
  children: ReactNode;
}

const LayoutContent: React.FC<ContentProps> = ({ children }) => (
  <div className={styles.layoutContent}>
    {children}
  </div>
);

export default LayoutContent;
