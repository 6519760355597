/* eslint-disable react/jsx-one-expression-per-line */
import React, { MouseEvent } from 'react';

import capitalizeFirstChar from 'helpers/utils/string';
import IMAGE_RESOLUTIONS from 'helpers/constants/imageResolutions';
import visibleImage from 'assets/albums/visible.svg';
import hiddenImage from 'assets/albums/hidden.svg';
import 'styles/helpers/tooltips/albumPrivate.scss';
import PrivateImage from 'assets/albums/private.png';

import AlbumService from 'services/album';
import UserService from 'services/user';
import styles from './albumItem.module.scss';

type AlbumProps = {
  id: string;
  title: string;
  level: string;
  source?: string;
  sharedUserId?: string;
  sharedUserName?: string;
  userId: string;
  isHidden?: boolean;
  onAlbumEnter: Function;
}

type AlbumState = {
  isCopied: boolean;
}

const PrivateType = 'PRIVATE';
const SecretType = 'SECRET';

export default class AlbumItem extends React.Component<AlbumProps, AlbumState> {
  constructor(props: AlbumProps) {
    super(props);
    this.state = { isCopied: false };
    this.handleAlbumEnter = this.handleAlbumEnter.bind(this);
  }

  handleCopyUserID = (id: string): void => {
    navigator.clipboard.writeText(id);
    this.setState({
      isCopied: true,
    });
    setTimeout((): void => {
      this.setState({
        isCopied: false,
      });
    }, 2000);
  }

  handleAlbumEnter(e: MouseEvent, accessLevel: string): void {
    e.preventDefault();
    const { id, onAlbumEnter } = this.props;
    if (accessLevel !== PrivateType && accessLevel !== SecretType) {
      onAlbumEnter(id);
    }
  }

  render(): React.ReactNode {
    const {
      title,
      level,
      source = '',
      sharedUserId = '',
      isHidden = false,
      id: albumsId,
      userId,
      sharedUserName,
    } = this.props;
    const { isCopied } = this.state;
    const textLevel = level === SecretType ? PrivateType : level;
    const unLink = textLevel === PrivateType ? styles.userPrivateItem : '';
    let scaledSource = source !== '' ? `${source}${IMAGE_RESOLUTIONS.IMAGE_MEDIUM}` : ''; // -m 400x400

    const levelLabel = capitalizeFirstChar(textLevel);

    if ((level === PrivateType || level === SecretType) && sharedUserId === '') {
      scaledSource = PrivateImage;
    }
    const bgUrlStyle = scaledSource !== '' ? { backgroundImage: `url(${scaledSource}` } : {};
    const itemCl = `${styles.userAlbumsItem} ${unLink}`;

    let visibilityIcon;
    if (level === SecretType) {
      const toggleImage = isHidden ? hiddenImage : visibleImage;
      const tipText = isHidden ? 'Hidden' : 'Visible';
      visibilityIcon = (
        <div className={styles.visibilityIcon}>
          <div className="tooltip">
            <img src={toggleImage} alt="visible" />
            <span className="tooltiptext">{tipText}</span>
          </div>
        </div>
      );
    }

    const albumService = new AlbumService();
    const userService = new UserService();

    const handleRecalculateStorage = async (userID: string, albumId: string): Promise<void> => {
      await albumService.recalculateAlbumStorage(userID, albumId);
      await userService.getUserDetails(userID);
    };

    const handleOpenUserProfile = (): void => {
      const url = `/#/user-profile:${sharedUserId}`;
      window.open(url, '_blank');
    };

    return (
      <div
        className={itemCl}
        role="button"
        tabIndex={0}
        style={bgUrlStyle}
        onClick={(e: MouseEvent): void => this.handleAlbumEnter(e, level)}
      >
        {visibilityIcon}
        <button
          className={`${styles.btn} ${styles.btnCopy} ${isCopied ? styles.active : ''}`}
          onClick={(): void => this.handleCopyUserID(albumsId)}
          type="button"
        >
          {isCopied ? 'Copied' : 'Copy album ID'}
        </button>
        {sharedUserId === '' && (
          <button
            className={`${styles.btn} ${styles.btnRecal}`}
            onClick={() => handleRecalculateStorage(userId, albumsId)}
            type="button"
          >
            Recalculate storage
          </button>
        )}
        <div className={styles.userAlbumsItemLabel}>
          <div className="d-flex justify-content-between">
            <div>
              <div>{title}</div>
              <div>{levelLabel}</div>
            </div>
            <div className="mr-3">
              {sharedUserId !== '' && (
                <>
                  <div>Shared by</div>
                  <button type="button" className={styles.sharedUser} onClick={() => handleOpenUserProfile()}>
                    {sharedUserName}
                  </button>
                </>
              )}
            </div>
          </div>

        </div>
      </div>
    );
  }
}
