/* eslint-disable class-methods-use-this */
/* eslint-disable dot-notation */
// User Action History service (get actions)
import {
  ActionHistoryListType,
  ActionHistoryListailedType,
  ActionHistoryUndoType,
} from 'types/services/actionHistory';

import API_PATH from 'helpers/constants/apiPaths';
import { api } from 'helpers/server/api';

export default class ActionHistoryService {
  mainPath: string;

  constructor() {
    this.mainPath = API_PATH.ACTION_HISTORY.MAIN;
  }

  async getUserActions(userId: string, pageOffset = 1, pageLimit = 20): Promise<ActionHistoryListType | ActionHistoryListailedType> {
    // in backend page is counting from 0, but in front-end it's first page = 1
    const path = `/v1/${this.mainPath}/${userId}/${API_PATH.ACTION_HISTORY.LIST}`;
    const response = await api.get(path, { offset: (pageOffset - 1) * pageLimit, limit: pageLimit });
    if (response && response['content']) {
      const pageAble = response['pageable'] || null;
      return {
        data: response['content'],
        pagination: {
          total: response['total'],
          offset: pageAble !== null ? pageAble['offset'] : 0,
          size: pageAble !== null ? pageAble['size'] : pageLimit,
        },
      };
    }
    return { data: undefined, pagination: undefined, status: response.status };
  }

  async undoAction(id: string, reason: string): Promise<ActionHistoryUndoType> {
    // @todo send and get real result when server side ready
    const useMock = true;
    let response;
    if (!useMock) {
      const path = `/v1/${this.mainPath}/${id}/${API_PATH.ACTION_HISTORY.UNDO}`;
      response = await api.post(path, { id, reason });
    } else {
      response = { data: { status: 1 } };
    }
    return response;
  }
}
