import React from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';

import styles from './textArea.module.scss';

interface TextAreaInputProps {
  controlId: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  disabled: boolean;
  rows?: number;
  label?: string;
  errorNotice?: string;
  inputIcon?: string;
  textAreaClass?: string;
  isResizable?: boolean;
}

const FormTextAreaInput: React.FC<TextAreaInputProps> = ({
  controlId,
  label,
  value,
  rows = 5,
  onChange,
  placeholder,
  errorNotice,
  disabled,
  inputIcon = '',
  textAreaClass = '',
  isResizable = false,
}) => {
  const inputIconContainer = (): React.ReactElement => {
    switch (inputIcon) {
      case 'search':
        return <div className={styles.textIcon}><FaSearch /></div>;
      default:
        return <i />;
    }
  };
  const resizableClass = !isResizable ? styles.textAreaNonResizable : '';
  const containerClass = `${textAreaClass} ${resizableClass}`;
  return (
    <FormGroup controlId={controlId}>
      <Form.Label className={styles.textLabel}>{label}</Form.Label>
      {inputIconContainer()}
      <Form.Control
        className={containerClass}
        as="textarea"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        rows={rows}
        disabled={disabled}
        aria-label="textarea"
      />
      <Form.Text><div className={styles.errorField}>{errorNotice}</div></Form.Text>
    </FormGroup>
  );
};

export default FormTextAreaInput;
