import React from 'react';
import { NavLink } from 'react-router-dom';
import MENU from 'helpers/constants/menuConstants';
import styles from '../submenu.module.scss';

const ENABLED = false; // temporary

const detectUserMngActiveLink = (match, locationAddress): boolean => {
  const { pathname } = locationAddress;
  const pattern = /(user-profile *|album-management *)/i;
  const found = pathname.match(pattern);
  return match || found;
};

const verificationMenuLink = ENABLED ? (
  <li className={styles.navItem}>
    <NavLink
      activeClassName={styles.navLinkActive}
      className={styles.navLink}
      to={MENU.ADMIN_MAIN.USER_VERIFICATION}
    >
      Verification requests
    </NavLink>
  </li>
) : (null);

const UserManagementSubMenu = (
  <ul className={styles.subnav}>
    <li className={styles.navItem}>
      <NavLink
        activeClassName={styles.navLinkActive}
        className={styles.navLink}
        isActive={detectUserMngActiveLink}
        to={MENU.ADMIN_MAIN.USER_MANAGEMENT}
      >
        User Managament
      </NavLink>
    </li>
    {verificationMenuLink}
  </ul>
);

export default UserManagementSubMenu;
