import React, { MouseEvent } from 'react';
import {
  Modal,
  Row,
  Col,
} from 'react-bootstrap';
import DialogConfirm from 'components/UI/dialogs/dialog';
import AlbumService from 'services/album';
import ReportService from 'services/report';
import notify, { NotifyLevels } from 'services/notify';
import { PictureItem, ImageItemDetails } from 'types/content-item';
import ErrorContainer from 'components/UI/error';
import BlockReasonArea from 'components/UI/management/blockReason';

import { initPictureData } from 'models/albums/pictureManagement';
import styles from './albumPicture.module.scss';
import AlbumPictureDetails from './PictureDetails';

type AlbumPictureModalProps = {
  userId: string;
  albumId?: string;
  picture: PictureItem;
  onHide: (reloadItems: boolean) => void;
  show: boolean;
  pageType?: string;
}
interface State {
  pictureData: ImageItemDetails;
  dialogShow: boolean;
  dialogTriggerer: string;
  imageRemoved: boolean;
  showReasonArea: boolean;
}

enum dialogTriggerers {
   approveReport = 'approveReport',
}

export default class AlbumPictureManagementModal extends React.Component<AlbumPictureModalProps, State> {
  albumService: AlbumService;

  reportService: ReportService;

  constructor(props: AlbumPictureModalProps) {
    super(props);
    this.state = {
      pictureData: initPictureData,
      dialogShow: false,
      dialogTriggerer: '',
      imageRemoved: false,
      showReasonArea: false,
    };
    this.albumService = new AlbumService();
    this.reportService = new ReportService();
    this.handleDialogConfirm = this.handleDialogConfirm.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.onGottenBlockReason = this.onGottenBlockReason.bind(this);
    this.triggerApproveReport = this.triggerApproveReport.bind(this);
    this.triggerBlockPicture = this.triggerBlockPicture.bind(this);
  }

  componentDidUpdate(prevProps): void {
    const { picture } = this.props;
    if (picture !== prevProps.picture) {
      this.loadDetails();
    }
  }

  onGottenBlockReason(reason: string): void {
    this.setState({ showReasonArea: false });
    if (reason === '') { return; }
    const {
      userId,
      albumId = '-1',
      picture,
    } = this.props;
    this.blockItem(userId, albumId, picture.pictureId, reason);
  }

  onConfirmModalClose(reloadItems = false): void {
    const { onHide } = this.props;
    this.setState({ dialogShow: false });
    setTimeout((): void => { onHide(reloadItems); }, 1000);
  }

  triggerBlockReasonDialog(): void {
    this.setState({ showReasonArea: true });
  }

  handleDialogClose(): void{
    this.setState({ dialogShow: false });
  }

  handleDialogConfirm(): void{
    const {
      userId,
      albumId = '-1',
      picture,
    } = this.props;
    const { dialogTriggerer } = this.state;

    switch (dialogTriggerer) {
      case dialogTriggerers.approveReport:
      default:
        this.approveItem(userId, albumId, picture.pictureId);
        break;
    }
  }

  triggerApproveReport(e: MouseEvent): void{
    e.preventDefault();
    this.setState({ dialogShow: true, dialogTriggerer: dialogTriggerers.approveReport });
  }

  triggerBlockPicture(e: MouseEvent): void{
    e.preventDefault();
    this.triggerBlockReasonDialog();
  }

  async loadDetails(): Promise<any> {
    const { userId, albumId = '-1', picture } = this.props;
    if (albumId === '-1') { return; }
    const response = await this.albumService.getUserAlbumsContentItemDetails(userId, albumId, picture.pictureId);
    if (response.data !== undefined) {
      this.setState({ pictureData: response.data, imageRemoved: false });
    }
  }

  async approveItem(userId: string, albumId: string, pictureId: string): Promise<any> {
    const response = await this.albumService.approveReportedImageItem(userId, albumId, pictureId);
    if (response === undefined || response === '') {
      notify('Image is now removed from reported items!', NotifyLevels.SUCCESS);
      this.onConfirmModalClose(true);
      return;
    }
    this.onConfirmModalClose(false);
  }

  async blockItem(userId: string, albumId: string, pictureId: string, reason: string): Promise<any> {
    const response = await this.albumService.blockImageItem(userId, albumId, pictureId, reason);
    if (response === undefined || response === '') {
      this.setState({ imageRemoved: true });
    }
  }

  render(): React.ReactNode {
    const {
      onHide,
      show,
      picture,
      pageType = 'album',
    } = this.props;
    const {
      pictureData,
      dialogShow,
      imageRemoved,
      showReasonArea,
    } = this.state;
    const width = { picture: 8, details: 4 };
    const onHiding = (): void => {
      onHide(false);
      this.setState({ imageRemoved: false });
    };
    const pictureImage = !imageRemoved ? picture.pictureSource : '';
    const ImageDeletedNotice = imageRemoved ? (
      <div className={styles.albumPictureDeleted}>
        <ErrorContainer
          errors={['Image has been deleted']}
          customStyle={{
            textAlign: 'left',
            fontWeight: 'bold',
            left: 0,
            transform: 'translate(10%, -50%)',
          }}
          isVisible
        />
      </div>
    ) : null;

    return (
      <Modal show={show} onHide={onHiding} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body className={styles.modalBody}>
          <Row className={styles.albumPictureRow}>
            <Col xs={width.picture} md={width.picture} className={styles.albumPictureCol}>
              {ImageDeletedNotice}
              <div className={styles.albumPictureHolder} style={{ backgroundImage: `url(${pictureImage})` }} />
            </Col>
            <Col xs={width.details} md={width.details} className={styles.albumPictureCol}>
              <AlbumPictureDetails
                pictureData={pictureData}
                onHide={onHide}
                pageType={pageType}
                onTriggerApproveReport={this.triggerApproveReport}
                onTriggerBlockPicture={this.triggerBlockPicture}
              />
            </Col>
          </Row>
        </Modal.Body>
        <DialogConfirm
          content="Do you really want to set action?"
          onConfirm={this.handleDialogConfirm}
          onHide={this.handleDialogClose}
          show={dialogShow}
        />
        <BlockReasonArea
          page="content_media_picturemodal"
          mode="single"
          dialogTitle="Block picture item"
          showArea={showReasonArea}
          onBlockReasonAreaUpdate={this.onGottenBlockReason}
        />
      </Modal>
    );
  }
}
