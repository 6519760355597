import React, { CSSProperties } from 'react';
import {
  Card,
} from 'react-bootstrap';
import styles from './error.module.scss';

interface ErrorProps {
    errors: Array<string>;
    isVisible: boolean;
    customStyle?: CSSProperties | undefined;
}

const ErrorContainer: React.FC<ErrorProps> = ({
  errors, isVisible, customStyle,
}) => {
  if (!isVisible || errors.length <= 0 || errors[0] === '') {
    return null;
  }
  const custStyle = customStyle || {};
  return (
    <Card className={styles.errorContainer}>
      <div style={custStyle}>
        {errors[0]}
      </div>
    </Card>
  );
};

export default ErrorContainer;
