import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './dangerDetailsRow.module.scss';

interface DangerDetailsRowProps {
  title: string;
  value: React.ReactElement | string;
  rowClassName: string;
  colWidthLabel: Widths;
  colWidthValue: Widths;
  isHMTLElement?: boolean;
  isBordered?: boolean;
  direction?: string;
}

type Widths = {
    xs: number;
    md: number;
    lg: number;
}

const DangerDetailsRow: React.FC<DangerDetailsRowProps> = ({
  title, value, isBordered = true, rowClassName, colWidthLabel, colWidthValue, isHMTLElement,
  direction = 'right',
}) => {
  const borderClass = isBordered ? styles.bordered : '';
  const unPad = isBordered ? '' : styles.unpad;
  const valueElement = !isHMTLElement ? <span>{value}</span> : value;
  const classContainer = `${rowClassName} ${styles.container} ${borderClass} ${unPad}`;
  const titleSection = (
    <Col xs={colWidthLabel.xs} md={colWidthLabel.md} lg={colWidthLabel.lg}>
      <span className={styles.detailsTitle}>{title}</span>
    </Col>
  );
  const titleSectionLt = direction === 'left' ? titleSection : null;
  const titleSectionRt = direction === 'right' ? titleSection : null;
  return (
    <Row className={classContainer}>
      {titleSectionLt}
      <Col xs={colWidthValue.xs} md={colWidthValue.md} lg={colWidthValue.lg} className={styles.valueCol}>
        {valueElement}
      </Col>
      {titleSectionRt}
    </Row>
  );
};

export default DangerDetailsRow;
