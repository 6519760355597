import React from 'react';
import { Container } from 'react-bootstrap';
import ContentManagement from 'components/ContentManagement';

const ContentAlbumsPage = (): React.ReactElement => (
  <Container fluid>
    <ContentManagement contentType="albums" />
  </Container>
);

export default ContentAlbumsPage;
