import React, { useState } from 'react';
import {
  Container, Row, Col, Card, Form,
} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';

import { getErrorListFromObject } from 'helpers/utils/validators';
import MENU from 'helpers/constants/menuConstants';
import ErrorContainer from 'components/UI/error';
import ActionButton from 'components/UI/buttons/actionButton';
import AuthService from 'services/auth';

import FormTextInput from 'components/UI/texts/text';
import styles from './signin.module.scss';
import validate from './validate';

interface FormProps {
  isLoading: boolean;
}

export const SignInForm: React.FC<FormProps> = () => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [errorNotices, setError] = useState(['']);
  const [userEmail, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const authService = new AuthService();

  const handleSubmit = async (e: React.FormEvent): Promise<any> => {
    e.preventDefault();
    const errors = validate({ email: userEmail, password });
    const errorList = getErrorListFromObject(errors);
    setError(errorList);

    const token = await authService.signIn({ email: userEmail, password });
    if (token !== '') {
      localStorage.setItem('accessToken', JSON.stringify(token));
      setAuthenticated(true);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value);
    const errors = validate({ email: e.target.value, password });
    const emailErr = errors.email ? errors.email : '';
    setEmailError(emailErr.toString());
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value);
    const errors = validate({ email: userEmail, password: e.target.value });
    const pwdErr = errors.password ? errors.password : '';
    setPasswordError(pwdErr.toString());
  };

  const cardClass = `card-border ${styles.signInContainer}`;
  const buttonHolderClass = `${styles.signInSubmitHolder}`;
  if (isAuthenticated) {
    return (
      <Redirect
        to={{ pathname: MENU.ADMIN_MAIN.USER_MANAGEMENT }}
      />
    );
  }

  return (
    <Container className="justify-content-center">
      <Row className="justify-content-center">
        <Col sm="6" className={styles.signInTitle}>
          <Row className={styles.signInErrorRow}>
            <Col className={styles.signInErrorCol}>
              <ErrorContainer errors={errorNotices} isVisible={errorNotices[0] !== ''} />
            </Col>
          </Row>
          <h3>Albums Admin Panel</h3>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col sm="6">
          <Form onSubmit={handleSubmit} noValidate>
            <Card className={cardClass}>
              <Col xs="12" lg="12">
                <FormTextInput value={userEmail} errorMinHeight onChange={handleEmailChange} controlId="email" type="email" placeholder="E-mail" disabled={false} errorNotice={emailError} />
              </Col>
              <Col xs="12" lg="12">
                <FormTextInput value={password} errorMinHeight onChange={handlePasswordChange} controlId="password" type="password" placeholder="Password" errorNotice={passwordError} disabled={false} />
              </Col>
              <Col xs="12" lg="12" className={buttonHolderClass}>
                <ActionButton
                  title="Login"
                  type="submit"
                  isTextBold
                  customStyle={{ padding: '9px 20px 9px 20px' }}
                  onClick={handleSubmit}
                />
              </Col>
            </Card>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default SignInForm;
