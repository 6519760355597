/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { ImageItemDetails } from 'types/content-item';
import { Link } from 'react-router-dom';
import ManagementDetailsRow, { RowHeights } from 'components/UI/management/details/detailsRow';
import ModalCustomClose from 'components/UI/modal/close';
import { formatDateFromDatetime } from 'helpers/utils/date';
import DangerButton from 'components/UI/buttons/dangerButton';
import CancelButton from 'components/UI/buttons/cancelButton';
import MENU from 'helpers/constants/menuConstants';
import 'styles/helpers/commonPictureManagement/pictureModalOverride.scss';

import styles from './albumPictureDetails.module.scss';

type AlbumPictureDetailsProps = {
  pictureData: ImageItemDetails;
  onHide: (reloadItems: boolean) => void;
  onTriggerApproveReport: (e: React.MouseEvent) => void;
  onTriggerBlockPicture: (e: React.MouseEvent) => void;
  pageType: string;
}

export default class AlbumPictureDetails extends React.Component<AlbumPictureDetailsProps> {
  render(): React.ReactNode {
    const {
      pictureData,
      onHide,
      onTriggerApproveReport,
      onTriggerBlockPicture,
      pageType,
    } = this.props;
    const rowMb = `mb-3 ${styles.detailsRow}`;
    const width = { label: 5, value: 7 };

    const IgnoteButton = (
      <CancelButton
        title="Approve"
        onClick={onTriggerApproveReport}
        isTextBold
      />
    );

    const Cancel = (
      <CancelButton
        title="Cancel"
        onClick={onHide}
        isTextBold
      />
    );

    const userUrl = `${MENU.ADMIN_MAIN.USER_MANAGEMENT_PROFILE.split(':')[0]}:${pictureData.userId}`;
    // not to use url since direting to same place, just hide modal
    const albumUrl = `${MENU.ADMIN_MAIN.ALBUM_MANAGEMENT.split(':')[0]}:${pictureData.userId}/:${pictureData.albumId}`;

    const userNameLink = (
      <Link to={userUrl}>
        <span className={styles.logoText}>{pictureData.username}</span>
      </Link>
    );
    const albumNameLink = (
      <div role="button" tabIndex={0} onClick={(): void => { onHide(false); }} className={styles.linkAlbum}>
        <span className={styles.logoText}>{pictureData.albumName}</span>
      </div>
    );
    const albumNameLinkReport = (
      <Link to={albumUrl}>
        <span className={styles.logoText}>{pictureData.albumName}</span>
      </Link>
    );
    const albumLink = pageType !== 'report' ? albumNameLink : albumNameLinkReport;
    const CancelSection = pageType !== 'report' ? Cancel : IgnoteButton;
    const columns = [
      { title: 'User', value: userNameLink },
      { title: 'Album', value: albumLink },
      { title: 'Upload Date', value: formatDateFromDatetime(pictureData.uploadDate) },
    ];

    const Rows = columns.map((item) => (
      <ManagementDetailsRow
        rowHeight={RowHeights.Float}
        key={item.title}
        title={item.title}
        value={item.value}
        colWidthLabel={{ xs: width.label, md: width.label, lg: width.label }}
        colWidthValue={{ xs: width.value, md: width.value, lg: width.value }}
        rowClassName={rowMb}
        isBordered={false}
      />
    ));

    return (
      <Container className={styles.detailsContainer}>
        <ModalCustomClose
          onHide={onHide}
        />
        {Rows}
        <Row className={styles.detailsFooter}>
          <Col xs={12} md={12} className={styles.detailsFooterBtns}>
            {CancelSection}
            <DangerButton
              title="Block Picture"
              onClick={onTriggerBlockPicture}
              isTextBold
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
