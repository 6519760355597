import React, { FormEvent } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import styles from './headerMenu.module.scss';

interface MediaMenuProps {
  allSelected: boolean;
  onSelectAllClick: (event: FormEvent) => void;
  onUnselectAllClick: (event: FormEvent) => void;
  onApproveClick: (event: FormEvent) => void;
  onBlockClick: (event: FormEvent) => void;
  selectedItemsCount: number;
  maxItemsCount?: number;
}

const MediaMenuSection: React.FC<MediaMenuProps> = ({
  allSelected, onSelectAllClick, onUnselectAllClick, onApproveClick, onBlockClick, selectedItemsCount,
}) => {
  const SelectAllButton = (): React.ReactElement => (
    <Button
      variant="outline-light"
      className={styles.contentSelectAll}
      onClick={onSelectAllClick}
    >
      Select page
    </Button>
  );

  const UnSellectAllButton = (): React.ReactElement => (
    <Button
      variant="outline-light"
      className={styles.contentSelectAll}
      onClick={onUnselectAllClick}
    >
      Deselect page
    </Button>
  );

  const showSelButton = allSelected ? <UnSellectAllButton /> : <SelectAllButton />;
  const SelectAll = (): React.ReactElement => (
    <ul className={styles.contentMenuList}>
      <li>
        {showSelButton}
      </li>
    </ul>
  );

  const OperationsTools = (): React.ReactElement => (
    <ul className={styles.contentMenuList}>
      <li>
        <Button
          variant="outline-light"
          className={styles.contentIgnore}
          onClick={onApproveClick}
        >
          Approve
        </Button>
      </li>
      <li>
        <Button
          variant="outline-light"
          className={styles.contentDelete}
          onClick={onBlockClick}
        >
          Block
        </Button>
      </li>
    </ul>
  );
  const ShowManageTools = selectedItemsCount > 0 ? <OperationsTools /> : null;
  return (
    <Row>
      <Col>
        {ShowManageTools}
        <SelectAll />
      </Col>
    </Row>
  );
};

export default MediaMenuSection;
