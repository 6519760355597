import React, { CSSProperties } from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './userResults.module.scss';

export enum Levels {
  Alert = 'alert',
  Loading = 'loading',
  Default = 'default'
}

interface ListResultsProps {
  notice: string;
  level?: string;
  customRowStyle?: CSSProperties | undefined;
}

const ListResults: React.FC<ListResultsProps> = ({
  notice, level = Levels.Alert, customRowStyle = undefined,
}) => {
  const getNoticeClass = (paramLevel: Levels | string): string => {
    let noticeClass = styles.noticeContainer;
    switch (paramLevel) {
      case Levels.Loading:
        noticeClass = styles.loadingContainer;
        break;
      case Levels.Alert:
      default:
        noticeClass = styles.alertContainer;
        break;
    }
    return noticeClass;
  };
  const noticeClass = getNoticeClass(level);
  const rowStyle = customRowStyle || {};
  return (
    <Row className={noticeClass} style={rowStyle}>
      <Col>
        <span>{notice}</span>
      </Col>
    </Row>
  );
};

export default ListResults;
