import * as React from 'react';

import styles from './SocialMedia.module.scss';
import { Col, Row, Button } from 'react-bootstrap';

interface SocialMediaProps {
    data: Array<string>
    setData: (items: Array<string>) => void
}

const SocialMedia: React.FC<SocialMediaProps> = ({ data, setData }) => {


    const deleteItem = (index: number) => {
        const filteredItems = data.filter((item, i) => i !== index)
        setData(filteredItems)
    }
    const updateInputValue = (index: number, value: string) => {
        const items = [...data]
        items[index] = value
        setData(items)
    }
    return (
        <div className={styles.socialMediaItems}>
            <label htmlFor="">Social media</label>
            <div className='d-flex align-items-center'>
                <Button
                    variant='success'
                    size="sm"
                    className={'mb-3'}
                    onClick={() => setData([...data, ''])}
                >
                    Add a new one
                </Button>

                {data.length > 0 && (
                    <button onClick={() => setData([])} className={styles.removeButton}>Remove all</button>
                )}
            </div>

            <Row>
                {data.length > 0 && data.map((item, index) => (
                    <Col md={6}>
                        <div className={styles.socialMediaItem}>
                            <Button onClick={() => deleteItem(index)} variant='danger'>X</Button>
                            <input onChange={(e) => updateInputValue(index, e.target.value)} value={item} type="text" />
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default SocialMedia;