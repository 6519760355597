import React, { useState } from 'react';

import styles from './TagsBox.module.scss';
import crmService from 'services/crm';
import TagsBoxItems from './TagsBoxItems/index';
import SelectedTags from './SelectedTags';
import TagsBoxContainer from './TagsBoxContainer';

interface TagsBoxProps {
    defaultTags: Array<string>
    updateSelectedTags: (items: Array<string>) => void
    label: string
}

type Pageable = {
    offset: number,
    size: number
}

const TagsBox: React.FC<TagsBoxProps> = ({ updateSelectedTags, defaultTags, label }) => {
    const [dataStorage, setDataStorage] = useState(['']) // For having all data without effection
    const [selectedTags, setSelectedTags] = useState<any>(defaultTags);
    const [allTags, setAllTags] = useState(['']);
    const [total, setTotal] = useState(0);
    const [pageable, setPageable] = useState({ offset: 0, size: 10 });
    const [isListOpened, setIsListOpened] = useState(false);
    const [input, setInput] = useState('');

    const hadnleResetInput = () => setInput('')

    const tags = {
        sendRequest: async (
            query: string = '',
            offset: number = pageable.offset,
            size: number = pageable.size
        ) => {
            return await crmService.getTags(query, offset, size);
        },
        updateStatus: (total: number, pageable: Pageable) => {
            setTotal(total);
            setPageable(pageable);
        },
        getNewData: async (query: string = '') => {
            const res = await tags.sendRequest(query, 0, 10);
            const filteredData = res.content.filter(item => !selectedTags.includes(item))

            setDataStorage(res.content);
            setAllTags(filteredData);
            tags.updateStatus(res.total, res.pageable);
        },
        updateData: async () => {
            const newOffset = pageable.offset + pageable.size;
            const res = await tags.sendRequest('', newOffset);
            const filteredData = res.content.filter(item => !selectedTags.includes(item))
            const newData = [...allTags, ...filteredData];

            setDataStorage([...dataStorage, ...res.content]);
            setAllTags(newData);
            tags.updateStatus(res.total, res.pageable);
        },
        deleteItem: (name: string) => {
            const filteredTags = selectedTags.filter(item => item !== name);
            const newTags = [...allTags, name];

            setSelectedTags(filteredTags);
            updateSelectedTags(filteredTags);
            setAllTags(newTags);
        },
        deleteAll: () => {
            setSelectedTags([]);
            updateSelectedTags([]);
        },
        addToSelectedItems: (name: string) => {
            if (!selectedTags.includes(name)) {
                const newTags = [...selectedTags, name];
                const filteredTags = allTags.filter(item => item !== name);

                setSelectedTags(newTags);
                updateSelectedTags(newTags);
                setAllTags(filteredTags);
                hadnleResetInput();
            }
        },
        create: (key: string) => {
            if ((key === ' ' || key === 'Enter') && input.trim() !== '') {
                tags.addToSelectedItems(input);
                setTimeout(() => {
                    hadnleResetInput()
                    tags.getNewData()
                }, 10);
            }
            if (key === 'Backspace' && input === '') {
                const lastItem = selectedTags.at(-1);
                tags.deleteItem(lastItem);
            }
        }
    }

    const handleInputChange = (value: string) => {
        setInput(value);
        setIsListOpened(true);
        if (value !== null || value !== '') tags.getNewData(value)
    }

    const handleInputClick = () => {
        setIsListOpened(!isListOpened);
        if (!isListOpened) {
            tags.getNewData();
        }
    }

    return (
        <>
            <label htmlFor="">
                {label} 
                {selectedTags.length > 0 && (
                    <button onClick={() => tags.deleteAll()} className={styles.removeButton}>Remove all</button>
                )}
            </label>
            <TagsBoxContainer isListOpened={isListOpened} setIsListOpened={setIsListOpened}>
                {selectedTags && <SelectedTags data={selectedTags} deleteTag={tags.deleteItem} />}
                <div className={styles.inputBox}>
                    <input
                        onClick={() => handleInputClick()}
                        onChange={(e) => handleInputChange(e.target.value)}
                        onKeyDown={(e) => tags.create(e.key)}
                        value={input}
                        type="text"
                        placeholder='Select tags...'
                    />
                    {isListOpened && (
                        <TagsBoxItems 
                            updateData={tags.updateData} 
                            addToSelectedTags={tags.addToSelectedItems} 
                            totalItems={total} 
                            itemsCount={allTags.length}
                            storageCount={dataStorage.length}
                            inputValue={input} 
                            data={allTags} 
                        />
                    )}
                </div>
            </TagsBoxContainer>
        </>
    );
}

export default TagsBox;