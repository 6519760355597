import React from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';

import styles from './textField.module.scss';

interface TextInputProps {
  controlId: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
  placeholder: string;
  disabled: boolean;
  label?: string;
  errorNotice?: string;
  inputIcon?: string;
  autocomplete?: string;
  errorMinHeight?: boolean;
}

const FormTextInput: React.FC<TextInputProps> = ({
  controlId,
  type,
  label = '',
  value,
  onChange,
  placeholder,
  errorNotice = '',
  disabled,
  autocomplete = 'off',
  inputIcon = '',
  errorMinHeight = false,
}) => {
  const inputIconContainer = (): React.ReactElement => {
    switch (inputIcon) {
      case 'search':
        return <div className={styles.textIcon}><FaSearch /></div>;
      default:
        return <i />;
    }
  };
  const textInputClass = inputIcon === '' ? '' : styles.textInputWithIcon;
  const labelSection = label !== '' ? (
    <Form.Label className={styles.textLabel}>{label}</Form.Label>
  ) : null;
  const errorStyle = errorMinHeight ? { minHeight: '20px' } : {};
  return (
    <FormGroup controlId={controlId}>
      {labelSection}
      {inputIconContainer()}
      <Form.Control
        className={`${styles.textInput} ${textInputClass}`}
        value={value}
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={autocomplete}
      />
      <Form.Text style={errorStyle}>
        <div className={styles.errorField}>{errorNotice}</div>
      </Form.Text>
    </FormGroup>
  );
};

export default FormTextInput;
