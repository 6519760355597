/* eslint-disable import/prefer-default-export */ // don't think that default here is needed
export function getParamsFromLocation(path: string): string[] {
  let pathParams = path.split(':');
  pathParams = pathParams.map((item) => item.replace(/\//g, ''));
  return pathParams;
}

export function getBaseUrl(): string {
  const { protocol, host } = window.location;
  const baseUrl = `${protocol}//${host}/#`;
  return baseUrl;
}

// eslint-disable-next-line class-methods-use-this
export function openTab(url: string): void {
  const fullUrl = `${getBaseUrl()}${url}`;
  const win = window.open(fullUrl, '_blank');
  if (win != null) {
    win.focus();
  }
}
