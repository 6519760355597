/* eslint-disable class-methods-use-this */ // @todo will remove when get real data

import React from 'react';
import {
  Container,
  Row,
  Col,
  Form,
} from 'react-bootstrap';
import { UserFormSettings } from 'types/user/userProfile';
import FormSelect from 'components/UI/selects/select';
import ActionButton from 'components/UI/buttons/actionButton';
import ManagementDetailsTitle from 'components/UI/management/details/detailsTitle';
import ManagementDetailsRow from 'components/UI/management/details/detailsRow';
import UserDetails from 'types/user/userDetails';
import { liteShuffle } from 'helpers/utils/arrays';
import CountriesService from 'services/countries';
import UserService from 'services/user';
import notify from 'services/notify';

import MngUserMultiSelectRow from './MngUserMultiSelect';
import styles from './manageUser.module.scss';

const userData = {
  storagePlan: '100GB',
  suggested: [],
  suggestedIn: false,
  backup: [],
  backupIn: false,
};

// @todo remove when integrated
const DISBALE_STORAGE = true;

const mockStoragePlans = [
  { label: 'Free', value: 'Free' },
  { label: '100 GB', value: '100 GB' },
  { label: '2 TB', value: '2 TB' },
];

type CountryType = {
  label: string;
  value: string;
};

type StorageType = {
  label: string;
  value: string;
};

type ManageFormProps = {
  title: string;
  userId: string;
}

interface State {
  user: UserFormSettings;
  storagePlans: Array<StorageType>;
  suggestedCountries: Array<CountryType>;
  backupCountries: Array<CountryType>;
  storageApplyActive: boolean;
}

export default class UserSettingsForm extends React.Component<ManageFormProps, State> {
  countriesService: CountriesService;

  userService: UserService;

  constructor(props: ManageFormProps) {
    super(props);
    this.state = {
      user: userData,
      storagePlans: mockStoragePlans,
      suggestedCountries: [],
      backupCountries: [],
      storageApplyActive: false,
    };
    this.countriesService = new CountriesService();
    this.userService = new UserService();
    this.handleSuggestChboxChange = this.handleSuggestChboxChange.bind(this);
    this.handleBackupChboxChange = this.handleBackupChboxChange.bind(this);
    this.handleStoragePlans = this.handleStoragePlans.bind(this);
    this.handleSuggested = this.handleSuggested.bind(this);
    this.handleBackup = this.handleBackup.bind(this);
    this.handleStorageApply = this.handleStorageApply.bind(this);
    this.handleSuggestedApply = this.handleSuggestedApply.bind(this);
    this.handleBackupApply = this.handleBackupApply.bind(this);
  }

  componentDidMount(): void {
    this.loadAllCountries();
  }

  setUserSettings(data: UserDetails): void {
    const suggestedUser = data.suggestedUser || null;
    const { suggestedCountries, backupCountries } = this.state;
    if (suggestedUser) {
      this.handleSuggestChboxChange(suggestedUser.in);
      const countryCodes = liteShuffle<string>(suggestedUser.countries);
      const countriesSelect = this.countriesService.convertCountryCodesToSelectList(countryCodes, suggestedCountries);
      this.handleSuggested(countriesSelect);
    }
    const backupUser = data.backupUser || null;
    if (backupUser) {
      this.handleBackupChboxChange(backupUser.in);
      const countryCodes = liteShuffle<string>(backupUser.countries);
      const countriesSelect = this.countriesService.convertCountryCodesToSelectList(countryCodes, backupCountries);
      this.handleBackup(countriesSelect);
    }
  }

  getStoragePlans(storagePlans: string[]): Array<CountryType> {
    let countriesSelect: Array<CountryType> = [];
    if (storagePlans) {
      countriesSelect = storagePlans.map((item) => ({ label: item, value: item }));
    }
    return countriesSelect;
  }

  async loadUserDetails(): Promise<any> {
    const { userId } = this.props;
    const response = await this.userService.getUserDetails(userId);
    if (response.data !== undefined) {
      // transform to correct select form countries
      const { data } = response;
      // set user suggest, backup in
      this.setUserSettings(data);

      const activateStorageServer = false; // @todo activate when backend ready
      if (activateStorageServer) {
        const storagePlans = data.storagePlans || [];
        if (storagePlans) {
          const countriesSelect = this.getStoragePlans(storagePlans);
          this.setState({ storagePlans: countriesSelect });
        }
      }

      // set selected storage plan
      const storagePlan = data.storage.plan || '';
      this.setState((prevState) => {
        const user = { ...prevState.user };
        user.storagePlan = storagePlan;
        return { user };
      });
    }
  }

  async loadAllCountries(): Promise<any> {
    const response = await this.countriesService.getAllCountries(true);
    if (response.data !== null) {
      // transform to correct select form countries
      const { data } = response;
      this.setState({ backupCountries: data });
      this.setState({ suggestedCountries: data });

      // load all other data
      this.loadUserDetails();
    }
  }

  handleStoragePlans(e: React.ChangeEvent<HTMLInputElement>): void{
    e.preventDefault();
    const newValue = e.target.value;
    this.setState((prevState) => {
      const user = { ...prevState.user };
      user.storagePlan = newValue;
      return { user };
    });
    this.setState({ storageApplyActive: true });
  }

  handleSuggested(countryList: Array<CountryType>): void {
    if (countryList === undefined || countryList === null) { return; }
    // const list = countryList.map((item) => item.value);
    this.setState((prevState) => {
      const user = { ...prevState.user };
      user.suggested = countryList;
      return { user };
    });
  }

  handleBackup(countryList: Array<CountryType>): void {
    if (countryList === undefined || countryList === null) { return; }
    this.setState((prevState) => {
      const user = { ...prevState.user };
      user.backup = countryList;
      return { user };
    });
  }

  handleSuggestChboxChange(checked: boolean): void {
    this.setState((prevState) => {
      const user = { ...prevState.user };
      user.suggestedIn = checked;
      return { user };
    });
  }

  handleBackupChboxChange(checked: boolean): void {
    this.setState((prevState) => {
      const user = { ...prevState.user };
      user.backupIn = checked;
      return { user };
    });
  }

  reloadUsers(): void {
    this.loadUserDetails();
  }

  async handleSuggestedApply(): Promise<any> {
    const { userId } = this.props;
    const { user } = this.state;
    const countryCodes = user.suggested.map((item) => item.value);
    let status;
    if (user.suggestedIn) {
      status = await this.userService.addToSuggestedList(userId, countryCodes);
    } else {
      status = await this.userService.removedFromSuggestedList(userId);
      this.reloadUsers();
    }
    if (status === undefined) {
      notify('Suggest list updated!');
    } else { // error restore to initial page load data
      this.reloadUsers();
    }
  }

  async handleBackupApply(): Promise<any> {
    const { userId } = this.props;
    const { user } = this.state;
    const countryCodes = user.backup.map((item) => item.value);
    let status;
    if (user.backupIn) {
      status = await this.userService.addToBackupList(userId, countryCodes);
    } else {
      status = await this.userService.removeFromBackupList(userId);
      this.reloadUsers();
    }
    if (status === undefined) {
      notify('Backup list updated!');
    } else { // error restore to initial page load data
      this.reloadUsers();
    }
  }

  handleStorageApply(): void {
    const { user } = this.state;
    console.log(' --> handle storage ', user.storagePlan);
    // const response = this.userService.setStorage(storagePlan);
  }

  render(): React.ReactNode {
    const {
      storageApplyActive,
      user,
      backupCountries,
      suggestedCountries,
      storagePlans,
    } = this.state;
    const width = {
      checkbox: { xs: 4, md: 4, lg: 4 },
      select: { xs: 8, md: 8, lg: 8 },
    };

    const storageSection = DISBALE_STORAGE ? null : (
      <>
        <ManagementDetailsTitle
          title="STORAGE"
          rowClassName="mt-2"
          colWidthLabel={{ xs: 12, md: 12, lg: 12 }}
        />
        <ManagementDetailsRow
          title="Transaction info"
          value="storage info"
          colWidthLabel={{ xs: 9, md: 9, lg: 9 }}
          colWidthValue={{ xs: 3, md: 3, lg: 3 }}
          rowClassName=""
        />
        <Row className="mt-2">
          <Col xs={10} md={10} lg={10} className={styles.storageCol}>
            <FormSelect
              controlId="storagePlans"
              selectedValue={user.storagePlan}
              options={storagePlans}
              onChange={this.handleStoragePlans}
              disabled={false}
            />
          </Col>
          <Col xs={2} md={2} lg={2} className={styles.applyCol}>
            <ActionButton
              title="Apply"
              isApplyButton
              active={storageApplyActive}
              onClick={this.handleStorageApply}
            />
          </Col>
        </Row>
      </>
    );

    return (
      <Container fluid className={styles.manageUserForm}>
        <Form>
          <MngUserMultiSelectRow
            rowClass="mt-4"
            checkbox={{
              controlId: 'suggested',
              title: 'Suggested',
              disabled: false,
              checked: user.suggestedIn,
              onChange: this.handleSuggestChboxChange,
            }}
            select={{
              options: suggestedCountries,
              placeholder: 'Globally',
              onChange: this.handleSuggested,
            }}
            width={width}
            values={user.suggested}
            isApplyButton
            applyButtonClick={this.handleSuggestedApply}
          />

          <MngUserMultiSelectRow
            checkbox={{
              controlId: 'backup',
              title: 'Backup user',
              disabled: false,
              checked: user.backupIn,
              onChange: this.handleBackupChboxChange,
            }}
            select={{
              options: backupCountries,
              placeholder: 'Globally',
              onChange: this.handleBackup,
            }}
            width={width}
            values={user.backup}
            isApplyButton
            applyButtonClick={this.handleBackupApply}
          />
          {storageSection}
        </Form>
      </Container>
    );
  }
}
