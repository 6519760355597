import React, { MouseEvent, useState } from 'react';
import {
  Modal,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import FormTextAreaInput from 'components/UI/texts/textArea';
import ActionButton from 'components/UI/buttons/actionButton';
import CancelButton from 'components/UI/buttons/cancelButton';
import styles from './reasonDialog.module.scss';

interface ReasonDialogProps {
  title?: string;
  onConfirm: (textValue: string) => void;
  onHide: (e?: MouseEvent) => void;
  labelApply?: string;
  labelCancel?: string;
  show: boolean;
}

const ReasonDialog: React.FC<ReasonDialogProps> = ({
  title = 'Reason Dialog',
  labelCancel = 'Cancel',
  labelApply = 'Go',
  onHide,
  show,
  onConfirm,
}) => {
  const [textValue, setTextValue] = useState('');

  const onHiding = (): void => { onHide(); setTextValue(''); };
  const titleClass = `justify-content-center ${styles.modalTitle}`;
  const buttonClass = `text-right ${styles.modalFooterButtons}`;

  const handleApply = (e: MouseEvent): void => {
    e.preventDefault();
    onConfirm(textValue);
    setTextValue('');
  };

  const handleClose = (e: MouseEvent): void => {
    e.preventDefault();
    onHide(e);
    setTextValue('');
  };

  const handleText = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTextValue(e.target.value);
  };

  return (
    <Modal show={show} onHide={onHiding} className={styles.modal} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton className={styles.modalHeader}>
        <Modal.Title className={titleClass}>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <Container>
          <FormTextAreaInput
            controlId="reason"
            value={textValue}
            label="Reason for this action"
            placeholder="Please, write a note"
            onChange={handleText}
            disabled={false}
          />
        </Container>
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <Row>
          <Col className={buttonClass}>
            <CancelButton title={labelCancel} isTextBold onClick={handleClose} />
            <ActionButton title={labelApply} isTextBold onClick={handleApply} />
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default ReasonDialog;
