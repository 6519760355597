import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Tab,
  Tabs,
} from 'react-bootstrap';
import { getParamsFromLocation } from 'helpers/utils/url';
import UserManagementProfile from 'components/UserManagement/Profile';
import ActionHistoryList from 'components/ActionHistory';
import ButtonBack from 'components/UI/buttons/backButton';
import LinkConstans from 'helpers/constants/linkConstants';
import MENU from 'helpers/constants/menuConstants';
import styles from './userProfile.module.scss';
import 'styles/helpers/tabs/mainOverride.scss';

const UserProfilePage: React.FC = () => {
  // @todo undisable when action tab is ready in backend
  const location = useLocation();
  const userId = getParamsFromLocation(location.pathname)[1];
  let backBtnTitle = 'Back to user list';
  let backUrl: string = MENU.ADMIN_MAIN.USER_MANAGEMENT;

  if (location) {
    const { pathname } = location; // new storage types/react-dom-router
    if (pathname === LinkConstans.REPORT_USERS) {
      backBtnTitle = 'Back to reported users';
      backUrl = MENU.ADMIN_MAIN.CONTENT_USERS;
    }
  }

  const ActionHistoryTab = (
    <Tab eventKey="auditSearchAnalytics" title="Action History" tabClassName={styles.pageTab}>
      <ActionHistoryList title="Action history" userId={userId} />
    </Tab>
  );
  return (
    <Container fluid>
      <Row className="mt-4 mb-4">
        <Col>
          <ButtonBack title={backBtnTitle} pathTo={backUrl} />
        </Col>
      </Row>
      <Tabs defaultActiveKey="userProfile" id="uncontrolled-tab-example">
        <Tab eventKey="userProfile" title="User Management" tabClassName={styles.pageTab}>
          <UserManagementProfile userId={userId} />
        </Tab>
        {ActionHistoryTab}
      </Tabs>
    </Container>
  );
};

export default UserProfilePage;
