import React from 'react';
import { Container } from 'react-bootstrap';

const DashBoard = (): React.ReactElement => (
  <Container>
    <h3>Dashboard</h3>
  </Container>
);

export default DashBoard;
