/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/ban-types */ // customStyle object
import React from 'react';
import { Button } from 'react-bootstrap';
import styles from './actionButton.module.scss';

type buttonType = 'button' | 'submit' | 'reset';

interface ActionButtonProps {
  title: string;
  onClick: Function;
  type?: buttonType;
  isTextBold?: boolean;
  isSmall?: boolean;
  customStyle?: Object;
  isEnableButton?: boolean;
  isApplyButton?: boolean;
  disabled?: boolean;
  active?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  title,
  onClick,
  isTextBold = false,
  isSmall = false,
  customStyle = undefined,
  type = 'button',
  isEnableButton = false,
  isApplyButton = false,
  disabled = false,
  active = false,
}) => {
  const boldStyle = isTextBold ? styles.mainBold : '';
  const smallStyle = isSmall ? styles.mainSmall : '';
  const styleMain = `${styles.main} ${boldStyle} ${smallStyle}`;
  const custStyle = customStyle !== undefined ? customStyle : {};
  if (isEnableButton) {
    custStyle['backgroundColor'] = '#20a118';
    custStyle['border'] = '1px solid #20a118';
  }
  let disableState = disabled;
  if (isApplyButton && !active) {
    disableState = true;
    custStyle['backgroundColor'] = '#C4C4C4';
    custStyle['border'] = 'none';
  }
  return (
    <Button
      variant="primary"
      className={styleMain}
      type={type}
      disabled={disableState}
      style={custStyle}
      onClick={(e: React.MouseEvent): void => { onClick(e); }}
    >
      <span>{title}</span>
    </Button>
  );
};

export default ActionButton;
