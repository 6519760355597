import React from 'react';
import capitalizeFirstChar from 'helpers/utils/string';
import styles from './alertStatus.module.scss';

export default (items: string[]): React.ReactElement => {
  const contentItems = items.map((item) => {
    const className = styles[`alertStatus${capitalizeFirstChar(item)}`];
    return (<div key={item} className={className}>{item}</div>);
  });
  const showAlert = items && items.length > 0 ? contentItems : '-';
  return (
    <div>
      {' '}
      {showAlert}
      {' '}
    </div>
  );
};
