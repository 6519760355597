import * as React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';

import styles from '../TagsBox.module.scss';

interface SelectedTagsProps {
    data: Array<string>
    deleteTag: (item: string) => void
}

const SelectedTags: React.FC<SelectedTagsProps> = ({ data, deleteTag }) => {
    return (
        <>
            {data.map(item => (
                <span onClick={() => deleteTag(item)} className={styles.tagBadge}>
                    {item} <CancelIcon />
                </span>
            ))}
        </>
    );
}

export default SelectedTags;