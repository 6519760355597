/* eslint-disable class-methods-use-this */
/* eslint-disable dot-notation */
// User service (get users, user, profile, block, delete)
import { AlbumsType, AlbumType } from 'types/album';
import {
  ImageItemType,
  PictureItem,
} from 'types/content-item';
import { SelectOptionType } from 'types/form/select';
// using from report, albums
import {
  AlbumListType,
  AlbumListFailed,
  AlbumDetailsSuccessType,
  AlbumDetailsFailed,
  ImageItemSuccessType,
  ImageItemFailedType,
  AlbumItemDetailsSuccessType,
  AlbumItemDetailsFailed,
} from 'types/services/album';

import API_PATH from 'helpers/constants/apiPaths';
import { api } from 'helpers/server/api';

export default class AlbumService {
  usersPath: string;

  constructor() {
    this.usersPath = API_PATH.USERS.LIST;
  }

  async getUserAlbums(userId: string, pageOffset = 1, pageLimit = 10): Promise<AlbumListType | AlbumListFailed> {
    const path = `/v1/${this.usersPath}/${userId}/${API_PATH.ALBUM.LIST}`;
    // in components pageOffset starts from 1, backend request from 0
    const response = await api.get(path, { offset: (pageOffset - 1) * pageLimit, limit: pageLimit });
    if (response && response['content']) {
      const pageAble = response['pageable'] || null;
      return {
        data: response['content'],
        pagination: {
          total: response['total'],
          offset: pageAble !== null ? pageAble['offset'] : 0,
          size: pageAble !== null ? pageAble['size'] : pageLimit,
        },
      };
    }
    return { data: undefined, pagination: undefined, status: response.status };
  }

  getFormattedAlbums(data: AlbumsType[]): AlbumType[] {
    const albumsArr = data.map((item) => {
      const url = item.coverPhoto ? item.coverPhoto.url : '';
      let sharedUserId = '';
      let sharedUserName = '';
      const { isHidden } = item;
      const shareInfo = item.sharingInfo;
      if (shareInfo) {
        sharedUserId = shareInfo.userId;
        sharedUserName = shareInfo.username;
      }
      return {
        id: item.id,
        name: item.name,
        level: item.privacyLevel,
        url,
        sharedUserId,
        sharedUserName,
        isHidden,
      };
    });
    return albumsArr;
  }

  async getUserAlbumsDetails(userId: string, albumId: string): Promise<AlbumDetailsSuccessType | AlbumDetailsFailed> {
    const path = `/v1/${this.usersPath}/${userId}/${API_PATH.ALBUM.LIST}/${albumId}/${API_PATH.ALBUM.DETAILS}`;
    const response = await api.get(path, { });
    if (response && response['status'] === undefined) {
      return {
        data: response,
      };
    }
    return { data: undefined, status: response.status };
  }

  convertCategoriesToSelectList(categories: Array<string>): Array<SelectOptionType> {
    let catgsSelect: Array<SelectOptionType> = [];
    if (categories) {
      catgsSelect = categories.map((item) => ({ label: item.toLowerCase(), value: item }));
    }
    return catgsSelect;
  }

  unConvertCategories(categories: Array<SelectOptionType>): Array<string> {
    return categories.map((item) => item.value);
  }

  async setCategory(userId: string, albumId: string, category: string): Promise<string> {
    const path = `/v1/${this.usersPath}/${userId}/${API_PATH.ALBUM.LIST}/${albumId}/${API_PATH.ALBUM.CATEGORY}`;
    const response = await api.put(path, { category });
    return response.status;
  }

  async getUserAlbumsContentItems(userId: string, albumId: string, pageOffset = 1, limit = 10): Promise<ImageItemSuccessType | ImageItemFailedType> {
    const path = `/v1/${this.usersPath}/${userId}/${API_PATH.ALBUM.LIST}/${albumId}/${API_PATH.ALBUM.ITEMS}`;
    // in components pageOffset is portion (offset: page number + limit with minus startOffset)
    const response = await api.get(path, { offset: (pageOffset - 1) * limit, limit });
    if (response && response['content']) {
      const pageAble = response['pageable'] || null;
      const currentPage = pageAble ? pageAble['offset'] : 1;
      const totalItems = response['total'];
      const pageCount = Math.ceil(totalItems / pageAble['size']);
      return {
        data: this.getFormattedImageItems(response['content']),
        albumName: response['albumName'],
        pagination: {
          pageCount,
          currentPage,
        },
        total: totalItems,
      };
    }
    return {
      data: undefined,
      albumName: undefined,
      pagination: undefined,
      status: response.status,
      total: 0,
    };
  }

  getFormattedImageItems(items: ImageItemType[]): PictureItem[] {
    const formattedList = items.map((item) => {
      const source = item.thumb && item.thumb.url !== undefined ? item.thumb.url : item.object.url;
      return {
        pictureId: item.id,
        pictureSource: source,
        userId: item.userId,
        albumId: item.albumId,
      };
    });
    return formattedList;
  }

  async getUserAlbumsContentItemDetails(
    userId: string,
    albumId: string,
    imageItemId: string,
  ): Promise<AlbumItemDetailsSuccessType | AlbumItemDetailsFailed> {
    const path = `/v1/${this.usersPath}/${userId}/${API_PATH.ALBUM.LIST}/${albumId}/${API_PATH.ALBUM.ITEMS}/${imageItemId}`;
    const response = await api.get(path, { });
    if (response && response['status'] === undefined) {
      const { uploader } = response;
      const imageDetails = {
        id: response['id'],
        userId: response['userId'],
        albumId: response['albumId'],
        name: uploader['fullname'],
        username: uploader['username'],
        albumName: response['albumName'],
        uploadDate: response['creationDate'],
        image: response['object'],
      };
      return {
        data: imageDetails,
      };
    }
    return {
      data: undefined,
      status: response.status,
    };
  }

  // approve, remove from reported content
  async approveReportedImageItem(userId: string, albumId: string, itemId: string): Promise<string> {
    const path = `/v1/${this.usersPath}/${userId}/${API_PATH.ALBUM.LIST}/${albumId}/${API_PATH.ALBUM.ITEMS}/${itemId}/${API_PATH.ALBUM.REPORT_ITEMS}`;
    const response = await api.remove(path, { });
    return response.status;
  }

  // in backend block means delete completely
  async blockImageItem(userId: string, albumId: string, itemId: string, reasonText = ''): Promise<string> {
    const path = `/v1/${this.usersPath}/${userId}/${API_PATH.ALBUM.LIST}/${albumId}/${API_PATH.ALBUM.ITEMS}/${itemId}/${API_PATH.ALBUM.BLOCK}`;
    const response = await api.post(path, { reason: reasonText });
    return response.status;
  }

  // approve, remove from reported album
  async approveAlbum(userId: string, albumId: string): Promise<string> {
    const path = `/v1/${this.usersPath}/${userId}/${API_PATH.ALBUM.LIST}/${albumId}/${API_PATH.ALBUM.APPROVE_ALBUM}`;
    const response = await api.remove(path, { });
    return response.status;
  }

  async blockAlbum(userId: string, albumId: string, reasonText = ''): Promise<string> {
    const path = `/v1/${this.usersPath}/${userId}/${API_PATH.ALBUM.LIST}/${albumId}/${API_PATH.ALBUM.BLOCK}`;
    const response = await api.post(path, { reason: reasonText });
    return response.status;
  }

  // in backend means delete permamently
  async deleteCoverPhoto(userId: string, albumId: string): Promise<string> {
    const path = `/v1/${this.usersPath}/${userId}/${API_PATH.ALBUM.LIST}/${albumId}/${API_PATH.ALBUM.COVER_PHOTO}`;
    const response = await api.remove(path, { });
    return response.status;
  }

  async recalculateAlbumStorage(userId: string, albumId: string): Promise<string> {
    const path = `/v1/${this.usersPath}/${userId}/${API_PATH.ALBUM.LIST}/${albumId}/${API_PATH.ALBUM.RECALCULATE_USED_STORAGE}`;
    const response = await api.post(path, { });
    return response.status;
  }
}
