export function getRandomInt(min: number, max: number): number {
  const minNum = Math.ceil(min);
  const maxNum = Math.floor(max);
  return Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum;
}

export function formatThousandValue(value: number, separator = ' '): string {
  const formatVal = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  return formatVal;
}
