import React, { MouseEvent } from 'react';
import IMAGE_RESOLUTIONS from 'helpers/constants/imageResolutions';

import styles from './albumItem.module.scss';

type AlbumItemProps = {
  pictureId: string;
  title: string;
  coverPicture?: string;
  onAlbumItemEnter: (pictureId: string) => void;
}

export default class AlbumItem extends React.Component<AlbumItemProps> {
  constructor(props: AlbumItemProps) {
    super(props);
    this.handleAlbumEnter = this.handleAlbumEnter.bind(this);
  }

  handleAlbumEnter(e: MouseEvent): void{
    e.preventDefault();
    const { pictureId, onAlbumItemEnter } = this.props;
    onAlbumItemEnter(pictureId);
  }

  render(): React.ReactNode {
    const { title, coverPicture = '' } = this.props;
    const bgUrl = coverPicture || '';
    const styleCl = { backgroundImage: `url(${bgUrl}${IMAGE_RESOLUTIONS.IMAGE_MEDIUM})` };
    return (
      <div
        className={styles.albumItem}
        role="button"
        style={styleCl}
        tabIndex={0}
        onClick={(e: MouseEvent): void => this.handleAlbumEnter(e)}
      >
        <div className={styles.albumItemLabel}>
          <div>{title}</div>
        </div>
      </div>
    );
  }
}
