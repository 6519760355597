import React from 'react';
import {
  Container,
} from 'react-bootstrap';
import UserVerificationList from 'components/UserManagement/VerificationList';

const UserVerificationPage: React.FC = () => (
  <Container fluid>
    <UserVerificationList title="Verification List" />
  </Container>
);

export default UserVerificationPage;
