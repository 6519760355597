import React, { useRef, useEffect } from 'react';

import styles from '../TagsBox.module.scss';

interface TagsBoxContainerProps {
    isListOpened: boolean
    setIsListOpened: (x: boolean) => void
}

const TagsBoxContainer: React.FC<TagsBoxContainerProps> = ({ children, isListOpened, setIsListOpened }) => {
    const ref = useRef<HTMLHeadingElement>(null);

    /* eslint-disable */
    useEffect(() => {
        if (isListOpened) {
            const checkIfClickedOutside = (e: any) => {
                if (isListOpened && ref.current && !ref.current.contains(e.target)) {
                    setIsListOpened(false);
                }
            };

            document.addEventListener('mousedown', (e) => checkIfClickedOutside(e));

            return () => {
                document.removeEventListener('mousedown', checkIfClickedOutside);
            };
        }
    }, [isListOpened]);
    /* eslint-enable */

    return (
        <div ref={ref} className={styles.tagsBox}>
            {children}
        </div>
    );
}

export default TagsBoxContainer;