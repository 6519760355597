import * as React from 'react';

import styles from '../TagsBox.module.scss';

interface TagsBoxItemProps {
    title: string | React.ReactNode,
    value: string,
    addToSelectedTags: (item: string) => void
}

const TagsBoxItem: React.FC<TagsBoxItemProps> = ({ title, value, addToSelectedTags }) => {
    return (
        <li
            onClick={() => addToSelectedTags(value)}
            className={styles.tagsListItem}
        >
            {title}
        </li>
    );
}

export default TagsBoxItem;