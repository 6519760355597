import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { RouteComponentProps } from '@reach/router';
import DefaultLayout from 'layouts/default/defaultLayout';

const USE_AUTHENTICATION = true;

interface ExtendProps extends React.PropsWithChildren<any> {
  pageComponent: FC;
  path: string;
}

const DefaultRoute = ({
  children, ...props
}: ExtendProps & RouteComponentProps): React.ReactElement => {
  const { pageComponent, ...others } = props;
  const isAuthenticated = USE_AUTHENTICATION ? localStorage.getItem('accessToken') : true;
  if (!isAuthenticated) {
    return (
      <Redirect
        to={{ pathname: '/unauthorized', state: { from: props.location } }}
      />
    );
  }
  return (
    <DefaultLayout>
      <props.pageComponent {...others}>
        {children}
      </props.pageComponent>
    </DefaultLayout>
  );
};

export default DefaultRoute;
