import React from 'react';
import styles from './defaultLayout.module.scss';

const LayoutFooter: React.FC = () => (
  <footer className={styles.layoutFooter}>
    (c) OnoffApp
  </footer>
);

export default LayoutFooter;
