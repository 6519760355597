// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import React from 'react';
import { Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { DataTableColumnType } from 'types/datatable';
import { DeviceListType } from 'types/user/userDetails';
import { formatDateFromDatetime } from 'helpers/utils/date';

import UserService from 'services/user';
import DialogConfirm from 'components/UI/dialogs/dialog';

import styles from './devices.module.scss';
import 'styles/helpers/tables/userListOverride.scss';

type ListProps = {
  userId: string;
  devices: Array<DeviceListType>;
}

type ListState = {
  isModalOpen: boolean;
  deviceId: string;
}

export default class DeviceList extends React.Component<ListProps, ListState> {
  columns: Array<DataTableColumnType>;

  userService: UserService;

  constructor(props: ListProps) {
    super(props);
    this.userService = new UserService();
    this.columns = [];
    this.formatTable();
    this.state = {
      isModalOpen: false,
      deviceId: '',
    };
  }

  formatTable(): void {
    const formatVersion = (cell: string): React.ReactNode => (
      <div>{cell || '-'}</div>
    );
    const formatRegistrationDate = (cell: string): React.ReactNode => (
      <div>{formatDateFromDatetime(cell, true)}</div>
    );

    this.columns = [
      {
        dataField: 'type',
        text: 'Device type',
        classes: styles.userTableCol,
      },
      {
        dataField: 'version',
        text: 'Version',
        classes: styles.userTableCol,
        formatter: formatVersion,
      },
      {
        dataField: 'locale',
        text: 'Locale',
        classes: styles.userTableCol,
      },
      {
        dataField: 'creationDate',
        text: 'Login Date',
        formatter: formatRegistrationDate,
        classes: styles.userTableCol,
      },
      {
        dataField: 'action',
        text: '',
        classes: styles.userTableCol,
      }];
  }

  render(): React.ReactNode {
    const { devices, userId } = this.props;
    const { isModalOpen, deviceId } = this.state;
    const deviceListEmpty = (<span />);
    const userService = new UserService();

    const handleDeleteDevice = async (userID: string, deviceID: string) => {
      const status = await userService.deleteDevice(userID, deviceID);
      if (status === undefined) {
        window.location.reload();
      }
    };

    const handleOpenModal = (deviceID: string) => {
      this.setState({ isModalOpen: true, deviceId: deviceID });
    };

    const handleCloseModal = () => {
      this.setState({ isModalOpen: false });
    };

    const deviceWithButton = devices.map((device: DeviceListType) => (
      {
        ...device,
        action: <Button onClick={() => handleOpenModal(device.id)} className={styles.deleteButton} variant="danger" size="sm">Delete</Button>,
      }
    ));

    const deviceList = (
      <div className="w-100">
        <BootstrapTable
          keyField="id"
          data={deviceWithButton}
          columns={this.columns}
          BootstrapTable="bootstrap4"
          headerClasses={styles.userTableHeader}
          rowClasses={styles.userTableRow}
        />
        <DialogConfirm
          content="Are you sure?"
          onHide={() => handleCloseModal()}
          onConfirm={() => handleDeleteDevice(userId, deviceId)}
          show={isModalOpen}
        />
      </div>
    );

    const showList = devices && devices.length > 0 ? deviceList : deviceListEmpty;

    return (
      <div className="row mt-3">
        {showList}
      </div>
    );
  }
}
