import React from 'react';
import { Container } from 'react-bootstrap';

const ProfilePage = (): React.ReactElement => (
  <Container>
    <h3>Admin profile</h3>
    <span>Settings</span>
  </Container>
);

export default ProfilePage;
