/* General application, mainly for general, pagination, temp settings */

// PER_PAGE - common pagination per page limit (items per page)
export default {
  ALBUM_MANAGEMENT: {
    ITEMS_PER_PAGE: 10,
  },
  USER_MANAGEMENT: {
    DETAILS: {
      EMAIL_BUTTON: { padding: '5px 15px 5px 15px' },
      BUTTON_SM_SIZE: { padding: '5px 10px 5px 10px', fontSize: '12px' },
    },
  },
  NET_CONNECTION_CHECKER: {
    ENABLED: true,
  },
  DANGER_AREA: {
    BUTTON_SM_SIZE: { padding: '5px 10px 5px 10px' },
  },
  ALBUM_CONTENT: {
    PER_PAGE: 10,
    APPROVE_BUTTON: { padding: '2px 6px', background: '#5cb85c', border: '1px solid #5cb85c' },
    BLOCK_BUTTON: { padding: '2px 6px', background: '#d9534f' },
    USE_NEWTAB: true,
  },
  REPORTED_USERS: {
    APPROVE_BUTTON: { padding: '2px 6px', background: '#5cb85c', border: '1px solid #5cb85c' },
    BLOCK_BUTTON: { padding: '2px 6px', background: '#c9302c' },
    USE_NEWTAB: true,
  },
};
