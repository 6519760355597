import React from 'react';
import { NavLink } from 'react-router-dom';
import MENU from 'helpers/constants/menuConstants';
import styles from '../submenu.module.scss';

const CrmSubMenu = (
  <ul className={styles.subnav}>
    <li className={styles.navItem}>
      <NavLink
        activeClassName={styles.navLinkActive}
        className={styles.navLink}
        to={MENU.ADMIN_MAIN.CRM_USERS_LIST}
      >
        Users list
      </NavLink>
    </li>
    <li className={styles.navItem}>
      <NavLink
        activeClassName={styles.navLinkActive}
        className={styles.navLink}
        to={MENU.ADMIN_MAIN.CRM_TAGS}
      >
        Tags
      </NavLink>
    </li>
  </ul>
);

export default CrmSubMenu;
