/* eslint-disable react-hooks/exhaustive-deps */ // no need to import (like onLoad)
/* eslint-disable dot-notation */ // needed (item.name not found)
import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col, Form,
} from 'react-bootstrap';
import FormTextInput from 'components/UI/texts/text';
import ActionButton from 'components/UI/buttons/actionButton';
import { unQuoute } from 'helpers/utils/string';

import styles from './searchForm.module.scss';

interface FormProps {
  onSubmit: (searchText: string) => void;
}

const ENABLE_PERMAMENT_SAVE = true;
const MIN_VAL = 0;
const MAX_VAL = 60;

const UserManagementSearchForm: React.FC<FormProps> = ({
  onSubmit,
}) => {
  const [searchVal, setSearch] = useState('');
  const saveSearch = (svdsearch: string): void => {
    if (svdsearch !== 'undefined') {
      localStorage.setItem('savedSearch', JSON.stringify(svdsearch));
    }
  };

  useEffect(() => { // onload if in localstorage load saved search
    if (!ENABLE_PERMAMENT_SAVE) { return; }
    const searchFromStorage = localStorage.getItem('savedSearch');
    if (searchFromStorage) {
      const svdsearch = unQuoute(searchFromStorage);
      setSearch(svdsearch);
      onSubmit(svdsearch);
    }
  }, []);

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    if (searchVal.length >= MIN_VAL && searchVal.length < MAX_VAL) {
      saveSearch(searchVal.toLowerCase());
      onSubmit(searchVal.toLowerCase());
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value);
  };

  const searchRowCl = `mt-2 ${styles.searchInput}`;
  const isSubmitDisabled = searchVal.length < MIN_VAL || searchVal.length > MAX_VAL;
  return (
    <Container className={styles.searchContainer} fluid>
      <Form onSubmit={handleSubmit} noValidate>
        <Row className={searchRowCl}>
          <Col>
            <FormTextInput
              value={searchVal}
              onChange={handleSearchChange}
              controlId="user-search"
              label="Search"
              type="text"
              placeholder="Name or phone number"
              inputIcon="search"
              disabled={false}
            />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-left">
            <ActionButton
              title="Show results"
              onClick={handleSubmit}
              disabled={isSubmitDisabled}
              isTextBold
              customStyle={{ padding: '9px 24px 9px 24px' }}
            />
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default UserManagementSearchForm;
