export const initAlbumDetails = {
  id: 'dasdssasddasadsas',
  privacyLevel: 'PUBLIC',
  isSharedAlbum: true,
  categories: ['FASHION'],
  coverPhoto: {},
  creationDate: '2020-01-12T22:31:38.651Z',
};

export const initPictures = [
  { pictureId: '1', pictureName: 'Picture 1', pictureSource: '' },
  { pictureId: '2', pictureName: 'Picture 2', pictureSource: '' },
  { pictureId: '3', pictureName: 'Picture 3', pictureSource: '' },
];

export const modelTags = [
  { label: 'Food', value: 'food' },
  { label: 'Arts', value: 'arts' },
  { label: 'Coss', value: 'coss' },
  { label: 'Shipment', value: 'shimpment' },
];

export const modelSelectedTags = [
  { label: 'Food', value: 'food' },
];
