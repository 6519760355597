import { TableCell, TableRow } from '@material-ui/core';
import React, { useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import RowEditor from './RowEditor';

interface CRMTableRowProps {
    index: number
    row: any
}

const CRMTableRow: React.FC<CRMTableRowProps> = ({ index, row }) => {
    const labelId = `enhanced-table-checkbox-${index}`;
    const [modalActive, setModalActive] = useState(false)

    return (
        <TableRow
            hover
            tabIndex={-1}
            key={row.username}
        >
            <TableCell align="left">
                <Button onClick={() => setModalActive(true)} size='sm' variant='secondary'>
                    Edit
                </Button>
            </TableCell>
            <TableCell style={{ wordBreak: 'break-word', minWidth: "250px" }} align="left">{row.id}</TableCell>
            <TableCell style={{ wordBreak: 'break-word', minWidth: "150px" }} component="td" id={labelId} scope="row" padding="none">
                {row.username}
            </TableCell>
            <TableCell style={{ wordBreak: 'break-word', minWidth: "200px" }} align="left">{row.fullname}</TableCell>
            <TableCell align="left">{row.phoneNumber}</TableCell>
            <TableCell align="left">{row.email}</TableCell>
            <TableCell align="left">{row.category}</TableCell>
            <TableCell align="left">{row.subCategory}</TableCell>
            <TableCell align="left">{row.age}</TableCell>
            <TableCell align="left">{row.gender}</TableCell>
            <TableCell align="left">{row.country}</TableCell>
            <TableCell align="left">{row.job}</TableCell>
            <TableCell align="left">{row.information}</TableCell>
            <TableCell style={{ wordBreak: 'break-word', minWidth: "200px" }} align="left">
                {row.tags && row.tags.map((item, index) => (
                    <Badge
                        style={{ background: '#e9e9e9', color: '#404040', marginRight: '3px' }}
                        variant="info"
                        key={index}
                    >
                        {item}
                    </Badge>
                ))}
            </TableCell>
            <TableCell align="left">
                {row.social.length > 0 && row.social.map((item: string, index: number) => {
                    const lastItem = row.social.length - 1;
                    return index !== lastItem ? item + ', ' : item
                })}
            </TableCell>
            <TableCell style={{ wordBreak: 'break-word', minWidth: "150px" }} align="left">{row.date}</TableCell>
            {modalActive && <RowEditor row={row} setModalActive={setModalActive} />}
        </TableRow>
    );
}

export default CRMTableRow;