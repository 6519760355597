/* eslint-disable @typescript-eslint/ban-types */
import React, { CSSProperties } from 'react';
import { Button } from 'react-bootstrap';
import styles from './dangerButton.module.scss';

interface DangerButtonProps {
  title: string;
  onClick: (e: React.MouseEvent) => void;
  isTextBold?: boolean;
  isDisabled?: boolean;
  customStyle?: CSSProperties | undefined; // Record<string, any>; not working
}

const DangerButton: React.FC<DangerButtonProps> = ({
  title, onClick, isTextBold = false, customStyle, isDisabled = false,
}) => {
  const boldStyle = isTextBold ? styles.mainBold : '';
  const styleMain = `${styles.main} ${boldStyle}`;
  const custStyle = customStyle || {};
  return (
    <Button
      variant="danger"
      className={styleMain}
      style={custStyle}
      disabled={isDisabled}
      onClick={(e: React.MouseEvent): void => { onClick(e); }}
    >
      <span>{title}</span>
    </Button>
  );
};

export default DangerButton;
