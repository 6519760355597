import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { FaUserCircle } from 'react-icons/fa';
import { UserProfileType } from 'types/user/userProfile';
import UserManagementAlbums from 'components/UserManagement/Profile/Albums';
import { initUserProfile } from 'models/user/profile';
import UserService from 'services/user';

import styles from './albumManagement.module.scss';
import UserProfileDetails from './ProfileDetails';

type ProfileProps = {
  userId: string;
}

type ProfileState = {
  userProfile: UserProfileType;
}

export default class UserManagementProfile extends React.Component<ProfileProps, ProfileState> {
  userService: UserService;

  constructor(props: ProfileProps) {
    super(props);
    this.state = {
      userProfile: initUserProfile,
    };
    this.userService = new UserService();
  }

  componentDidMount(): void {
    this.loadUserProfile();
  }

  componentDidUpdate(prevProps): void {
    const { userId } = this.props;
    if (userId !== prevProps.userId) {
      this.loadUserProfile();
    }
  }

  async loadUserProfile(): Promise<any> {
    const { userId } = this.props;
    const response = await this.userService.getUserProfile(userId);
    if (response.data !== undefined) {
      this.setState({ userProfile: response.data });
    }
  }

  render(): React.ReactNode {
    const { userId } = this.props;
    const {
      // userDetails,
      userProfile,
    } = this.state;
    const statColumnClass = `my-auto ${styles.profileStatColumn}`;

    const statUserIconWidth = { xs: 3, md: 3, lg: 3 };
    const statBarWidth = { xs: 3, md: 3, lg: 2 };

    let ProfilePhoto = (
      <FaUserCircle size="5em" className={styles.userProfileIcon} />
    );

    let ProfileSection = (<div className={styles.profileNotFound}>No profile found!</div>);
    if (userProfile) {
      if (userProfile.profilePhoto) {
        ProfilePhoto = (
          <div
            style={{ backgroundImage: `url(${userProfile.profilePhoto.url})` }}
            className={styles.userProfilePhoto}
          />
        );
      }

      ProfileSection = (
        <>
          <Col xs={6} md={6} lg={6}>
            <Row className="mt-2">
              <Col xs={12} md={12} lg={12} xl={8}>
                <Row>
                  <Col xs={statUserIconWidth.xs} md={statUserIconWidth.md} lg={statUserIconWidth.lg}>
                    {ProfilePhoto}
                  </Col>
                  <Col xs={statBarWidth.xs} md={statBarWidth.md} lg={statBarWidth.lg} className={statColumnClass}>
                    <div>Pictures</div>
                    <div>{userProfile.photoCount}</div>
                  </Col>
                  <Col xs={statBarWidth.xs} md={statBarWidth.md} lg={statBarWidth.lg} className={statColumnClass}>
                    <div>Followers</div>
                    <div>{userProfile.followersCount}</div>
                  </Col>
                  <Col xs={statBarWidth.xs} md={statBarWidth.md} lg={statBarWidth.lg} className={statColumnClass}>
                    <div>Following</div>
                    <div>{userProfile.followingCount}</div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="mt-4 mb-3">
              <Col>
                <p className={styles.userStatDescription}>
                  {userProfile.bio}
                  <br />
                  <span className={styles.userStatLink}>{userProfile.website}</span>
                </p>
              </Col>
            </Row>

            <UserManagementAlbums userId={userId} />

          </Col>
        </>
      );
    }

    return (
      <Container fluid>
        <Row>
          <Col xs={6} md={6} lg={6} className={styles.profileDetailsContainer}>
            <UserProfileDetails userId={userId} profileImage={userProfile.profilePhoto} />
          </Col>
          {ProfileSection}
        </Row>
      </Container>
    );
  }
}
