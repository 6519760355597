/* eslint-disable linebreak-style */
/* eslint-disable no-param-reassign */ // to unformat
/* eslint-disable class-methods-use-this */
/* eslint-disable dot-notation */
// User service (get users, user, profile, block, delete)
import { DiscoveryCategoryRaw, DiscoveryCategory } from 'types/discovery/categories';
import { SelectOptionType } from 'types/form/select';

import API_PATH from 'helpers/constants/apiPaths';
import { api } from 'helpers/server/api';

import notify, { NotifyLevels } from 'services/notify';

type DiscoveryCategorySuccess = {
  data: Array<DiscoveryCategory>;
}
type DiscoveryCategoryFailed = {
  data: any;
  status: string;
}

export default class DiscoveryCategoryService {
  mainPath: string;

  constructor() {
    this.mainPath = API_PATH.DISCOVERY.MAIN;
  }

  async getCategories(): Promise<DiscoveryCategorySuccess | DiscoveryCategoryFailed> {
    const response = await api.get(`/v1/${this.mainPath}/${API_PATH.DISCOVERY.CATEGORIES}`, {});
    if (response && response.status !== undefined) {
      notify(' No Discovery categories found!', NotifyLevels.ERROR);
      return { data: undefined, status: response.status };
    }
    return { data: this.formatCategories(response['categories']), status: undefined };
  }

  async setCategories(formattedCategories: DiscoveryCategory[]): Promise<string> {
    const unformattedCategories = this.unFormatCategories(formattedCategories);
    const response = await api.post(`/v1/${this.mainPath}/${API_PATH.DISCOVERY.CATEGORIES}`, { categories: unformattedCategories });
    return response.status;
  }

  formatCategories(categories: DiscoveryCategoryRaw[]): DiscoveryCategory[] {
    const formattedCategories = categories.map((item) => ({ ...item, id: item.code }));
    return formattedCategories;
  }

  getCategoriesAsOptions(categories: DiscoveryCategory[]): SelectOptionType[] {
    const selectCategories = categories.map((item) => ({ label: item.name, value: item.code }));
    return selectCategories;
  }

  // restore categories to send back server
  unFormatCategories(categories: DiscoveryCategory[]): string[] {
    return categories.map((item) => item.code);
  }
}
