import React from 'react';
import { Row, Col } from 'react-bootstrap';
import FormTextInput from 'components/UI/texts/text';
import '../override.scss';

interface MngUserTextRow {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  width?: widthType;
  disabled?: boolean;
  errorNotice?: string;
}

type widthType = {
    label: number;
    value: number;
}

const MgnUserTextRow: React.FC<MngUserTextRow> = ({
  label, value, width, onChange, disabled = true, errorNotice = '',
}) => {
  const widths = width || { label: 3, value: 9 };
  return (
    <Row>
      <Col xs={widths.value} md={widths.value}>
        <FormTextInput
          value={value}
          controlId={value}
          label={label}
          placeholder=""
          onChange={onChange}
          type="text"
          disabled={disabled}
          errorNotice={errorNotice}
        />
      </Col>
    </Row>
  );
};

export default MgnUserTextRow;
