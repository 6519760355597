import * as React from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';

import styles from '../TagsBox.module.scss';
import TagsBoxItem from '../TagsBoxItem';
import useInView from 'react-cool-inview';

import loadingImage from 'images/loader.gif';

interface TagsBoxItemsProps {
    data: Array<string>
    inputValue: string
    totalItems: number
    storageCount: number
    itemsCount: number
    addToSelectedTags: (item: string) => void
    updateData: () => void
}

const TagsBoxItems: React.FC<TagsBoxItemsProps> = ({ data, inputValue, totalItems, itemsCount, storageCount, addToSelectedTags, updateData }) => {

    const addItemTitle = (
        <><AddCircleIcon /><span> {inputValue}</span></>
    );

    const { observe } = useInView({
        onEnter: () => updateData(),
    });

    return (
        <ul className={styles.tagsList}>
            {itemsCount > 0 ? (
                <>
                    {data.map(item => (
                        <TagsBoxItem addToSelectedTags={addToSelectedTags} value={item} title={item} />
                    ))}
                    {storageCount < totalItems && <img className={styles.loader} ref={observe} src={loadingImage} />}
                </>
            ) : (
                <>
                    <li className={`${styles.tagsListItem} ${styles.description}`}>There is no item</li>
                    {inputValue && (
                        <TagsBoxItem addToSelectedTags={addToSelectedTags} value={inputValue} title={addItemTitle} />
                    )}

                </>
            )}
        </ul>
    );
}

export default TagsBoxItems;