import React from 'react';
import ContentAlbumsManagement from './Albums';
import ContentMediaManagement from './Media';

type ContentProps = {
  contentType: string;
}

const ContentManagement: React.FC<ContentProps> = ({
  contentType,
}) => {
  const ManagementContainer = contentType === 'albums' ? <ContentAlbumsManagement /> : <ContentMediaManagement />;
  return (
    <div>
      {ManagementContainer}
    </div>
  );
};

export default ContentManagement;
