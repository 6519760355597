import React, { CSSProperties } from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './detailsRow.module.scss';

export enum RowHeights {
  Fixed = 'fixed',
  Float = 'float'
}

interface ManagementDetailsRowProps {
  title: string;
  value: React.ReactElement | string;
  rowClassName: string;
  colWidthLabel: Widths;
  colWidthValue: Widths;
  rowHeight?: string;
  isHMTLElement?: boolean;
  isBordered?: boolean;
  isPadded?: boolean;
  direction?: string;
  customValStyle?: CSSProperties | undefined;
}

type Widths = {
    xs: number;
    md: number;
    lg: number;
}

const ManagementDetailsRow: React.FC<ManagementDetailsRowProps> = ({
  title, value, isBordered = true, isPadded = true, rowClassName, colWidthLabel, colWidthValue, isHMTLElement,
  direction = 'left', rowHeight = RowHeights.Fixed, customValStyle,
}) => {
  const containerClasses = { main: styles.container, value: styles.valueCol };
  const containerFloatClasses = { main: styles.containerFloat, value: styles.valueColFloat };
  const container = rowHeight === RowHeights.Fixed ? containerClasses : containerFloatClasses;
  const borderClass = isBordered ? styles.bordered : '';
  const unPad = isBordered ? '' : styles.unpad;
  const unPadWithBorder = isPadded ? '' : styles.unpad;
  const valueElement = !isHMTLElement ? <span>{value}</span> : value;
  const classContainer = `${rowClassName} ${container.main} ${borderClass} ${unPad} ${unPadWithBorder}`;
  const titleSection = (
    <Col xs={colWidthLabel.xs} md={colWidthLabel.md} lg={colWidthLabel.lg}>
      <span className={styles.detailsTitle}>{title}</span>
    </Col>
  );
  const titleSectionLt = direction === 'left' ? titleSection : null;
  const titleSectionRt = direction === 'right' ? titleSection : null;
  const custValStyle = customValStyle || {};
  return (
    <Row className={classContainer}>
      {titleSectionLt}
      <Col xs={colWidthValue.xs} md={colWidthValue.md} lg={colWidthValue.lg} className={container.value} style={custValStyle}>
        {valueElement}
      </Col>
      {titleSectionRt}
    </Row>
  );
};

export default ManagementDetailsRow;
