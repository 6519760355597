export default {
  IMAGE_XS: '-xs' as const, // 84 x 84
  IMAGE_SMALL: '-s' as const, // 120 x 120
  IMAGE_MEDIUM: '-m' as const, // 400 x 400
  IMAGE_LARGE: '-l' as const, // 600 x 600
  IMAGE_XL: '-xl' as const, // 800 x 800
  IMAGE_XXL: '-xxl' as const,
  IMAGE_LARGEST: '-xxxl' as const,
  IMAGE_AVATAR: '-a' as const, // 36 x 36
};
