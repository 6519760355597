import React from 'react';
import notify, { NotifyLevels } from 'services/notify';

enum Statuses {
    Online = 'online',
    Offline = 'offline'
}

type ConnectionProps = {
  enabled: boolean;
}

type ConnectionState = {
  status: string;
}

export default class NetConnectionChecker extends React.Component<ConnectionProps, ConnectionState> {
  constructor(props: ConnectionProps) {
    super(props);
    this.state = { status: Statuses.Online };
    this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
  }

  componentDidMount(): void {
    const { enabled } = this.props;
    if (enabled) {
      this.addOfflineCheckMode();
    }
  }

  updateOnlineStatus(): void {
    const { status } = this.state;
    const newCondition = navigator.onLine ? Statuses.Online : Statuses.Offline;

    if (status === Statuses.Online && newCondition === Statuses.Offline) {
      notify('Internet connection lost ', NotifyLevels.ERROR);
    }
    if (status === Statuses.Offline && newCondition === Statuses.Online) {
      notify('Internet connection restored ', NotifyLevels.SUCCESS);
    }

    this.setState({ status: newCondition });
  }

  addOfflineCheckMode(): void {
    window.addEventListener(Statuses.Online, this.updateOnlineStatus);
    window.addEventListener(Statuses.Offline, this.updateOnlineStatus);
  }

  render(): React.ReactNode {
    return (
      <span />
    );
  }
}
