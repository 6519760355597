import CRMTagsComponent from 'components/CRM/Tags';
import * as React from 'react';
import { Container } from 'react-bootstrap';
 
const CRMTagsPage: React.FC = () => {
    return (  
        <Container fluid>
            <CRMTagsComponent />
        </Container>
    );
}
 
export default CRMTagsPage;