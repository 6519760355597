import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './detailsTitle.module.scss';

interface ManagementDetailsTitleProps {
  title: string;
  rowClassName: string;
  colWidthLabel: Widths;
  offsetLeft?: string;
}

type Widths = {
    xs: number;
    md: number;
    lg: number;
}

const ManagementDetailsTitle: React.FC<ManagementDetailsTitleProps> = ({
  title, rowClassName, colWidthLabel, offsetLeft = '-35px',
}) => {
  const rowClass = `${rowClassName} ${styles.rowTitle}`;
  return (
    <Row className={rowClass} style={{ marginLeft: offsetLeft }}>
      <Col xs={colWidthLabel.xs} md={colWidthLabel.md} lg={colWidthLabel.lg}>
        <h3>{title}</h3>
      </Col>
    </Row>
  );
};

export default ManagementDetailsTitle;
