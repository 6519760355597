/* eslint-disable linebreak-style */
/* eslint-disable dot-notation */
// User service (get users, user, profile, block, delete)
import {
  UserListType,
  UserListFailedType,
  UserDataFailed,
  UserProfileSuccess,
  UserNameSuccess,
  UserDetailsSuccess,
} from 'types/services/user';

import API_PATH from 'helpers/constants/apiPaths';
import { api } from 'helpers/server/api';

import notify, { NotifyLevels } from 'services/notify';

export function changePage(page, itemsCount = 20) {
  let offset = 0;
  offset += (page * itemsCount) - itemsCount;

  return offset;
}

export default class UserService {
  usersPath: string;

  constructor() {
    this.usersPath = API_PATH.USERS.LIST;
  }

  getUsers = async (query: string, pageNum = 1): Promise<UserListType | UserListFailedType> => {
    const response = await api.post('/v3/users', {
      limit: 20,
      offset: changePage(pageNum),
      query,
    });

    if (response && response['content']) {
      const currentPage = response['currentPage'] + 1;
      return {
        data: response['content'],
        pagination: {
          pageCount: response['pageCount'],
          currentPage,
        },
        total: response['total'],
      };
    }
    return { data: undefined, pagination: undefined, status: response.status };
  }

  getLatestRegisteredUsers = async (pageLimit = 20): Promise<UserListType | UserListFailedType> => {
    const path = '/v3/users';
    const response = await api.post(path, { limit: pageLimit });

    if (response && response['content']) {
      return {
        data: response['content'],
        pagination: {
          pageCount: 1,
          currentPage: 1,
        },
        total: response['total'],
      };
    }
    return { data: undefined, pagination: undefined, status: response.status };
  }

  async getUserDetails(userId: string): Promise<UserDetailsSuccess | any> {
    const response = await api.get(`/v1/${this.usersPath}/${userId}`, {});
    if (response) {
      return { data: response };
    }
    notify(' User Details not found!', NotifyLevels.ERROR);
    return { data: undefined };
  }

  async getUserProfile(userId: string): Promise<UserProfileSuccess | UserDataFailed> {
    const response = await api.get(`/v1/${this.usersPath}/${userId}/${API_PATH.USERS.PROFILE}`, {});
    if (response && response.status && response['id'] === undefined) {
      notify(' User Profile not found!', NotifyLevels.ERROR);
      return { data: undefined, status: response.status };
    }
    return { data: response, status: response.status };
  }

  async getUserName(userId: string): Promise<UserNameSuccess | UserDataFailed> {
    const response = await api.get(`/v1/${this.usersPath}/${userId}/${API_PATH.USERS.PROFILE}`, {});
    if (response && response.status && response['id'] === undefined) {
      notify(' User Name not found!', NotifyLevels.ERROR);
      return { data: undefined, status: response.status };
    }
    return { data: response['username'], status: response.status };
  }

  async getUsersTotal(): Promise<string | number> {
    const response = await api.get(`/v1/${this.usersPath}/${API_PATH.USERS.COUNT}`, {});
    if (response.status === undefined) {
      return response['totalCount'];
    }
    return response.status;
  }

  async unverifyUser(userId: string): Promise<string> {
    const response = await api.remove(`/v1/${this.usersPath}/${userId}/${API_PATH.USERS.UNVERIFY_USER}`, {});
    return response.status;
  }

  async verifyUser(userId: string): Promise<string> {
    const response = await api.post(`/v1/${this.usersPath}/${userId}/${API_PATH.USERS.VERIFY_USER}`, {});
    return response.status;
  }

  async changeBaseStorage(baseStorage: number, userId: string): Promise<any> {
    const response = await api.post(`/v1/${this.usersPath}/${userId}/${API_PATH.USERS.CHANGE_BASE_STORAGE}`, { baseStorage });
    return response.status;
  }

  async changeEmail(userId: string): Promise<string> {
    const response = await api.post(`/v1/${this.usersPath}/${userId}/${API_PATH.USERS.CHANGE_EMAIL}`, {});
    return response.status;
  }

  async blockUser(userId: string, reason: string): Promise<string> {
    const response = await api.post(`/v1/${this.usersPath}/${userId}/${API_PATH.USERS.BLOCK_USER}`, { reason });
    return response.status;
  }

  // @todo add real path when backend ready
  async approveUser(userId: string): Promise<string> {
    const response = await api.post(`/v1/${this.usersPath}/${userId}/${API_PATH.USERS.APPROVE_USER}`, {});
    return response.status;
  }

  async unBlockUser(userId: string, reason: string): Promise<string> {
    const response = await api.remove(`/v1/${this.usersPath}/${userId}/${API_PATH.USERS.UNBLOCK_USER}`, { reason });
    return response.status;
  }

  async addToSuggestedList(userId: string, countries: string[]): Promise<string> {
    const response = await api.post(`/v1/${this.usersPath}/${userId}/${API_PATH.USERS.ADD_SUGGESTED}`, { countries });
    if (response.status !== undefined) {
      notify('Currently supports only 16 countries to add to Suggestion list', NotifyLevels.ERROR);
    }
    return response.status;
  }

  async removedFromSuggestedList(userId: string): Promise<string> {
    const response = await api.remove(`/v1/${this.usersPath}/${userId}/${API_PATH.USERS.REMOVE_SUGGESTED}`, {});
    return response.status;
  }

  async addToBackupList(userId: string, countries: string[]): Promise<string> {
    const response = await api.post(`/v1/${this.usersPath}/${userId}/${API_PATH.USERS.ADD_BACKUP}`, { countries });
    if (response.status !== undefined) {
      notify('Currently supports only 16 countries to add to Backup list', NotifyLevels.ERROR);
    }
    return response.status;
  }

  async removeFromBackupList(userId: string): Promise<string> {
    const response = await api.remove(`/v1/${this.usersPath}/${userId}/${API_PATH.USERS.REMOVE_BACKUP}`, {});
    return response.status;
  }

  async deleteUser(userId: string, reason: string): Promise<void> {
    await api.remove(`/v1/${this.usersPath}/${userId}`, { reason });
  }

  async deletePicture(userId: string, reason: string): Promise<void> {
    await api.remove(`/v1/${this.usersPath}/${userId}/profile-photo`, { reason });
  }

  async deleteDevice(userId: string, deviceId: string): Promise<void> {
    await api.remove(`/v1/${this.usersPath}/${userId}/device/${deviceId}`);
  }

  async getUserFeatures(userId: string): Promise<any> {
    const response = await api.get(`/v1/${this.usersPath}/${userId}/features`);
    return response;
  }

  async updateUserFeature(userId: string, key: string, value: boolean): Promise<any> {
    const response = await api.put(`/v1/${this.usersPath}/${userId}/features/${key}`, { value });
    return response;
  }
}
