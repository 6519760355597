/* eslint-disable import/prefer-default-export */
export const initialVerificationUsers = [
  {
    id: 1, fullname: 'Alex Cars', email: 'alex@alb.com', locale: 'en_US', registrationDate: '2020-01-16T22:31:38.651Z',
  },
  {
    id: 2, fullname: 'Bob Jones', email: 'bob@alb.com', locale: 'en_US', registrationDate: '2020-01-16T22:31:38.651Z',
  },
  {
    id: 3, fullname: 'Cob Brian', email: 'cob@alb.com', locale: 'fr_FR', registrationDate: '2020-01-15T22:31:38.651Z',
  },
  {
    id: 4, fullname: 'James Cars', email: 'usperson@alb.com', locale: 'fr_FR', registrationDate: '2020-01-18T22:31:38.651Z',
  },
  {
    id: 5, fullname: 'Bob Robert', email: 'robert@alb.com', locale: 'en_US', registrationDate: '2020-01-12T22:31:38.651Z',
  },
  {
    id: 6, fullname: 'Mitchell Haskin', email: 'mitchell@alb.com', locale: 'en_US', registrationDate: '2020-01-02T22:31:38.651Z',
  },
];
