import React, {useEffect, useState} from 'react';
import { Container } from 'react-bootstrap';
import crmService from 'services/crm';
import TagsList from './TagsList';
import TagsSearch from './TagsSearch/index';
 
const CRMTagsComponent: React.FC = () => {
    const [tags, setTags] = useState<any>('')
    const [total, setTotal] = useState<number>(0);
    const [pageable, setPageable] = useState({offset: 0, size: 10});

    const getTags = async (query: string = '', offset: number = pageable.offset, size: number = pageable.size) => {
        const response = await crmService.getTags(query, offset, size);

        setTags(response.content)
        setTotal(response.total)
        setPageable(response.pageable)
    }

    useEffect(() => {
        getTags();
    }, [])

    return (  
        <Container fluid>
            <TagsSearch searchAction={getTags} />
            <div className='mb-2'>Number of tags: {total}</div>
            {tags ? tags.length > 0 ? <TagsList getData={getTags} total={total} data={tags} /> : 'There is no item' : 'Please waite...'}
        </Container>
    );
}
 
export default CRMTagsComponent;