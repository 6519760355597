import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import UserService from 'services/user';
import notify from 'services/notify';
import styles from '../albumDetails.module.scss';
import StorageUnit from '../enums';

export interface StorageModalProps {
    handleClose: () => void;
    userId: string;
    getNewData: () => void;
    currentStorageVal: number;
}

const StorageModal: React.FC<StorageModalProps> = ({
  handleClose, getNewData, userId, currentStorageVal,
}) => {
  const [inputValue, setInputValue] = useState(currentStorageVal.toString());
  const [unit, setUnit] = useState('GB');
  const userService = new UserService();

  const BytesConvertor = (value: number, storageUnit: string) => {
    switch (storageUnit) {
      case 'TB':
        return (value * StorageUnit.TB);
      case 'GB':
        return (value * StorageUnit.GB);
      default:
        return (value * StorageUnit.MB);
    }
  };

  const handleChangeBaseStorage = async () => {
    const baseStorage = BytesConvertor(parseFloat(inputValue), unit);
    const response = await userService.changeBaseStorage(baseStorage, userId);
    const errorMessage = 'The value can not be less than your used storage';
    const successMessage = 'Base storage is successfully changed';
    if (baseStorage) {
      if (response !== undefined) {
        notify(errorMessage, 'error');
      } else {
        notify(successMessage);
        handleClose();
        getNewData();
      }
    }
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modalBox}>
        <button
          onClick={() => handleClose()}
          className={styles.closeBTN}
          type="button"
        >
          X
        </button>
        <input
          onChange={(e) => setInputValue(e.target.value)}
          type="number"
          value={inputValue}
          placeholder="enter the value"
          min={currentStorageVal}
          step="0.01"
        />
        <select defaultValue={unit} onChange={(e) => setUnit(e.target.value)}>
          <option value="TB">TB</option>
          <option value="GB">GB</option>
          <option value="MB">MB</option>
        </select>
        <Button onClick={() => handleChangeBaseStorage()} size="sm">Change</Button>
      </div>
    </div>
  );
};

export default StorageModal;
