/* eslint-disable @typescript-eslint/no-explicit-any */
export const MAXIMUM_SCROLL_PAGES = 100;

function isBottom(el: any): boolean {
  if (el === null) { return false; }
  return el.getBoundingClientRect().bottom <= window.innerHeight;
}

export function trackScrolling(
  containerId: string,
  onLoadPortionCallback: () => void,
): boolean {
  const wrappedElement = document.getElementById(containerId);
  if (isBottom(wrappedElement)) {
    onLoadPortionCallback();
    return true;
  }
  return false;
}
