/* eslint-disable linebreak-style */ // @todo editor settings
/* eslint-disable dot-notation */
/* eslint-disable class-methods-use-this */

// Authentication service
import { UserCredentials } from 'types/user/userCredentials';

import API_PATH from 'helpers/constants/apiPaths';
import { api } from 'helpers/server/api';

export default class AuthService {
  async signIn(credentials: UserCredentials): Promise<string> {
    const response = await api.post(`/v1/${API_PATH.AUTH.LOGIN}`, credentials);
    if (response && response['token'] !== undefined) {
      return response['token'];
    }
    return '';
  }

  async signOut(): Promise<string> {
    const response = await api.post(`/v1/${API_PATH.AUTH.LOGOUT}`, {});
    if (response && response['status'] !== undefined) {
      return response['status'];
    }
    return '';
  }
}
