import React from 'react';
import { NavLink } from 'react-router-dom';
import MENU from 'helpers/constants/menuConstants';
import styles from '../submenu.module.scss';

const DiscoverySubMenu = (
  <ul className={styles.subnav}>
    <li className={styles.navItem}>
      <NavLink
        activeClassName={styles.navLinkActive}
        className={styles.navLink}
        to={MENU.ADMIN_MAIN.DISCOVERY_CATEGORY_ORDER}
      >
        Category Order
      </NavLink>
    </li>
  </ul>
);

export default DiscoverySubMenu;
