/* eslint-disable react/no-unused-state */
import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import MENU from 'helpers/constants/menuConstants';
import LinkConstants from 'helpers/constants/linkConstants';
import Settings from 'helpers/constants/generalSettings';
import { openTab } from 'helpers/utils/url';
import { ReportAlbumType } from 'types/report';
import { AlbumsType } from 'types/album';
import BlockReasonArea, { ReasonModes } from 'components/UI/management/blockReason';

import ListPagation from 'components/UI/table/listPagination';
import ReportedService from 'services/report';
import AlbumService from 'services/album';
import notify, { NotifyLevels } from 'services/notify';

import CommonListResults from 'components/UI/table/Results/commonList';
import styles from './contentAlbums.module.scss';
import ContentAlbumItem from './ContentAlbumItem';

type ContentAlbumProps = {}

type ContentAlbumState = {
  albums: Array<ReportAlbumType>;
  isDirectToAlbum: boolean;
  selectedUser: string;
  selectedAlbum: string;
  selectedAlbumName: string;
  paginationBlocked: boolean;
  totalItems: number;
  perPage: number;
  albumDetails: AlbumsType[];
  loadingItems: boolean;
  showReasonArea: boolean;
}

const { USE_NEWTAB } = Settings.ALBUM_CONTENT;

export default class ContentAlbumsManagement extends React.Component<ContentAlbumProps, ContentAlbumState> {
  reportService: ReportedService;

  albumService: AlbumService;

  constructor(props: ContentAlbumProps) {
    super(props);
    this.state = {
      albums: [],
      isDirectToAlbum: false,
      selectedUser: '1',
      selectedAlbum: '1',
      selectedAlbumName: '',
      paginationBlocked: false,
      albumDetails: [],
      totalItems: 1,
      perPage: 1,
      loadingItems: false,
      showReasonArea: false,
    };
    this.reportService = new ReportedService();
    this.albumService = new AlbumService();
    this.handleContentItemEnter = this.handleContentItemEnter.bind(this);
    this.handleApproveItem = this.handleApproveItem.bind(this);
    this.triggerBlockItemReasonDialog = this.triggerBlockItemReasonDialog.bind(this);
    this.onGottenBlockReason = this.onGottenBlockReason.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount(): void {
    this.loadReportedUsersAlbums();
  }

  onGottenBlockReason(reason: string): void {
    this.setState({ showReasonArea: false });
    if (reason === '') { return; }
    const { selectedUser, selectedAlbum } = this.state;
    this.handleBlockItem(selectedUser, selectedAlbum, reason);
  }

  triggerBlockItemReasonDialog(albumData: ReportAlbumType): void {
    this.setState({
      showReasonArea: true,
      selectedUser: albumData.userId,
      selectedAlbum: albumData.id,
    });
  }

  handleContentItemEnter(albumsData: ReportAlbumType): void{
    if (albumsData && albumsData.id !== '-1') {
      this.setState({
        isDirectToAlbum: true,
        selectedUser: albumsData.userId,
        selectedAlbum: albumsData.id,
      });
    }
  }

  handlePageChange(pageNum: number): void {
    this.loadReportedUsersAlbums(pageNum);
  }

  async loadReportedUsersAlbums(newPage?: number): Promise<any> {
    const curPage = newPage || 1;
    this.setState({ loadingItems: true });

    const response = await this.reportService.getUserAlbums(curPage);
    if (response.data !== undefined && response.data.length > 0) {
      const { pagination } = response;
      this.setState({
        albumDetails: response.data,
        totalItems: pagination.total,
        perPage: pagination.size,
      });
      this.addAlbums(this.reportService.getFormattedAlbums(response.data));
    }
    this.setState({ loadingItems: false });
  }

  addAlbums(newAlbums: ReportAlbumType[]): void {
    this.setState({ albums: newAlbums });
  }

  async handleApproveItem(albumsData: ReportAlbumType): Promise<any> {
    if (albumsData && albumsData.id !== '-1') {
      const status = await this.albumService.approveAlbum(albumsData.userId, albumsData.id);
      if (status === undefined) {
        notify('Approve album applied!', NotifyLevels.SUCCESS);
        this.loadReportedUsersAlbums();
      }
    }
  }

  async handleBlockItem(userId: string, albumId: string, reason: string): Promise<any> {
    const status = await this.albumService.blockAlbum(userId, albumId, reason);
    if (status === undefined) {
      notify('Block album applied!', NotifyLevels.SUCCESS);
      this.loadReportedUsersAlbums();
    }
  }

  render(): React.ReactNode {
    const {
      albums,
      isDirectToAlbum,
      selectedUser,
      selectedAlbum,
      selectedAlbumName,
      paginationBlocked,
      totalItems,
      perPage,
      showReasonArea,
      loadingItems,
    } = this.state;
    if (isDirectToAlbum) {
      const pathName = `${MENU.ADMIN_MAIN.ALBUM_MANAGEMENT.split(':')[0]}:${selectedUser}/:${selectedAlbum}`;
      this.setState({ isDirectToAlbum: false });
      if (USE_NEWTAB) {
        openTab(pathName);
      } else {
        return (
          <Redirect
            to={{ pathname: pathName, state: { from: LinkConstants.REPORT_ALBUMS, albumName: selectedAlbumName } }}
          />
        );
      }
    }
    const width = { xs: 4, md: 3, lg: 2 };
    const contentItems = albums.map((item) => (
      <Col key={`${item.id} ${item.id}`} xs={width.xs} md={width.md} lg={width.lg} className={styles.contentCol}>
        <ContentAlbumItem
          albumData={item}
          onApproveItem={this.handleApproveItem}
          onBlockItem={this.triggerBlockItemReasonDialog}
          onAlbumItemEnter={this.handleContentItemEnter}
        />
      </Col>
    ));

    const albumsEmpty = (
      <CommonListResults loadingItems={loadingItems} loadingTitle="Loading Albums..." noResultsTitle="No Albums found" />
    );

    const albumsSection = (
      <Row className={styles.contentRow}>
        {contentItems}
      </Row>
    );
    const showAlbum = albums && albums.length > 0 ? albumsSection : albumsEmpty;

    return (
      <Container fluid>
        <Row className={styles.contentSelectRow}>
          <Col xs={4} md={4} lg={3} xl={2} className={styles.contentSelectRowCol}>
            <span>Most reported albums:</span>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            {showAlbum}
          </Col>
        </Row>
        <ListPagation
          firstPage={1}
          perPage={perPage}
          itemsCount={totalItems}
          onClick={this.handlePageChange}
          isBlocked={paginationBlocked}
        />
        <BlockReasonArea
          page="content_albums"
          mode={ReasonModes.Single}
          dialogTitle="Block content item"
          showArea={showReasonArea}
          onBlockReasonAreaUpdate={this.onGottenBlockReason}
        />
      </Container>
    );
  }
}
