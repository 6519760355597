import React from 'react';
import styles from './notFound.module.scss';

const NotFound = (): React.ReactElement => (
  <div className={styles.container}>
    <strong>Page Not Found</strong>
  </div>
);

export default NotFound;
