export default {
  AUTH: {
    LOGIN: 'login' as const,
    LOGOUT: 'logout' as const,
  },
  USERS: {
    LIST: 'users' as const,
    USER: 'users' as const,
    BLOCK_USER: 'block' as const,
    UNBLOCK_USER: 'block' as const,
    APPROVE_USER: 'approve' as const,
    DELETE_USER: 'delete' as const,
    CHANGE_EMAIL: 'email' as const, // @todo determine if correct
    UNVERIFY_USER: 'verify' as const,
    CHANGE_BASE_STORAGE: 'change-base-storage' as const,
    VERIFY_USER: 'verify' as const,
    LATEST_REGISTERED: 'latest' as const,
    PROFILE: 'profile' as const,
    ADD_SUGGESTED: 'suggestion' as const,
    REMOVE_SUGGESTED: 'suggestion' as const,
    ADD_BACKUP: 'backup' as const,
    REMOVE_BACKUP: 'backup' as const,
    COUNT: 'count' as const,
    FEATURES: 'features' as const,
  },
  ACTION_HISTORY: {
    MAIN: 'users' as const,
    LIST: 'actions' as const,
    UNDO: 'undo' as const,
  },
  ALBUM: {
    LIST: 'albums' as const,
    DETAILS: 'details' as const,
    ITEMS: 'content-items' as const,
    BLOCK: 'block' as const,
    CATEGORY: 'category' as const,
    REPORT_ITEMS: 'report' as const,
    APPROVE_ALBUM: 'report' as const,
    COVER_PHOTO: 'cover-photo' as const,
    RECALCULATE_USED_STORAGE: 'recalculate-used-storage' as const,
  },
  REPORT: {
    MAIN: 'report' as const,
    USERS: 'users' as const,
    ALBUMS: 'albums' as const,
    IMAGE_ITEMS: 'content-item' as const,
  },
  DISCOVERY: {
    MAIN: 'discovery' as const,
    CATEGORIES: 'categories' as const,
  },
  HEALTH: {
    MAIN: 'health' as const,
    LIVELINESS: 'liveliness' as const,
  },
  COMMON: {
    COUNTRIES: 'countries' as const,
  },
};
