import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import ActionButton from 'components/UI/buttons/actionButton';
import AuthService from 'services/auth';

import MENU from 'helpers/constants/menuConstants';

const DefaultHeaderNav: React.FC = () => {
  const [isDirectLogout, setDirectLogout] = useState(false);
  const authService = new AuthService();

  const logout = async (): Promise<any> => { // any allowed (no need to return value)
    const response = await authService.signOut();
    if (response !== '') {
      localStorage.removeItem('accessToken');
    }
    setDirectLogout(true);
  };

  if (isDirectLogout) {
    return (
      <Redirect
        to={{ pathname: MENU.PUBLIC.LOGIN }}
      />
    );
  }

  return (
    <ActionButton
      title="Logout"
      onClick={logout}
      customStyle={{ marginTop: '1px', padding: '2px 10px 2px 10px' }}
    />
  );
};

export default DefaultHeaderNav;
