import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Menu from 'helpers/constants/menuConstants';
import logo from 'assets/logo.svg';
import DefaultHeaderNav from './headerNav';
import styles from '../defaultLayout.module.scss';

const LayoutHeader: React.FC = () => (
  <Container className={styles.layoutHeader} fluid>
    <Row>
      <Col md="9" lg="9" className={styles.logo}>
        <Link className={styles.logo} to={Menu.ADMIN_MAIN.USER_MANAGEMENT}>
          <img className={styles.img} alt="Albums Admin" src={logo} />
          <span className={styles.logoText}>Admin Panel</span>
        </Link>
      </Col>
      <Col md="3" lg="3" className={styles.layoutHeaderNav}>
        <DefaultHeaderNav />
      </Col>
    </Row>
  </Container>
);

export default LayoutHeader;
