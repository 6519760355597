export default {
  PUBLIC: {
    LOGIN: '/' as const,
    UNAUTHORIZED: '/unauthorized' as const,
    NOT_FOUND: '/not-found' as const,
  },
  ADMIN_MAIN: {
    DASHBOARD: '/dashboard' as const,
    USER_MANAGEMENT: '/user-management' as const,
    USER_VERIFICATION: '/user-verification' as const,
    USER_MANAGEMENT_PROFILE: '/user-profile:id' as const,
    ALBUM_MANAGEMENT: '/album-management:id/:albumId' as const,
    CONTENT_MEDIA: '/content-media' as const,
    CONTENT_USERS: '/content-users' as const,
    CONTENT_ALBUM: '/content-albums' as const,
    DISCOVERY: '/discovery' as const,
    DISCOVERY_CATEGORY_ORDER: '/category-order' as const,
    CRM_USERS_LIST: '/crm/users-list' as const,
    CRM_TAGS: '/crm/tags' as const,
    PROFILE: '/admin-profile' as const,
  },
};
