import React, { ReactNode } from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './defaultLayout.module.scss';
import LayoutSideBar from './sidebar/sidebar';

interface ContentProps {
  children: ReactNode;
}

const LayoutContent: React.FC<ContentProps> = ({ children }) => (
  <Row>
    <Col lg="2" xs="2">
      <LayoutSideBar />
    </Col>
    <Col lg="10" xs="10">
      <main role="main" className={styles.layoutContent}>
        {children}
      </main>
    </Col>
  </Row>
);

export default LayoutContent;
