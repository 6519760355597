/* eslint-disable import/prefer-default-export */

type ValueType = string | number;

// @param expireTime (int) seconds
export function saveWithExpiry(key: string, value: ValueType, expireTime = 60000): void {
  const now = new Date();
  const item = {
    value,
    expiry: now.getTime() + (expireTime * 60000),
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key: string): ValueType {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return '';
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return '';
  }
  return item.value;
}
