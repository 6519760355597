/* eslint-disable import/prefer-default-export */ // no default

function getDatetime(datePart: string): string {
  const timePiece = datePart.split(':');
  const time = `${timePiece[0]}:${timePiece[1]}`;
  return time;
}

export function formatDateFromDatetime(date: string, viewTime?: boolean, shortYear?: boolean): string {
  let datePiece: string[] = [];
  let day: string[] = [];
  let isError = false;

  if (date && date.indexOf('-') > -1) {
    datePiece = date.split('-');
    if (datePiece && datePiece.length > 2) {
      day = datePiece[2].split('T');
    } else {
      isError = true;
    }
  } else {
    isError = true;
  }
  if (isError) {
    return date;
  }

  const year = shortYear ? datePiece[0].substr(2, 2) : datePiece[0];
  let formattedDate = `${day[0]}/${datePiece[1]}/${year}`;

  if (viewTime) {
    const time = getDatetime(day[1]);
    formattedDate = `${time} ${formattedDate}`;
  }

  return formattedDate;
}
