import { FormErrors } from 'types/form/formErrors';
import { UserCredentials } from 'types/user/userCredentials';

import { getErrorsFromValidationMap } from 'helpers/utils/validators';

const validate = (values: UserCredentials): FormErrors<UserCredentials> => {
  let errors: FormErrors<UserCredentials> = {};

  const validationMap = [{
    key: 'email',
    title: 'E-mail',
    value: values.email,
    isRequired: true,
    validators: [{ minLength: 5, maxLength: 30, isEmail: true }],
  },
  {
    key: 'password',
    title: 'Password',
    value: values.password,
    isRequired: true,
    validators: [{ minLength: 3, maxLength: 30 }],
  },
  ];

  errors = getErrorsFromValidationMap<UserCredentials>(validationMap);
  return errors;
};

export default validate;
