/* eslint-disable class-methods-use-this */ // @todo remove when ready
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import React from 'react';
import { Container } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { DataTableColumnType } from 'types/datatable';
import { UserVerificationTableType } from 'types/user/userTable';
import ListPagation from 'components/UI/table/listPagination';
import UserManagementSearchForm from 'components/UserManagement/Search';
import { formatDateFromDatetime } from 'helpers/utils/date';
import { initialVerificationUsers } from 'models/user/verificationList';

import ListResults from '../../UI/table/Results';
import UserVerificationModal from './ManageVerification';

import styles from './userVerificationList.module.scss';
import './override.scss';

type ListProps = {
  title: string;
}

interface State {
  users: Array<UserVerificationTableType>;
  paginationBlocked: boolean;
  selectedUserId: string | number;
  userVerificatinShow: boolean;
}

export default class UserVerifictionList extends React.Component<ListProps, State> {
  columns: Array<DataTableColumnType>;

  constructor(props: ListProps) {
    super(props);
    this.state = {
      users: initialVerificationUsers,
      paginationBlocked: false,
      selectedUserId: -1,
      userVerificatinShow: false,
    };
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.manageVerificationModal = this.manageVerificationModal.bind(this);
    this.manageVerificationModalHide = this.manageVerificationModalHide.bind(this);

    const formatRegistrationDate = (cell: string): React.ReactNode => (
      <div>{formatDateFromDatetime(cell)}</div>
    );

    this.columns = [{
      dataField: 'id',
      text: '#',
      classes: styles.userTableCol,
    },
    {
      dataField: 'fullname',
      text: 'User Name',
      classes: styles.userTableCol,
    },
    {
      dataField: 'email',
      text: 'Email',
      classes: styles.userTableCol,
    },
    {
      dataField: 'locale',
      text: 'Locale',
      classes: styles.userTableCol,
    },
    {
      dataField: 'registrationDate',
      text: 'Application Date',
      formatter: formatRegistrationDate,
      classes: styles.userTableCol,
    }];
  }

  onSearchSubmit(searchTerm: string): void {
    console.log(` search verification users ${searchTerm}`);
  }

  manageVerificationModal(id: string | number): void {
    const userId = id || -1;
    this.setState({ userVerificatinShow: true, selectedUserId: userId });
  }

  manageVerificationModalHide(): void {
    this.setState({ userVerificatinShow: false, selectedUserId: -1 });
  }

  handlePageChange(pageNum: number): void {
    console.log(` ---> handle page change ${pageNum}`);
  }

  render(): React.ReactNode {
    const {
      users,
      selectedUserId,
      paginationBlocked,
      userVerificatinShow,
    } = this.state;

    const rowEvents = {
      onClick: (e, row): void => {
        this.manageVerificationModal(row.id);
      },
    };

    const userListEmpty = (
      <ListResults notice="No results found" level="alert" />
    );

    const userList = (
      <div id="verification-table">
        <BootstrapTable
          keyField="id"
          data={users}
          columns={this.columns}
          BootstrapTable="bootstrap4"
          headerClasses={styles.userTableHeader}
          rowEvents={rowEvents}
          rowClasses={styles.userTableRow}
        />
        <ListPagation
          firstPage={1}
          itemsCount={users.length}
          onClick={this.handlePageChange}
          isBlocked={paginationBlocked}
        />
      </div>
    );

    const showList = users && users.length > 0 ? userList : userListEmpty;
    let userVerificationModal = (<span />);
    if (users && users.length > 0) {
      userVerificationModal = (
        <UserVerificationModal
          show={userVerificatinShow}
          title="ManageVerification"
          userId={selectedUserId}
          onHide={this.manageVerificationModalHide}
        />
      );
    }

    return (
      <Container fluid>
        <UserManagementSearchForm
          onSubmit={this.onSearchSubmit}
        />
        <h3>Verification Applications</h3>
        {showList}
        {userVerificationModal}
      </Container>
    );
  }
}
