import React from 'react';
import ListPagation from 'components/UI/table/listPagination';
import styles from '../../Crm.module.scss';

interface CrmPaginationProps {
    itemsCount: number
    limit: number
    numberOfPages: number
    handleGetUsers: (query: string, offset: number) => void;
}

const CrmPagination: React.FC<CrmPaginationProps> = ({ itemsCount, limit, numberOfPages, handleGetUsers }) => {

    const handlePagination = (page: number) => {
        const offset = (page * limit) - limit;
        handleGetUsers('', offset)
    }

    return (
        <div className={styles.paginationWrap}>
            <ListPagation
                firstPage={1}
                pageCount={numberOfPages}
                onClick={(page) => handlePagination(page)}
                isBlocked={false}
            />
        </div>
    );
}

export default CrmPagination;