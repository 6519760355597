import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import ButtonBack from 'components/UI/buttons/backButton';
import LinkConstans from 'helpers/constants/linkConstants';
import UserService from 'services/user';
import { getParamsFromLocation } from 'helpers/utils/url';
import AlbumManagement from 'components/AlbumManagement';
import MENU from 'helpers/constants/menuConstants';

async function getUserName(userId: string): Promise<any> {
  const userService = new UserService();
  const response = await userService.getUserName(userId);
  const username = response.data || '';
  return username;
}

const AlbumManagementPage: React.FC = () => {
  const [title, setTitle] = useState('Back to user');
  const [backUrl, setBackUrl] = useState('');
  const location = useLocation();
  const urlParams = getParamsFromLocation(location.pathname);
  const userId = urlParams[1];
  const albumId = urlParams[2];

  useEffect(() => {
    const fetchData = async (): Promise<any> => {
      const userName = await getUserName(userId);
      const user = userName ? `: ${userName}` : '';
      setTitle(`Back to user ${user}`);

      if (location !== undefined) {
        const { pathname } = location;
        if (pathname === LinkConstans.REPORT_ALBUMS) {
          setTitle('Back to reported albums');
          setBackUrl(`${MENU.ADMIN_MAIN.CONTENT_ALBUM}`);
        } else {
          setBackUrl(`${MENU.ADMIN_MAIN.USER_MANAGEMENT_PROFILE.split(':')[0]}:${userId}`);
        }
      } else {
        setBackUrl(`${MENU.ADMIN_MAIN.USER_MANAGEMENT_PROFILE.split(':')[0]}:${userId}`);
      }
    };
    fetchData();
  }, [userId, location]);

  return (
    <Container fluid>
      <Row className="mt-4 mb-4">
        <Col>
          <ButtonBack title={title} pathTo={backUrl} />
        </Col>
      </Row>
      <Row>
        <Col>
          <AlbumManagement userId={userId} albumId={albumId} />
        </Col>
      </Row>
    </Container>
  );
};

export default AlbumManagementPage;
