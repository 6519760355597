/* eslint-disable import/prefer-default-export */
export type DangerAreaType = {
    id: string;
    title: string;
    buttonTitle: string;
    buttonType?: string;
    isDisabled?: boolean;
}

export enum DangerButtonIds {
    BlockUser = 'blockUser',
    UnblockUser = 'unblockUser',
    DeleteUser = 'deleteUser',
    DeletePicture = 'deletePicture',
    DeleteCover = 'deleteCover',
    BlockAlbum = 'albumAlbum'
}
