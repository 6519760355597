/* eslint-disable */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import UI_CONTSTANTS from 'helpers/constants/uiConstants';

import { showNotification, ToastLevelType } from 'components/UI/notify';

const { REACT_APP_API_URL } = process.env;

const NON_AUTHERIZATION = 'login';

axios.defaults.baseURL = REACT_APP_API_URL;

export interface APIError {
  Message: string;
  Key: string;
}

export interface Response {
  status: 'OK' | 'failed';
  seqId: string;
}

export interface SuccessfulResponse<T> extends Response {
  status: 'OK';
  data: T;
}

export interface FailedResponse extends Response {
  status: 'failed';
  errors?: Array<APIError>;
}

export function showCommonNotification(message: string, level: ToastLevelType, options?: any): void {
  showNotification({
    message,
    level,
    containerId: UI_CONTSTANTS.NOTIFY_CONTAINER.MAIN_ID,
    toastProperties: options,
  });
}

const statusNotError = (status: string): boolean => {
  const notErrorList = ['ACTIVE', 'BLOCKED'];
  const has = notErrorList.filter((item) => item === status);
  const notError = has && has.length > 0;
  return notError;
};

/**
 * Request error handling function. Executes when axios throws an expection
 * @param error – an error from Axios
 * @param handleErrors – manual error handling function
 */
const handleCatch = (
  error: AxiosError,
  handleErrors: (errors?: APIError[]) => APIError[] = (errors): APIError[] => errors || [],
): FailedResponse => {
  const autoCloseTimeout = 3000;
  if (error.response) {
    const response: FailedResponse = error.response.data || { status: 'failed', seqId: '' };

    if (error.response.status === 404 || error.response.status >= 500) {
      // if internal error, show a generic error
      showCommonNotification(error.response.data.status, UI_CONTSTANTS.NOTIFY_LEVEL.ERROR, { options: { autoClose: autoCloseTimeout } });
    } else if (error.response.status === 401) {
      // if unathorized (token is invalid), remove token and route to /login
      // localStorage.removeItem('accessToken');
    } else if (error.response.status < 500 && error.response.status >= 300) {
      // if some errors, run manual error handler
      if (response.errors && response.errors.length > 0) {
        const errors = handleErrors(response.errors);
        // if any errors are left after handling, show a generic error
        if (errors.length > 0) {
          showCommonNotification(error.response.data.status, UI_CONTSTANTS.NOTIFY_LEVEL.ERROR, { options: { autoClose: autoCloseTimeout } });
        }
      } else {
        // if no errors, show a generic error
        showCommonNotification(error.response.data.status, UI_CONTSTANTS.NOTIFY_LEVEL.ERROR, { options: { autoClose: autoCloseTimeout } });
      }
    }

    return response;
  }

  if (error.request) {
    // if didn't get a response (network issues), show a generic error
    showCommonNotification('Network Generic error', UI_CONTSTANTS.NOTIFY_LEVEL.ERROR, { options: { autoClose: autoCloseTimeout } });
  } else {
    // if didn't make a request (?), show a generic error
    showCommonNotification('Request Generic error', UI_CONTSTANTS.NOTIFY_LEVEL.ERROR, { options: { autoClose: autoCloseTimeout } });
  }

  // returns empty failed response anyways

  return {
    seqId: '',
    status: 'failed',
  } as FailedResponse;
};

/**
 * General request method which fires axios and handles response
 * @param axiosConfig – axios config with url, method, data and other custom parameters
 * @param handleErrors – manual error handling function
 */
const request = <T>(
  axiosConfig: AxiosRequestConfig,
  handleErrors: (errors?: APIError[]) => APIError[] = (errors): APIError[] => errors || [],
): Promise<SuccessfulResponse<T> | FailedResponse> => {
  let token = localStorage.getItem('accessToken') || '';
  token = token.replace(/['"]+/g, '');
  const headers = !axiosConfig.url?.includes(NON_AUTHERIZATION) ? { Authorization: `Basic ${token}` } : {};
  const enableNotify = !axiosConfig.url?.includes(NON_AUTHERIZATION);

  return axios({
    headers,
    withCredentials: true,
    ...axiosConfig,
  }).then((axiosResponse: AxiosResponse) => {
    const response: Response = axiosResponse.data;

    if (response.status === 'OK') {
      return response as SuccessfulResponse<T>;
    }
    if (response.status && !statusNotError(response.status) && enableNotify) {
      if (response.status.toString() !== 'error.storage-limit-exceeded') {
        showCommonNotification(response.status, UI_CONTSTANTS.NOTIFY_LEVEL.ERROR, { options: { autoClose: 2000 } });
      }
    }

    return response as FailedResponse;
  }).catch((error: AxiosError) => handleCatch(error, handleErrors));
};

export const post = <T>(
  url: string,
  data: any = {},
  handleErrors?: (errors?: APIError[]) => APIError[],
): Promise<SuccessfulResponse<T> | FailedResponse> => request<T>({
  url,
  data,
  method: 'post',
}, handleErrors);

export const put = <T>(
  url: string,
  data: any = {},
  handleErrors?: (errors?: APIError[]) => APIError[],
): Promise<SuccessfulResponse<T> | FailedResponse> => request<T>({
  url,
  data,
  method: 'put',
}, handleErrors);

export const remove = <T>(
  url: string,
  data: any = {},
  handleErrors?: (errors?: APIError[]) => APIError[],
): Promise<SuccessfulResponse<T> | FailedResponse> => request<T>({
  url,
  data,
  method: 'delete',
}, handleErrors);

export const get = <T>(
  url: string,
  params: any = {},
  handleErrors?: (errors?: APIError[]) => APIError[],
): Promise<SuccessfulResponse<T> | FailedResponse> => request<T>({
  url,
  params,
  method: 'get',
}, handleErrors);

export const api = {
  post, put, get, remove,
};
