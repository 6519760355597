/* eslint-disable class-methods-use-this */ // @todo get real action
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import React from 'react';
import { Container } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { DataTableColumnType } from 'types/datatable';
import { UserActionHistoryTableType } from 'types/actionHistory';
import { formatDateFromDatetime } from 'helpers/utils/date';
import ActionHistoryService from 'services/actionHistory';
import ActionButton from 'components/UI/buttons/actionButton';
import BlockReasonArea, { ReasonModes } from 'components/UI/management/blockReason';

import notify, { NotifyLevels } from 'services/notify';
import ListPagation from 'components/UI/table/listPagination';
import CommonListResults from 'components/UI/table/Results/commonList';
import styles from './actionHistory.module.scss';
import 'styles/helpers/tables/userListOverride.scss';

type ListProps = {
  title: string;
  userId: string;
}

interface State {
  actions: Array<UserActionHistoryTableType>;
  loadingItems: boolean;
  pageCount: number;
  undoId: string;
  showReasonArea: boolean;
}

export default class ActionHistoryList extends React.Component<ListProps, State> {
  actionHistoryService: ActionHistoryService;

  columns: Array<DataTableColumnType>;

  constructor(props: ListProps) {
    super(props);
    this.state = {
      actions: [],
      loadingItems: false,
      pageCount: 1,
      undoId: '',
      showReasonArea: false,
    };
    this.columns = [];
    this.formatTable();
    this.actionHistoryService = new ActionHistoryService();
    this.undoAction = this.undoAction.bind(this);
    this.onGottenBlockReason = this.onGottenBlockReason.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount(): void {
    this.loadActions();
  }

  componentDidUpdate(prevProps): void {
    const { userId } = this.props;
    if (userId !== prevProps.userId) {
      this.loadActions();
    }
  }

  onGottenBlockReason(reason: string): void {
    this.setState({ showReasonArea: false });
    const { undoId } = this.state;
    if (reason === '') { return; }
    this.undoAction(undoId, reason);
  }

  handlePageChange(pageNum: number): void {
    this.loadActions(pageNum);
  }

  async undoAction(id: string, reason: string): Promise<void> {
    const status = await this.actionHistoryService.undoAction(id, reason);
    if (status === undefined) {
      notify('Undo action applied!', NotifyLevels.SUCCESS);
    }
  }

  triggerUndoActionDialog(id: string): void {
    this.setState({ undoId: id, showReasonArea: true });
  }

  async loadActions(pageNum = 1): Promise<void> {
    const { userId } = this.props;
    this.setState({ loadingItems: true });
    const response = await this.actionHistoryService.getUserActions(userId, pageNum);

    if (response && response.data) {
      const { pagination } = response;
      const pageCount = Math.ceil(pagination.total / pagination.size);
      this.setState({
        actions: response.data,
        pageCount,
      });
    }
    this.setState({ loadingItems: false });
  }

  formatTable(): void {
    const formatActionDate = (cell: string): React.ReactNode => (
      <div>{formatDateFromDatetime(cell, true, true)}</div>
    );
    const formatUndoButton = (cell: string, row): React.ReactNode => (
      <ActionButton
        title="Undo"
        onClick={(): void => this.triggerUndoActionDialog(row.id)}
        customStyle={{ padding: '5px 10px' }}
      />
    );

    this.columns = [{
      dataField: 'adminId',
      text: 'Admin ID',
      classes: styles.userTableCol,
    },
    {
      dataField: 'adminFullname',
      text: 'Admin',
      classes: styles.userTableCol,
    },
    {
      dataField: 'note',
      text: 'Note',
      classes: styles.userTableCol,
    },
    {
      dataField: 'created',
      text: 'Date',
      classes: styles.userTableCol,
      formatter: formatActionDate,
    },
    {
      dataField: 'undo',
      text: '',
      classes: styles.userTableCol,
      formatter: formatUndoButton,
    }];
  }

  render(): React.ReactNode {
    const {
      actions,
      loadingItems,
      showReasonArea,
      pageCount,
    } = this.state;

    const actionListEmpty = (
      <div className={styles.noResults}>
        <CommonListResults loadingItems={loadingItems} loadingTitle="Loading Actions..." noResultsTitle="No actions found" />
      </div>
    );

    const actionList = (
      <div>
        <BootstrapTable
          keyField="id"
          data={actions}
          columns={this.columns}
          BootstrapTable="bootstrap4"
          headerClasses={styles.userTableHeader}
          rowClasses={styles.userTableRow}
        />
        <ListPagation
          firstPage={1}
          pageCount={pageCount}
          onClick={this.handlePageChange}
          isBlocked={false}
        />
        <BlockReasonArea
          page="users_action_history"
          mode={ReasonModes.Single}
          dialogTitle="Undo reason"
          showArea={showReasonArea}
          onBlockReasonAreaUpdate={this.onGottenBlockReason}
        />
      </div>
    );

    const showList = actions && actions.length > 0 ? actionList : actionListEmpty;

    return (
      <Container fluid className={styles.actionHistoryContainer}>
        {showList}
      </Container>
    );
  }
}
