// UI Elements contstants

export default {
  NOTIFY_CONTAINER: {
    MAIN_ID: 'notifyContainer',
  },
  NOTIFY_LEVEL: {
    ERROR: 'error' as const,
    WARN: 'warn' as const,
    INFO: 'info' as const,
    SUCCESS: 'success' as const,
  },
};
