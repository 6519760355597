import React, { ReactNode } from 'react';
import { Container } from 'react-bootstrap';

import LayoutHeader from './header';
import LayoutContent from './content';
import styles from './authLayout.module.scss';

interface ContentProps {
  children: ReactNode;
}

const AuthLayout: React.FC<ContentProps> = ({ children }) => {
  const layoutClass = `justify-content-md-center ${styles.layoutContainer}`;
  return (
    <Container className={layoutClass} fluid>
      <LayoutHeader />
      <LayoutContent>
        {children}
      </LayoutContent>
    </Container>
  );
};

export default AuthLayout;
