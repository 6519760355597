// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import React from 'react';

import Switch from 'components/UI/switch';
import UserService from 'services/user';
import styles from './features.module.scss';

type ListProps = {
  items: FeaturesType;
  userId: string;
  loadUserFeatures: (userId: string) => void;
}

export default class DeviceList extends React.Component<ListProps> {
  async handleChangeFeatureStatus(key: string, userValue: boolean) {
    const userService = new UserService();
    const { userId, loadUserFeatures } = this.props;

    await userService.updateUserFeature(userId, key, !userValue);
    await loadUserFeatures(userId);
  }

  render(): React.ReactNode {
    const { items } = this.props;
    const deviceListEmpty = (<span />);

    const deviceList = (
      <table className={styles.featureTable}>
        <thead>
          <th>Key</th>
          <th className={styles.sm}>User value</th>
          <th>Global value</th>
        </thead>
        <tbody>

          {(items as any[]).map((item) => (
            <tr>
              <td>{item.key}</td>
              <td>
                <Switch
                  action={() => this.handleChangeFeatureStatus(item.key, item.userValue)}
                  mode={item.userValue ? 'active' : 'deactive'}
                />
              </td>
              <td>
                {item.globalValue
                  ? <span className={styles.featureOn}>On</span>
                  : <span className={styles.featureOff}>Off</span>}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );

    const showList = items?.length > 0 ? deviceList : deviceListEmpty;

    return (
      <div className="row">
        {showList}
      </div>
    );
  }
}
