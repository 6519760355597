import React from 'react';
import { Container } from 'react-bootstrap';
import ReportedUsers from 'components/ContentManagement/Users';

const ContentUsersPage = (): React.ReactElement => (
  <Container fluid>
    <ReportedUsers title="Reported users" />
  </Container>
);

export default ContentUsersPage;
