import React, { useEffect, useState } from 'react';

import crmService from 'services/crm';
import { CrmUserList, GetUserParamsType } from 'types/crm';
import { Alert, Button, Container } from 'react-bootstrap';
import CrmUserTable from './CrmUserTable';
import CrmSearch from './Search/index';
import CrmFilter from './Filter';
import CrmPagination from './Pagination';
import { isObjectEmpty } from 'helpers/utils/crm';


export default function CRMUserListComponent() {
  const [rowData, setRowData] = useState<any>('');
  const [itemsCount, setItemsCount] = useState(0);
  const [filter, setFilter] = useState<GetUserParamsType['filters']>({});
  const [isFilterOn, setIsFilterOn] = useState(false)

  const limit = 100;
  const numberOfPages = Math.ceil(itemsCount / limit);

  const checkFilterItems = () => {
    const filterItems = { ...filter };
    if (!isObjectEmpty(filter)) {
      for (let key in filterItems) {
        if (filterItems[key].length === 0) delete filterItems[key]
      }
    }

    return !isObjectEmpty(filterItems) ? filterItems : undefined;
  }

  const handleGetUsers = (query = '', offset = 0): void => {
    const filters = checkFilterItems()
    crmService.getUsers({ limit, query, offset, filters })
      .then((res) => {
        setRowData(res.content)
        setItemsCount(res.total)
      });
  };

  const handleFilter = () => {
    setIsFilterOn(false);
    handleGetUsers();
  }

  useEffect(() => {
    handleGetUsers();
  }, []);

  const validate = (item: any) => {
    if (item) return item;
    return '';
  };

  const rows = rowData ? rowData.map((row: CrmUserList) => ({
    username: validate(row.username),
    id: validate(row.id),
    fullname: validate(row.fullname),
    phoneNumber: validate(row.phone),
    email: validate(row.email),
    category: validate(row.category),
    subCategory: validate(row.subCategory),
    age: validate(row.age),
    gender: validate(row.gender),
    country: validate(row.country),
    job: validate(row.job),
    information: validate(row.information),
    tags: validate(row.tags),
    social: validate(row.socialMedia),
    date: validate(row.creationDate.slice(0, 10)),
  })) : '';

  const handleExportUsers = () => {
    const userIds = rows?.map((item) => item.id).join(',');
    crmService.exportUsers(userIds);
  };

  const handleImportUsers = async (file: FileList | null) => {
    const isFile = file !== null ? file[0] : '';
    if (isFile) {
      await crmService.importUsers(isFile);
      await handleGetUsers();
    }
  };

  return (
    <Container fluid>
      <CrmSearch itemsCount={itemsCount} importAction={handleImportUsers} exportAction={handleExportUsers} searchAction={handleGetUsers} />

      {isFilterOn && <CrmFilter setIsFilterOn={setIsFilterOn} handleFilter={handleFilter} data={filter} setFilter={setFilter} />}

      {rows ? rows.length > 0 ? (
        <CrmUserTable activeFilter={setIsFilterOn} rows={rows} />
      ) : (
        <div>
          <Alert variant={'secondary'}>
            There is no item!
            <Button variant='link' onClick={() => window.location.reload()}>Back</Button>
          </Alert>
        </div>
      ) : (
        <Alert variant={'info'}>
          Please Waite...
        </Alert>
      )}

      {numberOfPages > 1 && (
        <CrmPagination
          itemsCount={itemsCount}
          limit={limit}
          numberOfPages={numberOfPages}
          handleGetUsers={handleGetUsers}
        />
      )}
    </Container>
  );
}
