import React from 'react';
import {
  Container,
} from 'react-bootstrap';
import UserManagementList from 'components/UserManagement/List';

const UserManagementPage: React.FC = () => (
  <Container fluid>
    <UserManagementList title="User List" />
  </Container>
);

export default UserManagementPage;
