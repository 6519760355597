import axios from 'axios';

import { GetUserParamsType } from 'types/crm';
import UI_CONTSTANTS from 'helpers/constants/uiConstants';
import { showCommonNotification } from 'helpers/server/api';
import { CrmService } from '../../types/services/crmService';
import headers from './config';

const crmService: CrmService = {
  getUsers: (params: GetUserParamsType) => (
    axios.post('/v3/users', params, {
      headers: headers(),
    })
      .then((res) => res.data)
  ),

  updateUser: (id: string, data: unknown) => (
    axios.put(`/v1/users/${id}/update`, data, { headers: headers() })
      .then((res) => {
        showCommonNotification('Data is successfully updated', UI_CONTSTANTS.NOTIFY_LEVEL.SUCCESS, { options: { autoClose: 3000 } });
      })
      .catch((ex) => {
        showCommonNotification('Update proccess is failed. try again...', UI_CONTSTANTS.NOTIFY_LEVEL.ERROR, { options: { autoClose: 3000 } });
      })
  ),

  exportUsers: (ids: string) => (
    axios.get(`/v1/users/export?userIds=${ids}`, { headers: headers() })
      .then((res) => {
        window.open(res.data.s3ObjectUrl);
      })
  ),

  importUsers: (file: File) => {
    const data = new FormData();
    data.append('file', file);
    return axios.post('/v1/users/import', data, { headers: headers() })
      .then((res) => {
        showCommonNotification('Data is successfully imported', UI_CONTSTANTS.NOTIFY_LEVEL.SUCCESS, { options: { autoClose: 3000 } });
      })
      .catch((ex) => {
        showCommonNotification('Import proccess is failed. try again...', UI_CONTSTANTS.NOTIFY_LEVEL.ERROR, { options: { autoClose: 3000 } });
      });
  },

  getTags: (query: string = '', offset: number = 0, size: number = 10) => (
    axios.get(`/v1/crm/tags`, {
      headers: headers(),
      params: {
        query,
        offset,
        "limit": size
      }
    }).then(res => res.data)
  ),

  deleteTag: (name: string) => (
    axios.delete('/v1/crm/tags', {
      data: {
        tagNames: [name]
      },
      headers: headers(),
    })
      .then(() => {
        showCommonNotification('Tag is successfully deleted', UI_CONTSTANTS.NOTIFY_LEVEL.SUCCESS, { options: { autoClose: 3000 } });
      })
      .catch(() => {
        showCommonNotification('Tag deletion is failed. try again...', UI_CONTSTANTS.NOTIFY_LEVEL.ERROR, { options: { autoClose: 3000 } });
      })
  )
};

export default crmService;
