export const initUserDetails = {
  id: 'dass23asdddsds',
  appleId: 'dasdasdss',
  googleId: 'asddasdasdasdasds',
  instagram: { id: 'asddass', username: 'Alex' },
  isPasswordSet: true,
  username: 'Alex',
  status: 'Active',
  alertStatus: ['Follow 2k'],
  email: 'herwitt@gmail.com',
  fullname: 'Margarita Rita',
  creationDate: '2020-01-12T22:31:38.651Z',
  verification: 'Applied',
  devices: [

  ],
  isVerified: true,
  storage: {
    plan: 'free',
    usedStorageBytes: 0,
    baseStorageBytes: 0,
    paidStorageBytes: 0,
    extra: {
      storageBytes: 0,
      invitedUsers: 0,
    },
  },
  suggestedUser: { in: true, countries: ['ee'] },
  backupUser: { in: false, countries: ['ee'] },
};

export const initAlbums = [
  {
    id: '1', name: 'Streets', level: 'Public', url: '',
  },
];

export const initUserProfile = {
  id: '1',
  username: 'User Name',
  fullname: 'Alex',
  website: '',
  bio: '',
  profilePhoto: {
    id: '1',
    url: 'url',
  },
  socialRelation: '',
  photoCount: 0,
  followersCount: 0,
  followingCount: 0,
  isVerified: true,
};
