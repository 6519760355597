import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import crmService from 'services/crm';
import { categoriesData, gendersData, subCategoriesData } from 'statics/CRM/data';
import countries from '../countries';
import SocialMedia from '../SocialMedia/';

import styles from './RowEditor.module.scss';
import TagsBox from './TagsBox';


interface RowEditorProps {
    row: any;
    setModalActive: (i: boolean) => void
}

const RowEditor: React.FC<RowEditorProps> = ({ row, setModalActive }) => {
    const [category, setCategory] = useState(row.category || '')
    const [subCategory, setSubCategory] = useState(row.subCategory || '')
    const [age, setAge] = useState(row.age || '')
    const [gender, setGender] = useState(row.gender || '')
    const [country, setCountry] = useState(row.country || '')
    const [job, setJob] = useState(row.job || '')
    const [information, setInformation] = useState(row.information || '')
    const [tags, setTags] = useState(row.tags || '')
    const [socials, setSocials] = React.useState<Array<string>>(row.social)
    const [isDataUpdated, setIsDataUpdated] = useState(false)

    const updateInfo = () => {
        const data = { category, subCategory, age, gender, country, job, information, tags, socialMedia: socials }
        for (let key in data) {
            if (typeof data[key] === 'string' && !data[key]) delete data[key]
        }

        crmService.updateUser(row.id, data)
        setIsDataUpdated(true)
    }

    const handleCloseModal = () => {
        if(isDataUpdated) {
            window.location.reload()
        } else {
            setModalActive(false)
            document.body.style.overflow = 'auto';
        }
    }
    
    useEffect(() => {
        document.body.style.overflow = 'hidden';
    }, [])

    return (
        <div className={styles.rowEditor}>
            <div className={styles.rowEditorBox}>
                <Row className="mb-5">
                    <Col lg={4}>
                        <label htmlFor="">Category</label>
                        <select value={category} onChange={(e) => setCategory(e.target.value)}>
                            <option value="">Select...</option>
                            {categoriesData.map(item => <option key={item}>{item}</option>)}
                        </select>
                    </Col>
                    <Col lg={4}>
                        <label htmlFor="">Sub category</label>
                        <select value={subCategory} onChange={(e) => setSubCategory(e.target.value)}>
                            <option value="">Select...</option>
                            {subCategoriesData.map(item => <option key={item}>{item}</option>)}
                        </select>
                    </Col>
                    <Col md={4}>
                        <label htmlFor="">Age</label>
                        <input onChange={(e) => setAge(e.target.value)} value={age} type="number" />
                    </Col>
                    <Col md={4}>
                        <label htmlFor="">Gender</label>
                        <select onChange={(e) => setGender(e.target.value)} value={gender}>
                            <option value="">Select...</option>
                            {gendersData.map(item => <option key={item}>{item}</option>)}
                        </select>
                    </Col>
                    <Col md={4}>
                        <label htmlFor="">Country</label>
                        <select onChange={(e) => setCountry(e.target.value)} value={country} name="" id="">
                            <option value="">Select...</option>
                            {countries.map(country => <option key={country.code}>{country.name}</option>)}
                        </select>
                    </Col>
                    <Col md={4}>
                        <label htmlFor="">Job</label>
                        <input onChange={(e) => setJob(e.target.value)} value={job} type="text" />
                    </Col>
                    <Col md={12}>
                        <label htmlFor="">Information</label>
                        <textarea onChange={(e) => setInformation(e.target.value)}>{information}</textarea>
                    </Col>
                    <Col md={12}>
                        <TagsBox label={'Tags'} defaultTags={tags} updateSelectedTags={setTags} />
                    </Col>
                    <Col md={12}>
                        <SocialMedia data={socials} setData={setSocials} />
                    </Col>
                </Row>
                <Button className="mr-3" onClick={() => updateInfo()}>Save</Button>
                <Button variant='secondary' onClick={() => handleCloseModal()}>Close</Button>
            </div>
        </div>
    );
}

export default RowEditor;