/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { MouseEvent, useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import { PictureItem } from 'types/content-item';
import IMAGE_RESOLUTIONS from 'helpers/constants/imageResolutions';

import selectImage from 'assets/content/select.svg';
import selectedImage from 'assets/content/selected.svg';
import styles from './contentMediaItem.module.scss';
import 'styles/helpers/buttons/outlineOverride.scss';

type ContentItemProps = {
  allSelected: boolean;
  picture: PictureItem;
  onItemEnter: (picture: PictureItem) => void;
  onItemSelect: (picture: PictureItem) => void;
  onItemUnselect: (picture: PictureItem) => void;
  allDesellected: boolean;
  onBlockItem: (picture: PictureItem) => void;
}

const ContentMediaItem: React.FC<ContentItemProps> = ({
  picture, allSelected, onItemEnter, onItemSelect, onItemUnselect, allDesellected, onBlockItem,
}) => {
  const [isShowSelection, setSelectionVisibility] = useState(false);
  const [isUnselectedVisible, setUnselectedVisibility] = useState(false);
  const isOpenable = picture.pictureSource !== '-1';

  useEffect(() => {
    if (allDesellected) {
      setSelectionVisibility(false);
    }
  }, [allDesellected]);

  const handleItemSelection = (e: MouseEvent, enterFullView: boolean): void => {
    e.preventDefault();
    if (!isOpenable) { return; }
    if (enterFullView) {
      onItemEnter(picture);
      return;
    }
    onItemSelect(picture);
    if (isShowSelection) {
      onItemUnselect(picture);
    }
    setSelectionVisibility(!isShowSelection);
  };

  const handleDeleteItem = (e: MouseEvent): void => {
    e.preventDefault();
    onBlockItem(picture);
    setSelectionVisibility(false);
  };

  const handleMouseHover = (): void => {
    if (!isOpenable) { return; }
    setUnselectedVisibility(true);
  };

  const handleMouseLeave = (): void => {
    setUnselectedVisibility(false);
  };

  const DeleteItemSection = (
    <Col className={styles.contentDeleteCol}>
      <Button
        className={styles.contentDeleteItem}
        onClick={(e): void => {
          handleDeleteItem(e);
          handleItemSelection(e, false);
        }}
      >
        Block
      </Button>
    </Col>
  );

  const UnSelected = (): React.ReactElement => (
    <Row className={styles.contentItemSelectedRow}>
      <Col className={styles.contentCheckCol}>
        <Button
          variant="outline-light"
          className={styles.contentDeleteItem}
          onClick={(e): void => { handleItemSelection(e, false); }}
        >
          <img alt="Unselected" src={selectImage} />
        </Button>
      </Col>
      {DeleteItemSection}
    </Row>
  );

  const Selected = (): React.ReactElement => (
    <Row className={styles.contentItemSelectedRow}>
      <Col className={styles.contentCheckCol}>
        <Button
          variant="outline-light"
          className={styles.contentDeleteItem}
          onClick={(e): void => { handleItemSelection(e, false); }}
        >
          <img alt="Selected" src={selectedImage} />
        </Button>
      </Col>
      {DeleteItemSection}
    </Row>
  );

  let showSelection = isUnselectedVisible ? UnSelected() : (<span />);
  let itemClass = styles.contentItem;
  const itemBackground = picture.pictureSource === 'none' ? { background: 'grey' } : { backgroundImage: `url(${picture.pictureSource}${IMAGE_RESOLUTIONS.IMAGE_MEDIUM})` };

  if (isUnselectedVisible) {
    itemClass = styles.contentItemUnselected;
  }

  if (isShowSelection || allSelected) {
    showSelection = Selected();
    itemClass = styles.contentItemSelected;
  }

  return (
    <div
      className={itemClass}
      style={itemBackground}
      onMouseEnter={handleMouseHover}
      onMouseLeave={handleMouseLeave}
    >
      <div
        role="button"
        tabIndex={0}
        className={styles.contentOpenArea}
        onClick={(e: MouseEvent): void => handleItemSelection(e, isOpenable)}
      />
      <div className={styles.contentHeader}>
        {showSelection}
      </div>
    </div>
  );
};
export default ContentMediaItem;
