import React, { MouseEvent, useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';

import { ReportAlbumType } from 'types/report';
import capitalizeFirstChar from 'helpers/utils/string';
import IMAGE_RESOLUTIONS from 'helpers/constants/imageResolutions';
import GeneralSettings from 'helpers/constants/generalSettings';

import ActionButton from 'components/UI/buttons/actionButton';
import DangerButton from 'components/UI/buttons/dangerButton';

import styles from './contentAlbumItem.module.scss';

type ContentItemProps = {
  albumData: ReportAlbumType;
  onAlbumItemEnter: (albumData: ReportAlbumType) => void;
  onApproveItem: (albumData: ReportAlbumType) => void;
  onBlockItem: (albumData: ReportAlbumType) => void;
}

const ContentAlbumItem: React.FC<ContentItemProps> = ({
  albumData, onAlbumItemEnter, onApproveItem, onBlockItem,
}) => {
  const handleAlbumEnter = (e: MouseEvent): void => {
    e.preventDefault();
    onAlbumItemEnter(albumData);
  };
  const [isHeaderVis, setHeaderVis] = useState(false);
  const bgStyle = albumData.url ? { backgroundImage: `url(${albumData.url}${IMAGE_RESOLUTIONS.IMAGE_MEDIUM})` } : {};
  const userEmptyAvatar = (<span><FaUserCircle /></span>);
  const userAvatarImg = albumData.userAvatar ? { backgroundImage: `url(${albumData.userAvatar}${IMAGE_RESOLUTIONS.IMAGE_XS})` } : {};
  const userAvatar = (<div style={userAvatarImg} className={styles.contentItemAvatar}> </div>);
  const showAvatar = albumData.userAvatar ? userAvatar : userEmptyAvatar;

  const onApprove = (e: MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    onApproveItem(albumData);
  };
  const onBlock = (e: MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    onBlockItem(albumData);
  };

  const handleMouseHover = (): void => {
    setHeaderVis(!isHeaderVis);
  };

  const controls = albumData && albumData.id !== '-1' ? (
    <div className={styles.contentControls}>
      <div>
        <ActionButton
          title="Approve"
          onClick={(e: MouseEvent): void => { onApprove(e); }}
          isTextBold
          customStyle={GeneralSettings.ALBUM_CONTENT.APPROVE_BUTTON}
        />
      </div>
      <div>
        <DangerButton
          title="Block"
          onClick={(e: MouseEvent): void => { onBlock(e); }}
          isTextBold
          customStyle={GeneralSettings.ALBUM_CONTENT.BLOCK_BUTTON}
        />
      </div>
      <div
        className={styles.enterArea}
        role="button"
        tabIndex={0}
        onClick={(e: MouseEvent): void => handleAlbumEnter(e)}
      >
        &nbsp;
      </div>
    </div>
  ) : null;

  const Header = !isHeaderVis ? (
    <div className={styles.contentHeader}>
      {showAvatar}
      <span>{albumData.userName}</span>
    </div>
  ) : null;

  return (
    <div
      className={styles.contentItem}
      onMouseEnter={handleMouseHover}
      onMouseLeave={handleMouseHover}
      style={bgStyle}
    >
      {controls}
      {Header}
      <div className={styles.contentFooter}>
        <div className={styles.albumName}>{albumData.name}</div>
        <div>{capitalizeFirstChar(albumData.level)}</div>
      </div>
    </div>
  );
};
export default ContentAlbumItem;
