import React, { useState, useEffect } from 'react';

import ReasonDialog from 'components/UI/dialogs/reasonDialog';

export enum ReasonModes {
  Single = 'single',
  Multiple = 'multiple'
}

interface BlockReasonProps {
  page: string;
  dialogTitle: string;
  showArea: boolean;
  mode: string;
  onBlockReasonAreaUpdate: (reasonText: string, mode: string) => void;
}

const BlockReasonArea: React.FC<BlockReasonProps> = ({
  showArea, mode = 'single', dialogTitle = 'Block item', onBlockReasonAreaUpdate,
}) => {
  const [showReason, setShowReason] = useState(false);
  const [reasonTitle, setReasonTitle] = useState('');

  useEffect(() => {
    if (showArea) {
      setReasonTitle(dialogTitle);
      setShowReason(true);
    }
    setShowReason(showArea);
  }, [showArea, dialogTitle]);

  const handleReasonConfirm = async (reasonText: string): Promise<any> => {
    setShowReason(false);
    onBlockReasonAreaUpdate(reasonText, mode);
  };

  const handleReasonClose = (): void => {
    setShowReason(false);
    onBlockReasonAreaUpdate('', mode);
  };

  return (
    <>
      <ReasonDialog
        title={reasonTitle}
        show={showReason}
        onHide={handleReasonClose}
        onConfirm={handleReasonConfirm}
      />
    </>
  );
};

export default BlockReasonArea;
