import React, { CSSProperties } from 'react';
import { Button } from 'react-bootstrap';
import styles from './cancelButton.module.scss';

interface CancelButtonProps {
  title: string;
  onClick: Function;
  disableOutlineHover?: boolean;
  isTextBold?: boolean;
  isSmall?: boolean;
  customStyle?: CSSProperties | undefined;
}

const CancelButton: React.FC<CancelButtonProps> = ({
  title, onClick, disableOutlineHover = false, isTextBold = false, isSmall = false, customStyle = {},
}) => {
  const boldStyle = isTextBold ? styles.mainBold : '';
  const smallStyle = isSmall ? styles.mainSmall : '';
  const outlineNone = disableOutlineHover ? styles.outlineHoverNone : '';
  const styleMain = `${styles.main} ${outlineNone} ${boldStyle} ${smallStyle}`;

  return (
    <Button
      variant="outline-dark"
      className={styleMain}
      style={customStyle}
      onClick={(e: React.MouseEvent): void => { onClick(e); }}
    >
      <span>{title}</span>
    </Button>
  );
};

export default CancelButton;
