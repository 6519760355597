import React from 'react';
import { NavLink } from 'react-router-dom';
import MENU from 'helpers/constants/menuConstants';
import styles from '../submenu.module.scss';

const ReportSubMenu = (
  <ul className={styles.subnav}>
    <li className={styles.navItem}>
      <NavLink
        activeClassName={styles.navLinkActive}
        className={styles.navLink}
        to={MENU.ADMIN_MAIN.CONTENT_USERS}
      >
        Users
      </NavLink>
    </li>
    <li className={styles.navItem}>
      <NavLink
        activeClassName={styles.navLinkActive}
        className={styles.navLink}
        to={MENU.ADMIN_MAIN.CONTENT_ALBUM}
      >
        Albums
      </NavLink>
    </li>
    <li className={styles.navItem}>
      <NavLink
        activeClassName={styles.navLinkActive}
        className={styles.navLink}
        to={MENU.ADMIN_MAIN.CONTENT_MEDIA}
      >
        Media
      </NavLink>
    </li>
  </ul>
);

export default ReportSubMenu;
