import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import CheckboxWithInput from 'components/UI/checkbox/checkboxWithInput';
import MultiSelect from 'components/UI/selects/multiSelect';
import { WidthConf } from 'types/layout';
import { SelectOptionType } from 'types/form/select';
import GeneralSettings from 'helpers/constants/generalSettings';
import ActionButton from 'components/UI/buttons/actionButton';
import styles from './mngUserMultiSelect.module.scss';

type CheckboxType = {
    controlId: string;
    title: string;
    disabled: boolean;
    checked: boolean;
    onChange?: Function;
}

type SelectType = {
    options: Array<SelectOptionType>;
    placeholder?: string;
    onChange: (event: Array<SelectOptionType>) => void;
}

interface MngUserMultiSelectProps {
    rowClass?: string;
    checkbox: CheckboxType;
    select: SelectType;
    values: SelectOptionType[];
    width?: WidthType;
    isApplyButton?: boolean;
    applyTitle?: string;
    applyButtonClick?: Function;
}

type WidthType = {
    checkbox: WidthConf;
    select: WidthConf;
}

const MngUserMultiSelectRow: React.FC<MngUserMultiSelectProps> = ({
  rowClass = '',
  checkbox,
  select,
  values,
  isApplyButton = false,
  applyTitle = 'Apply',
  applyButtonClick = (): void => {},
  width = { checkbox: { xs: 5, md: 5, lg: 5 }, select: { xs: 7, md: 7, lg: 7 } },
}) => {
  const [isChecked, setChecked] = useState(false);
  const [isApplyActive, setApplyActive] = useState(false);
  useEffect(() => {
    setChecked(checkbox.checked);
  }, [checkbox.checked]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setChecked(e.target.checked);
    if (checkbox.onChange) {
      checkbox.onChange(e.target.checked);
    }
    setApplyActive(true);
  };

  const btnSizeSm = GeneralSettings.USER_MANAGEMENT.DETAILS.BUTTON_SM_SIZE;

  const applyButton = (
    <ActionButton
      title={applyTitle}
      isApplyButton
      active={isApplyActive}
      onClick={applyButtonClick}
      customStyle={{ padding: btnSizeSm.padding, fontSize: btnSizeSm.fontSize }}
    />
  );
  const showApply = isApplyButton ? applyButton : null;

  return (
    <Row className={rowClass}>
      <Col xs={width.checkbox.xs} md={width.checkbox.md} lg={width.checkbox.lg} className={styles.checkboxCol}>
        <CheckboxWithInput
          controlId={checkbox.controlId}
          checked={checkbox.checked}
          onClick={handleCheckboxChange}
          placeholder={checkbox.title}
          disabled={checkbox.disabled}
        />
      </Col>
      <Col
        className={styles.selectCol}
        xs={width.select.xs}
        md={width.select.md}
        lg={width.select.lg}
      >
        <Row>
          <Col md="6" lg="10" xl="10">
            <MultiSelect
              options={select.options}
              placeholder={select.placeholder}
              onChange={(event): void => { select.onChange(event); setApplyActive(true); }}
              disabled={!isChecked}
              value={values}
            />
          </Col>
          <Col
            className={styles.applyButtonCol}
            md="6"
            lg="2"
            xl="2"
          >
            {showApply}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MngUserMultiSelectRow;
