/* eslint-disable import/prefer-default-export */
export const initPictureData = {
  id: 'dasdasdas',
  name: 'Picture name 1',
  userId: '1',
  albumId: 'aasdsasds',
  username: 'User Name',
  albumName: 'Album Name',
  uploadDate: '2019-15-05T15:00:00Z',
  image: {
    id: 'asddasdas',
    url: 'assets/placeholder/defaultPicture.jpg',
  },
};
