/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-array-constructor */ // all needed
import React, { ChangeEvent, useState, useEffect } from 'react';
import { Row, Col, Pagination } from 'react-bootstrap';
import './paginationOverride.scss';

interface PaginationProps {
  firstPage?: number;
  perPage?: number;
  pageCount?: number;
  itemsCount?: number;
  maxVisiblePages?: number;
  onClick: (pageNumber: number) => void;
  isBlocked: boolean;
}

const ListPagination: React.FC<PaginationProps> = ({
  firstPage = 1, pageCount = 0, perPage = 1, itemsCount = 1, maxVisiblePages = 6, onClick, isBlocked,
}) => {
  const [currentPage, setPage] = useState(firstPage);
  const [pageItems, setVisiblePageSet] = useState(Array<React.ReactElement>());
  const [leftSideActive, setLeftSide] = useState(true);
  const [rightSideActive, setRightSide] = useState(true);

  const getVisibleSide = (): number => Math.floor(maxVisiblePages / 2);
  const lastPage = pageCount !== 0 ? pageCount : Math.ceil(itemsCount / perPage);
  const side = getVisibleSide();

  const convertPage = (e: ChangeEvent): number => {
    e.persist();
    const p = e.target.textContent !== null ? e.target.textContent.toString() : '0';
    return parseInt(p, 10);
  };

  const displayPages = (onChangeCb: Function, firstIndex: number, lastIndex: number, forceCurrentPage?: number): void => {
    const curPage = forceCurrentPage || currentPage;
    const items = Array<React.ReactElement>();
    for (let number = firstIndex; number <= lastIndex; number += 1) {
      items.push(
        <a key={number} href="#" onClick={(e: React.MouseEvent): void => { onChangeCb(e); }}>
          <Pagination.Item key={number} active={number === curPage}>
            {number}
          </Pagination.Item>
        </a>,
      );
    }
    setVisiblePageSet(items);
  };

  const disableSides = (curPage: number): void => {
    // setRightSide(true);
    // setLeftSide(true);
    // if (curPage <= firstPage) {
    //   setLeftSide(false);
    // }
    // if (curPage >= lastPage) {
    //   setRightSide(false);
    // }
  };

  const getPageVisibilityIndexes = (curPage: number): number[] => {
    const leftSide = curPage - side;
    const rightSide = curPage + side;
    const firstIndex = leftSide > firstPage ? leftSide : firstPage;
    const lastIndex = rightSide <= lastPage ? rightSide : lastPage;
    return [firstIndex, lastIndex];
  };

  const onPageChange = (e: ChangeEvent): void => {
    if (isBlocked) { return; }
    const pageNum = convertPage(e);
    setPage(pageNum);
    onClick(pageNum);
    disableSides(pageNum);
    const indexes = getPageVisibilityIndexes(pageNum);
    displayPages(onPageChange, indexes[0], indexes[1], pageNum);
  };

  const setVisiblePages = (forceCurNum = -1): void => {
    const curPage = forceCurNum !== -1 ? forceCurNum : currentPage;
    const indexes = getPageVisibilityIndexes(curPage);
    disableSides(curPage);
    displayPages(onPageChange, indexes[0], indexes[1], curPage);
  };

  const onPageFirst = (): void => {
    if (isBlocked || lastPage < side) { return; }
    setPage(firstPage);
    displayPages(onPageChange, firstPage, firstPage + side, firstPage);
    onClick(1);
    setLeftSide(false)
    setRightSide(true)
  };
  const onPageLast = (): void => {
    setPage(lastPage);
    setVisiblePages();
    displayPages(onPageChange, lastPage - side, lastPage, lastPage);
    onClick(lastPage);
    setLeftSide(true)
    setRightSide(false)
  };

  const onPagePrev = (): void => {
    if (isBlocked) { return; }
    if (currentPage > firstPage) {
      const index = currentPage - 1;
      setPage(index);
      setVisiblePages(index);
      onClick(index);
    }
  };

  const onPageNext = (): void => {
    if (isBlocked) { return; }
    if (currentPage < lastPage) {
      const index = currentPage + 1;
      setPage(index);
      setVisiblePages(index);
      onClick(index);
    }
  };

  // on init, when per per page update
  useEffect(() => {
    setVisiblePages();
  }, [pageCount, perPage]);

  const paginationClass = isBlocked ? 'paginationBlocked' : '';

  const PaginationSection = (
    <Pagination className={paginationClass}>
      <Pagination.First onClick={onPageFirst} disabled={!leftSideActive} />
      <Pagination.Prev onClick={onPagePrev} disabled={!leftSideActive} />
      {pageItems}
      <Pagination.Next onClick={onPageNext} disabled={!rightSideActive} />
      <Pagination.Last onClick={onPageLast} disabled={!rightSideActive} />
    </Pagination>
  );
  const PaginationContainer = (
    <Row className="paginationContainer">
      <Col className="d-flex justify-content-center">
        {PaginationSection}
      </Col>
    </Row>
  );

  return (
    <>{PaginationContainer}</>
  );
};

export default ListPagination;
