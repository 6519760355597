/* eslint-disable class-methods-use-this */
/* eslint-disable dot-notation */
// User service (get users, user, profile, block, delete)
import { AlbumsType } from 'types/album';
import { ImageItemType, PictureItem } from 'types/content-item';
import { ReportAlbumType } from 'types/report';
import {
  UserListType,
  UserListFailedType,
  AlbumListType,
  AlbumListFailed,
  ImageItemSuccessType,
  ImageItemFailedType,
} from 'types/services/report';

import API_PATH from 'helpers/constants/apiPaths';
import { api } from 'helpers/server/api';
import { checkObjectHasKeys } from 'helpers/utils/arrays';

export default class ReportedService {
  mainPath: string;

  constructor() {
    this.mainPath = API_PATH.REPORT.MAIN;
  }

  async getUsers(pageNum = 1): Promise<UserListType | UserListFailedType> {
    // in backend page is counting from 0, but in front-end it's first page = 1
    const response = await api.get(`/v1/${this.mainPath}/${API_PATH.REPORT.USERS}`, { page: pageNum - 1 });
    if (response && response['content']) {
      const pageAble = response['pageable'] || null;
      const totalUsers = response['total'];
      const pageCount = Math.ceil(totalUsers / pageAble['size']);
      const currentPage = pageAble['page'] ? pageAble['page'] + 1 : 1;
      return {
        data: response['content'],
        pagination: {
          pageCount,
          currentPage,
        },
      };
    }
    return { data: undefined, pagination: undefined, status: response.status };
  }

  async getUserAlbums(pageOffset = 1): Promise<AlbumListType | AlbumListFailed> {
    const path = `/v1/${this.mainPath}/${API_PATH.REPORT.ALBUMS}`;
    // in components pageOffset starts from 1, backend request from 0
    const response = await api.get(path, { page: pageOffset - 1 });
    if (response && response['content']) {
      const pageAble = response['pageable'] || null;
      const currentPage = pageAble ? pageAble['page'] + 1 : 1;
      return {
        data: response['content'],
        pagination: {
          total: response['total'],
          offset: currentPage,
          size: pageAble !== null ? pageAble['size'] : 20,
        },
      };
    }
    return { data: undefined, pagination: undefined, status: response.status };
  }

  getFormattedAlbums(data: AlbumsType[]): ReportAlbumType[] {
    const albumsArr = data.map((item) => {
      if (Object.keys(item).length > 2) {
        const user = item['albumOwner'] || { username: '', fullname: '' };
        const userAvatar = user.profilePhoto ? user.profilePhoto['url'] : '';
        const url = item.coverPhoto ? item.coverPhoto.url : '';
        return {
          id: item.id,
          name: item.name,
          userId: item.userId,
          userName: user.username,
          fullname: user.fullname,
          userAvatar,
          level: item.privacyLevel,
          url,
          isHidden: item.isHidden,
          isOpenable: item.isOpenable,
        };
      }
      return {
        id: '-1',
        name: 'Broken Album',
        userId: '-1',
        userName: 'None',
        level: 'Public',
        url: '',
        isHidden: item.isHidden,
        isOpenable: item.isOpenable,
        fullname: 'None',
        userAvatar: 'none',
      };
    });
    return albumsArr;
  }

  async getImageItems(pageOffset = 1): Promise<ImageItemSuccessType | ImageItemFailedType> {
    const path = `/v1/${this.mainPath}/${API_PATH.REPORT.IMAGE_ITEMS}`;
    // in components pageOffset starts from 1, backend request from 0
    const response = await api.get(path, { page: (pageOffset - 1) });
    if (response && response['content']) {
      const pageAble = response['pageable'] || null;
      const currentPage = pageAble ? pageAble['page'] + 1 : 1;
      const totalItems = response['total'];
      const pageCount = Math.ceil(totalItems / pageAble['size']);
      return {
        data: this.getFormattedImageItems(response['content']),
        pagination: {
          pageCount,
          currentPage,
        },
      };
    }
    return { data: undefined, pagination: undefined, status: response.status };
  }

  getFormattedImageItems(items: ImageItemType[]): PictureItem[] {
    const formattedList = items.map((item) => {
      if (!checkObjectHasKeys(item)) {
        return {
          pictureId: '-1',
          pictureSource: '-1',
          albumId: '-1',
          userId: '-1',
        };
      }
      const source = item.thumb && item.thumb.url !== undefined ? item.thumb.url : item.object.url;
      return {
        pictureId: item.id,
        pictureSource: source,
        albumId: item.albumId,
        userId: item.userId,
      };
    });
    return formattedList;
  }

  unFormatImageItems(imageItems: PictureItem[]): string[] {
    return imageItems.map((item) => item.pictureId);
  }
}
