// Validator to check input fields
import { validateEmail, validateURL, validatePhone } from '../validators';

type inputType = string | number;

export enum ValidationTypes {
    Email = 'email',
    Phone = 'phone',
    URL = 'url'
}

// simple validator for common use
function validate(input: inputType, fieldType: ValidationTypes): string[] {
  let errors: Array<string> = [];
  switch (fieldType) {
    case ValidationTypes.Phone:
      if (!validatePhone(input.toString())) {
        errors.push('Field is not valid Phone number');
      }
      break;
    case ValidationTypes.URL:
      if (!validateURL(input.toString())) {
        errors.push('Field is not valid URL');
      }
      break;
    case ValidationTypes.Email:
      if (!validateEmail(input.toString())) {
        errors.push('Field is not valid E-mail');
      }
      break;
    default:
      errors = [];
      break;
  }
  return errors;
}

export default validate;
