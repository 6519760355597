import { ToastOptions } from 'react-toastify';

import UI_CONTSTANTS from 'helpers/constants/uiConstants';

import { ToastLevelType, showNotification } from 'components/UI/notify';

export const NotifyLevels = UI_CONTSTANTS.NOTIFY_LEVEL;

export default (
  error: string,
  level: ToastLevelType = 'success',
  toastOptions: ToastOptions = { autoClose: 2000 },
): void => {
  const notifyLevel = level || UI_CONTSTANTS.NOTIFY_LEVEL.SUCCESS;
  showNotification({
    message: error,
    level: notifyLevel,
    containerId: UI_CONTSTANTS.NOTIFY_CONTAINER.MAIN_ID,
    toastProperties: toastOptions,
  });
};
