/* eslint-disable class-methods-use-this */
import React from 'react';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import ManagementDetailsTitle from 'components/UI/management/details/detailsTitle';
import ManagementDetailsRow from 'components/UI/management/details/detailsRow';
import UserDetails from 'types/user/userDetails';
import { initUserDetails } from 'models/user/profile';
import DangerArea from 'components/UI/management/dangerArea';
import DangerButton from 'components/UI/buttons/dangerButton';
import { formatDateFromDatetime } from 'helpers/utils/date';
import UserVerificationModal from 'components/UserManagement/VerificationList/ManageVerification';
import formatAlertStatus from 'components/UserManagement/AlertStatus';
import DeviceList from 'components/UserManagement/DeviceList';
import GeneralSettings from 'helpers/constants/generalSettings';

import notify from 'services/notify';
import UserService from 'services/user';
import validate, { ValidationTypes } from 'helpers/utils/liteValidator';

import { DangerAreaType, DangerButtonIds } from 'types/form/dangerArea';
import ActionButton from 'components/UI/buttons/actionButton';
import UserSettingsForm from 'components/UserManagement/UserSettings';
import styles from './albumDetails.module.scss';
import StorageUnit from './enums';
import StorageModal from './StorageModal';
import Features from '../../Features/index';

enum ChangeEmailModes {
  Apply = 'apply',
  Edit = 'edit'
}

type ProfileProps = {
  userId: string;
  profileImage: unknown;
}

type ProfileDetailsState = {
  userDetails: UserDetails;
  userFeatures: FeaturesType;
  dangerButtons: Array<DangerAreaType>;
  userVerificatinShow: boolean;
  email: string;
  changeEmailMode: string;
  emailValid: boolean;
  isStorageModalOpen: boolean;
}

const initDangerAreaButtons = [
  { id: DangerButtonIds.BlockUser, title: '', buttonTitle: 'Block user' },
  { id: DangerButtonIds.DeleteUser, title: 'Delete user', buttonTitle: 'Delete user' },
  {
    id: DangerButtonIds.DeletePicture, title: '', buttonTitle: 'Unset profile picture', isDisbaled: true,
  }, // @todo unremove when logic determed
];

const initDangerAreaButtonsUnblock = [
  {
    id: DangerButtonIds.UnblockUser, title: 'Unblock user', buttonType: 'enable', buttonTitle: 'Unblock user',
  },
  { id: DangerButtonIds.DeleteUser, title: '', buttonTitle: 'Delete user' },
  {
    id: DangerButtonIds.DeletePicture, title: '', buttonTitle: 'Unset profile picture', isDisabled: true,
  },
];

export default class UserProfileDetails extends React.Component<ProfileProps, ProfileDetailsState> {
  userService: UserService;

  constructor(props: ProfileProps) {
    super(props);
    this.state = {
      userDetails: initUserDetails,
      userFeatures: [],
      dangerButtons: initDangerAreaButtons,
      userVerificatinShow: false,
      email: '',
      changeEmailMode: ChangeEmailModes.Apply,
      emailValid: true,
      isStorageModalOpen: false,
    };
    this.userService = new UserService();
    this.handleUnverification = this.handleUnverification.bind(this);
    this.handleVerification = this.handleVerification.bind(this);
    this.manageVerificationModalHide = this.manageVerificationModalHide.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleEmailSubmit = this.handleEmailSubmit.bind(this);
    this.handleEmailEdit = this.handleEmailEdit.bind(this);
    this.handleResetFunc = this.handleResetFunc.bind(this);
    this.handleDangerAreaUpdate = this.handleDangerAreaUpdate.bind(this);
    this.loadUserFeatures = this.loadUserFeatures.bind(this);
  }

  componentDidMount(): void {
    const { userId } = this.props;
    this.loadUserDetails();
    this.loadUserFeatures(userId);
  }

  componentDidUpdate(prevProps): void {
    const { userId } = this.props;
    if (userId !== prevProps.userId) {
      this.loadUserDetails();
      this.loadUserFeatures(userId);
    }
  }

  getEmailCol(): React.ReactElement {
    const {
      email,
      changeEmailMode,
      emailValid,
    } = this.state;
    const emailApply = (
      <ActionButton
        title="Apply"
        onClick={this.handleEmailSubmit}
        isSmall
        disabled={!emailValid}
        customStyle={GeneralSettings.USER_MANAGEMENT.DETAILS.EMAIL_BUTTON}
      />
    );
    // @todo for now we disable non intergrated functionality
    const emailEdit = (
      <ActionButton
        title="Edit"
        onClick={this.handleEmailEdit}
        isSmall
        disabled
        customStyle={GeneralSettings.USER_MANAGEMENT.DETAILS.EMAIL_BUTTON}
      />
    );
    const emailColButtons = changeEmailMode === ChangeEmailModes.Apply ? emailEdit : emailApply;
    const emailPadLt = changeEmailMode === ChangeEmailModes.Apply ? '' : '10px';
    const emailCol = (
      <Row style={{ margin: '0' }}>
        <Col className={styles.emailCol} xs={6} md={6} lg={8} xl={10}>
          <input
            type="text"
            placeholder="E-mail"
            disabled={changeEmailMode === ChangeEmailModes.Apply}
            value={email}
            style={{ paddingLeft: emailPadLt, width: '100%' }}
            onChange={this.handleEmailChange}
          />
        </Col>
        <Col className={styles.emailBtn} xs={6} md={6} lg={4} xl={2}>
          {emailColButtons}
        </Col>
      </Row>
    );
    return emailCol;
  }

  async handleVerification(): Promise<any> {
    // @todo for future times, we should show modal, but right now just verify/unverify commands
    // this.setState({ userVerificatinShow: true });
    const { userId } = this.props;
    const status = await this.userService.verifyUser(userId);
    this.handleUpdate(status, 'Verification applied!');
  }

  async loadUserDetails(): Promise<any> {
    const { userId } = this.props;

    const response = await this.userService.getUserDetails(userId);
    if (response.data !== undefined) {
      const { data } = response;
      this.setState({
        userDetails: data,
        email: data.email,
      });
      if (data.status && data.status === 'BLOCKED') {
        this.setState({ dangerButtons: initDangerAreaButtonsUnblock });
      } else {
        this.setState({ dangerButtons: initDangerAreaButtons });
      }
    }
  }

  async loadUserFeatures(userId: string): Promise<void> {
    const response = await this.userService.getUserFeatures(userId);

    if (response !== undefined) {
      const { content } = await response;
      this.setState({ userFeatures: content });
    }
  }

  handleDangerAreaUpdate(): void {
    this.loadUserDetails();
  }

  handleResetFunc(): void {
    console.log(' handle reset functionaly ');
  }

  manageVerificationModalHide(): void {
    this.setState({ userVerificatinShow: false });
  }

  async handleUnverification(): Promise<any> {
    const { userId } = this.props;
    const status = await this.userService.unverifyUser(userId);
    this.handleUpdate(status, 'Unverification applied!');
  }

  async handleEmailSubmit(): Promise<any> {
    const { userId } = this.props;
    const status = await this.userService.changeEmail(userId);
    this.handleUpdate(status, 'Changed email!');
    this.setState({ changeEmailMode: 'apply' });
  }

  handleEmailChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const emailVal = e.target.value;
    const errors = validate(emailVal, ValidationTypes.Email);
    const valid = errors.length < 1;
    this.setState({ email: emailVal, emailValid: valid });
  }

  handleUpdate(status: string, successNote: string): void {
    if (status === undefined) {
      this.loadUserDetails();
      notify(successNote);
    }
  }

  handleEmailEdit(): void {
    this.setState({ changeEmailMode: 'edit' });
  }

  render(): React.ReactNode {
    const { userId } = this.props;
    const {
      userDetails, isStorageModalOpen, userFeatures, dangerButtons, userVerificatinShow,
    } = this.state;
    const rowMb = '';
    const width = { label: 4, value: 8 };
    const btnSizeSm = GeneralSettings.USER_MANAGEMENT.DETAILS.BUTTON_SM_SIZE;

    const verifyButton = (
      <ActionButton
        title="Verify"
        onClick={this.handleVerification}
        isSmall
        isEnableButton
        customStyle={{ padding: btnSizeSm.padding, fontSize: btnSizeSm.fontSize }}
      />
    );
    const unverifyButton = (
      <DangerButton
        title="Unverify"
        onClick={this.handleUnverification}
        customStyle={{ padding: btnSizeSm.padding, fontSize: btnSizeSm.fontSize }}
      />
    );

    let verificationLabel = 'Not Verified';
    let showVerButton = verifyButton;
    let verifyStatClass = '';

    if (userDetails.isVerified) {
      verificationLabel = 'Verified';
      showVerButton = unverifyButton;
      verifyStatClass = styles.verifiedStatus;
    }

    const verificationCol = (
      <Row>
        <Col className={styles.verifyTitleCol} xs={10} md={10} lg={10}>
          <span className={verifyStatClass} style={{ whiteSpace: 'nowrap' }}>
            {verificationLabel}
          </span>
        </Col>
        <Col xs={2} md={2} lg={2} className={styles.verifyBtnCol}>
          {showVerButton}
        </Col>
      </Row>
    );

    const username = userDetails.username || '';
    const fullname = userDetails.fullname || '';
    const alertStatusCol = formatAlertStatus(userDetails.alertStatus);
    const phone = userDetails.phone || '-';
    const {
      usedStorageBytes, baseStorageBytes, paidStorageBytes, extra,
    } = userDetails.storage;
    const { invitedUsers, storageBytes } = extra;

    const usedStorageCalculator = (value: number): string => {
      let unit = '';
      if (value >= StorageUnit.TB) {
        unit = `${(value / StorageUnit.TB).toFixed(2)} TB`;
      } else if (value >= StorageUnit.GB) {
        unit = `${(value / StorageUnit.GB).toFixed(2)} GB`;
      } else if (value >= StorageUnit.MB) {
        unit = `${(value / StorageUnit.MB).toFixed(2)} MB`;
      } else if (value >= StorageUnit.KB) {
        unit = `${(value / StorageUnit.KB).toFixed(2)} KB`;
      } else if (value < StorageUnit.KB && value > 0) {
        unit = `${value} B`;
      }

      return unit;
    };

    const handleModal = (): void => {
      this.setState({ isStorageModalOpen: !isStorageModalOpen });
    };

    const getUserDetailData = async () => {
      const response = await this.userService.getUserDetails(userId);

      if (response.data !== undefined) {
        const { data } = response;
        this.setState({
          userDetails: data,
        });
      }
    };

    const baseStorageAmount = usedStorageCalculator(baseStorageBytes);

    const baseStorageRow = (
      <Row>
        <Col className={styles.verifyTitleCol} xs={10} md={10} lg={10}>
          <span style={{ whiteSpace: 'nowrap' }}>
            {baseStorageAmount || '-'}
          </span>
        </Col>
        <Col xs={2} md={2} lg={2} className={styles.verifyBtnCol}>
          <Button onClick={() => handleModal()} size="sm" className={styles.changeBaseStorageBTN}>Change</Button>
        </Col>
        {isStorageModalOpen && <StorageModal currentStorageVal={parseInt(baseStorageAmount, 10)} getNewData={getUserDetailData} userId={userId} handleClose={handleModal} />}
      </Row>
    );
    const usedStorage = usedStorageCalculator(usedStorageBytes) || '-';
    const paidStorage = usedStorageCalculator(paidStorageBytes) || '-';
    const invitedUsersCount = invitedUsers > 0 ? `(${invitedUsers} invited users)` : '(no invited users)';
    const extraStorageBytes = usedStorageCalculator(storageBytes) || '-';

    const columns = [
      { title: 'User ID', value: userId, removedPads: false },
      { title: 'Full name', value: fullname, removedPads: false },
      { title: 'Username', value: username, removedPads: false },
      { title: 'Phone', value: phone, removedPads: false },
      { title: 'Creation date', value: formatDateFromDatetime(userDetails.creationDate), removedPads: false },
      { title: 'Used storage', value: usedStorage, removedPads: false },
      { title: 'Base storage', value: baseStorageRow, removedPads: false },
      { title: 'Paid storage', value: paidStorage, removedPads: false },
      { title: `Extra storage ${invitedUsersCount}`, value: extraStorageBytes, removedPads: false },
      { title: 'Alert status', value: alertStatusCol, removedPads: false },
      { title: 'Verfication', value: verificationCol, removedPads: false },
    ];

    const Rows = columns.map((item) => (
      <ManagementDetailsRow
        key={item.title}
        title={item.title}
        value={item.value}
        isPadded={!item.removedPads}
        colWidthLabel={{ xs: width.label, md: width.label, lg: width.label }}
        colWidthValue={{ xs: width.value, md: width.value, lg: width.value }}
        rowClassName={rowMb}
      />
    ));

    return (
      <Container className={styles.detailsProfileContainer}>
        {Rows}

        <DeviceList userId={userId} devices={userDetails.devices} />

        <Features
          userId={userId}
          items={userFeatures}
          loadUserFeatures={this.loadUserFeatures}
        />

        <UserSettingsForm
          title="UserSettings"
          userId={userId}
        />

        <ManagementDetailsTitle
          title="FUNCTIONALITY"
          rowClassName="mt-2"
          colWidthLabel={{ xs: 12, md: 12, lg: 12 }}
        />

        <ManagementDetailsRow
          title="Reset hidden album functionality"
          value={<DangerButton title="Reset" isDisabled onClick={this.handleResetFunc} customStyle={{ padding: btnSizeSm.padding, fontSize: btnSizeSm.fontSize }} />}
          colWidthLabel={{ xs: 10, md: 10, lg: 10 }}
          colWidthValue={{ xs: 2, md: 2, lg: 2 }}
          customValStyle={{ textAlign: 'right', paddingRight: '8px' }}
          rowClassName=""
          isHMTLElement
        />

        <DangerArea
          buttons={dangerButtons}
          page="user"
          onDangerAreaUpdate={this.handleDangerAreaUpdate}
          userId={userId}
        />

        <UserVerificationModal
          show={userVerificatinShow}
          title="ManageVerification"
          userId={1}
          isManual
          onHide={this.manageVerificationModalHide}
        />
      </Container>
    );
  }
}
