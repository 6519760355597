import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import AuthLayout from 'layouts/auth/authLayout';

interface ExtendProps extends React.PropsWithChildren<any> {
  pageComponent: FC;
}

const AuthRoute = ({ children, ...props }: ExtendProps & RouteComponentProps): React.ReactElement => {
  const { pageComponent, ...others } = props;
  return (
    <AuthLayout>
      <props.pageComponent {...others}>
        {children}
      </props.pageComponent>
    </AuthLayout>
  );
};

export default AuthRoute;
