const getFilteredItems = (items, selectedTags) => {
  return items.filter(item => {
    const itemTags = item.tags;

    if(itemTags?.length > 0) {
      return (
        itemTags.filter(tag => {
          return selectedTags.indexOf(tag) > -1;
        }).length === selectedTags.length
      );
    } 
  });
};

export const isObjectEmpty = (object) => Object.keys(object).length === 0 && object.constructor === Object

export default getFilteredItems;
