import * as React from 'react';
import styles from './switch.module.scss';

export interface SwitchProps {
    mode: 'active' | 'deactive';
    action: () => void;
}

const Switch: React.FC<SwitchProps> = ({ mode, action }) => {
  const makeRandomeId = (): string => {
    const number = Math.floor(Math.random() * 5000);
    const name = 'item';
    return (`${name}-${number}`);
  };
  const id = makeRandomeId();
  const [checked, setChecked] = React.useState(mode === 'active');

  return (
    <div className={styles.switch}>
      <input
        checked={checked}
        type="checkbox"
        id={id}
        onChange={() => {
          action();
          setChecked(!checked);
        }}
      />
      <label htmlFor={id}>Toggle</label>
    </div>
  );
};

export default Switch;
