/* eslint-disable class-methods-use-this */
import React from 'react';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import { AlbumDetailsType } from 'types/album/albumDetails';

import ManagementDetailsTitle from 'components/UI/management/details/detailsTitle';
import ManagementDetailsRow from 'components/UI/management/details/detailsRow';
import { DangerButtonIds, DangerAreaType } from 'types/form/dangerArea';
import UserDetails from 'types/user/userDetails';
import { initUserDetails } from 'models/user/profile';
import { initAlbumDetails } from 'models/albums/management';
import UserService from 'services/user';
import AlbumService from 'services/album';
import DiscoveryCategoryService from 'services/discoveryCategories';
import capitalizeFirstChar from 'helpers/utils/string';
import ActionButton from 'components/UI/buttons/actionButton';
import DangerButton from 'components/UI/buttons/dangerButton';
import GeneralSettings from 'helpers/constants/generalSettings';

import { SelectOptionType } from 'types/form/select';
import IMAGE_RESOLUTIONS from 'helpers/constants/imageResolutions';
import MENU from 'helpers/constants/menuConstants';
import { Link } from 'react-router-dom';
import notify, { NotifyLevels } from 'services/notify';

import DangerArea from 'components/UI/management/dangerArea';
import { formatDateFromDatetime } from 'helpers/utils/date';
import styles from './albumDetails.module.scss';
import AlbumTagCategories from './AlbumTagCategories';

enum AlbumCategoryModes {
  Apply = 'apply',
  Edit = 'edit'
}

type AlbumDetailsProps = {
  userId: string;
  albumId: string;
}

type AlbumDetailsState = {
  dangerButtons: Array<DangerAreaType>;
  userDetails: UserDetails;
  albumDetails: AlbumDetailsType | any;
  categoryOptions: SelectOptionType[];
  categories: SelectOptionType[];
  categoryMode: string;
  memberInfoVisible: boolean;
}

const initDangerAreaButtons = [
  { id: DangerButtonIds.BlockAlbum, title: '', buttonTitle: 'Block album' },
];

const initDangerAreaButtonsUnblock = [
  { id: DangerButtonIds.BlockAlbum, title: '', buttonTitle: 'Block album' },
];

export default class AlbumDetails extends React.Component<AlbumDetailsProps, AlbumDetailsState> {
  userService: UserService;

  categoriesService: DiscoveryCategoryService;

  albumService: AlbumService;

  constructor(props: AlbumDetailsProps) {
    super(props);
    this.state = {
      dangerButtons: initDangerAreaButtons,
      userDetails: initUserDetails,
      albumDetails: initAlbumDetails,
      categoryOptions: [],
      categories: [],
      categoryMode: AlbumCategoryModes.Apply,
      memberInfoVisible: false,
    };
    this.userService = new UserService();
    this.albumService = new AlbumService();
    this.categoriesService = new DiscoveryCategoryService();
    this.handleTagsChange = this.handleTagsChange.bind(this);
    this.handleDangerAreaUpdate = this.handleDangerAreaUpdate.bind(this);
    this.handleCategoriesApply = this.handleCategoriesApply.bind(this);
    this.handleMemberInfo = this.handleMemberInfo.bind(this);
    this.handleTagsEdit = this.handleTagsEdit.bind(this);
    this.handleDeleteCover = this.handleDeleteCover.bind(this);
    this.handleMembersLoadMore = this.handleMembersLoadMore.bind(this);
  }

  componentDidMount(): void {
    this.loadUserDetails();
    this.loadAlbumDetails();
    this.loadCategories();
  }

  getUserLink(userName: string, usrId: string): React.ReactElement {
    const userUrl = `${MENU.ADMIN_MAIN.USER_MANAGEMENT_PROFILE.split(':')[0]}:${usrId}`;
    const userLink = (
      <Link to={userUrl}>
        <span>{userName}</span>
      </Link>
    );
    return userLink;
  }

  async loadUserDetails(): Promise<any> {
    const { userId } = this.props;
    const response = await this.userService.getUserDetails(userId);
    if (response.data !== undefined) {
      const { data } = response;
      this.setState({ userDetails: data });
      if (data.status && data.status === 'BLOCKED') {
        this.setState({ dangerButtons: initDangerAreaButtonsUnblock });
      } else {
        this.setState({ dangerButtons: initDangerAreaButtons });
      }
    }
  }

  async loadAlbumDetails(): Promise<any> {
    const { userId, albumId } = this.props;
    const response = await this.albumService.getUserAlbumsDetails(userId, albumId);
    if (response.data !== undefined) {
      const { data } = response;
      this.setState({
        albumDetails: data,
        categories: this.albumService.convertCategoriesToSelectList(data.categories),
      });
    }
  }

  async loadCategories(): Promise<any> {
    const response = await this.categoriesService.getCategories();
    if (response.data !== undefined && response.data.length > 0) {
      this.setState({
        categoryOptions: this.categoriesService.getCategoriesAsOptions(response.data),
      });
    }
  }

  handleDangerAreaUpdate(): void {
    this.loadUserDetails();
  }

  handleTagsChange(selectedCategory: SelectOptionType): void {
    this.setState({ categories: [selectedCategory] });
  }

  async handleCategoriesApply(): Promise<any> {
    const { userId, albumId } = this.props;
    this.setState({ categoryMode: AlbumCategoryModes.Apply });
    const { categories } = this.state;
    const status = await this.albumService.setCategory(userId, albumId, categories[0].value);
    if (status === undefined) {
      notify('Change category applied!', NotifyLevels.SUCCESS);
    }
  }

  handleTagsEdit(): void {
    this.setState({ categoryMode: AlbumCategoryModes.Edit });
  }

  async handleDeleteCover(): Promise<any> {
    const { userId, albumId } = this.props;
    const response = await this.albumService.deleteCoverPhoto(userId, albumId);
    if (response === undefined) {
      notify('Delete cover image applied!', NotifyLevels.SUCCESS);
      this.loadAlbumDetails();
    }
  }

  handleMemberInfo(): void {
    this.setState({ memberInfoVisible: true }); // @todo use info from server
  }

  handleMembersLoadMore(): void { }

  render(): React.ReactNode {
    const { userId, albumId } = this.props;
    const {
      dangerButtons,
      userDetails,
      albumDetails,
      categoryOptions,
      categories,
      categoryMode,
      memberInfoVisible,
    } = this.state;
    const rowMb = '';
    const width = { label: 3, value: 9 };
    const btnSizeSm = GeneralSettings.USER_MANAGEMENT.DETAILS.BUTTON_SM_SIZE;

    const ctgApply = (
      <ActionButton title="Apply" onClick={this.handleCategoriesApply} customStyle={{ padding: btnSizeSm.padding, fontSize: btnSizeSm.fontSize }} />
    );
    const ctgEdit = (
      <ActionButton title="Edit" onClick={this.handleTagsEdit} customStyle={{ padding: btnSizeSm.padding, fontSize: btnSizeSm.fontSize }} />
    );
    const ctgButtons = categoryMode === AlbumCategoryModes.Apply ? ctgEdit : ctgApply;

    const tagCategoryCol = (
      <Row>
        <Col xs={8} md={8} lg={8} xl={10}>
          <AlbumTagCategories
            options={categoryOptions}
            values={categories}
            mode={categoryMode}
            onCategoryChange={this.handleTagsChange}
          />
        </Col>
        <Col xs={4} md={4} lg={4} xl={2} className={styles.albumDetailsCategoryCol}>
          {ctgButtons}
        </Col>
      </Row>
    );
    const scale = IMAGE_RESOLUTIONS.IMAGE_XS;
    const isCoverPhoto = !albumDetails.coverPhoto;
    const coverStyle = albumDetails.coverPhoto ? { backgroundImage: `url(${albumDetails.coverPhoto.url}${scale}` } : {};
    const albumCoverCol = (
      <Row>
        <Col xs={6} md={6} lg={6} xl={6}>
          <div className={styles.albumDetailsCover} style={coverStyle} />
        </Col>
        <Col xs={6} md={6} lg={6} xl={6} className={styles.albumDetailCoverDelete}>
          <DangerButton
            title="Delete album cover"
            isDisabled={isCoverPhoto}
            onClick={this.handleDeleteCover}
            customStyle={{ padding: btnSizeSm.padding, fontSize: btnSizeSm.fontSize }}
          />
        </Col>
      </Row>
    );
    const sharedState = albumDetails.isSharedAlbum ? (<span className={styles.albumDetailsShare}>Shared</span>) : '-';
    const albumColumns = [
      { title: 'Album ID', value: albumDetails.id },
      { title: 'Album type', value: capitalizeFirstChar(albumDetails.privacyLevel) },
      { title: 'Shared state', value: sharedState },
      { title: 'Album Category', value: tagCategoryCol },
      { title: 'Creation Date', value: formatDateFromDatetime(albumDetails.creationDate) },
      { title: 'Album Cover', value: albumCoverCol },
    ];

    // @todo use from real data
    const collaborativeList = [
      { userName: 'Alex', id: 'sdsaswsadsas' },
      { userName: 'Ron', id: 'adsas23adsdasasddsas' },
    ];
    const collaborative = collaborativeList.map((item) => <li key={item.id}>{this.getUserLink(item.userName, item.id)}</li>);
    const loadMoreItemsBtn = (
      <ActionButton title="Load more..." onClick={this.handleMembersLoadMore} />
    );
    collaborative.push(
      <li key="dssdaasds">
        {loadMoreItemsBtn}
      </li>,
    );
    const collaborativeSection = (
      <ul className={styles.albumDetailsMemberList}>{collaborative}</ul>
    );

    const membersColumns = [
      { title: 'Album Owner', value: this.getUserLink(userDetails.fullname, userId) },
      { title: 'Collaborative', value: collaborativeSection },
    ];

    const AlbumRows = albumColumns.map((item) => (
      <ManagementDetailsRow
        key={item.title}
        title={item.title}
        value={item.value}
        colWidthLabel={{ xs: width.label, md: width.label, lg: width.label }}
        colWidthValue={{ xs: width.value, md: width.value, lg: width.value }}
        rowClassName={rowMb}
      />
    ));

    const MembersListButton = (
      <Row className="mt-2">
        <Col className={styles.albumMemberBtnCol}>
          <ActionButton title="Show member list" disabled onClick={this.handleMemberInfo} customStyle={{ padding: btnSizeSm.padding, fontSize: btnSizeSm.fontSize }} />
        </Col>
      </Row>
    );

    const MemberSection = memberInfoVisible ? membersColumns.map((item) => (
      <ManagementDetailsRow
        key={item.title}
        title={item.title}
        value={item.value}
        colWidthLabel={{ xs: width.label, md: width.label, lg: width.label }}
        colWidthValue={{ xs: width.value, md: width.value, lg: width.value }}
        rowClassName={rowMb}
      />
    )) : MembersListButton;

    return (
      <Container className={styles.detailsContainer}>
        <ManagementDetailsTitle
          title="ALBUM DETAILS"
          rowClassName="mt-2 mb-2"
          colWidthLabel={{ xs: 12, md: 12, lg: 12 }}
        />
        {AlbumRows}
        <ManagementDetailsTitle
          title="MEMBER DETAILS"
          rowClassName="mt-4 mb-2"
          colWidthLabel={{ xs: 12, md: 12, lg: 12 }}
        />
        {MemberSection}
        <DangerArea
          buttons={dangerButtons}
          page="albumDetails"
          userId={userId}
          albumId={albumId}
          onDangerAreaUpdate={this.handleDangerAreaUpdate}
        />

      </Container>
    );
  }
}
