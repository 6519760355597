import React from 'react';
import { NavLink } from 'react-router-dom';
import userListIcon from 'assets/sidebar/user.svg';
import reportContentIcon from 'assets/sidebar/report.svg';
import discoveryIcon from 'assets/sidebar/discovery.svg';

import styles from './sidebar.module.scss';
import ReportSubMenu from './submenu/report';
import UserManagementSubMenu from './submenu/user';
import DiscoverySubMenu from './submenu/discovery';
import CrmSubMenu from './submenu/crm';

const SideBarMenu: React.FC = () => {
  const ShowReportMenu = ReportSubMenu;
  const ShowManagementMenu = UserManagementSubMenu;
  const ShowDiscoveryMenu = DiscoverySubMenu;
  const ShowCrmMenu = CrmSubMenu;

  return (
    <ul className={styles.nav}>
      <li className={styles.navItem}>
        <NavLink
          className={styles.navInactiveLink}
          to="#"
        >
          <img alt="User list" src={userListIcon} />
          <span className={styles.navLinkManagement}>
            Management
          </span>
        </NavLink>
        {ShowManagementMenu}
      </li>
      <li className={styles.navItem}>
        <NavLink
          className={styles.navInactiveLink}
          to="#"
        >
          <img alt="Report list" src={reportContentIcon} />
          <span className={styles.navLinkReport}>
            Reported Content
          </span>
        </NavLink>
        {ShowReportMenu}
      </li>
      <li className={styles.navItem}>
        <NavLink
          className={styles.navInactiveLink}
          to="#"
        >
          <img alt="Discovery" src={discoveryIcon} />
          <span className={styles.navLinkDiscovery}>
            Discovery
          </span>
        </NavLink>
        {ShowDiscoveryMenu}
      </li>
      <li className={styles.navItem}>
        <NavLink
          className={styles.navInactiveLink}
          to="#"
        >
          <img alt="Discovery" src={discoveryIcon} />
          <span className={styles.navLinkDiscovery}>
            CRM
          </span>
        </NavLink>
        {ShowCrmMenu}
      </li>
    </ul>
  );
};

export default SideBarMenu;
