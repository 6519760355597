/* eslint-disable no-shadow */ // needed
import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { DraggableArea } from 'react-draggable-tags';
import { DiscoveryCategory } from 'types/discovery/categories';
import ActionButton from 'components/UI/buttons/actionButton';
import CancelButton from 'components/UI/buttons/cancelButton';
import notify, { NotifyLevels } from 'services/notify';
import CommonListResults from 'components/UI/table/Results/commonList';

import DiscoveryCategoryService from 'services/discoveryCategories';
import './categoryOrder.scss';

type CategoryTagsProps = {
  title: string;
}

type CategoryTagsState = {
  tags: Array<DiscoveryCategory>;
  applyActive: boolean;
  loadingItems: boolean;
}

export default class CategoryOrderManagement extends Component<CategoryTagsProps, CategoryTagsState> {
  categoriesService: DiscoveryCategoryService;

  constructor(props: CategoryTagsProps) {
    super(props);
    this.state = {
      tags: [],
      applyActive: false,
      loadingItems: false,
    };
    this.handleApply = this.handleApply.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleOrderChange = this.handleOrderChange.bind(this);
    this.categoriesService = new DiscoveryCategoryService();
  }

  componentDidMount(): void {
    this.loadCategories();
  }

  async loadCategories(): Promise<any> {
    this.setState({ loadingItems: true });
    const response = await this.categoriesService.getCategories();
    if (response.data !== undefined && response.data.length > 0) {
      this.setState({
        tags: response.data,
      });
    }
    this.setState({ loadingItems: false });
  }

  async handleApply(): Promise<any> {
    const { tags } = this.state;
    this.setState({ applyActive: false });
    const status = await this.categoriesService.setCategories(tags); // @todo unremove
    if (status === undefined) {
      notify('Categories Applied!', NotifyLevels.SUCCESS);
    }
  }

  handleReset(): void {
    this.setState({ applyActive: false });
    this.loadCategories();
  }

  handleOrderChange(newTgs: DiscoveryCategory[]): void {
    this.setState({ applyActive: true, tags: newTgs });
  }

  render(): React.ReactElement {
    const { title } = this.props;
    const { tags, applyActive: applyDisabled, loadingItems } = this.state;
    const discoveryItemsEmpty = (
      <CommonListResults
        loadingItems={loadingItems}
        loadingTitle="Loading Discovery..."
        noResultsTitle="No Category items found"
        showCondition={loadingItems}
      />
    );

    const ControlMode = (
      <ul className="buttons">
        <li>
          <ActionButton title="Apply" onClick={this.handleApply} active={applyDisabled} isApplyButton />
        </li>
        <li>
          <CancelButton title="Reset" disableOutlineHover customStyle={{ color: '#c9302c', border: 'none' }} onClick={this.handleReset} />
        </li>
      </ul>
    );

    return (
      <Container fluid>
        <Row className="mt-2 mb-2">
          <Col>
            <h3>{title}</h3>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <div className="TagEditor">
              <div className="main">
                <DraggableArea
                  tags={tags}
                  render={({ tag }): React.ReactElement => (
                    <div className="tag">
                      {tag.name}
                    </div>
                  )}
                  onChange={(tags): void => this.handleOrderChange(tags)}
                />
              </div>
              <div className="inputs">
                {ControlMode}
              </div>
              {discoveryItemsEmpty}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
