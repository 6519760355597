import React, { MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Modal,
  Container,
  Row,
  Col,
  Form,
} from 'react-bootstrap';
import MENU from 'helpers/constants/menuConstants';
import CancelButton from 'components/UI/buttons/cancelButton';
import ActionButton from 'components/UI/buttons/actionButton';
import { UserVerificationProfile } from 'types/user/userProfile';
import ReasonDialog from 'components/UI/dialogs/reasonDialog';
import { verificationData } from 'models/user/verificationManagement';
import validate, { ValidationTypes } from 'helpers/utils/liteValidator';

import styles from './userVerification.module.scss';
import MgnUserTextRow from './MngVerTextRow';
import MgnUserMultiRow from './MngVerMultiRow';

const userDataEmpty = {
  name: '',
  email: '',
  phone: '',
  website: '',
  description: '',
};

type ErrorType = {
  name: string;
  email: string;
  phone: string;
  website: string;
}

type ManageModalProps = {
  title: string;
  userId: string | number;
  onHide: () => void;
  show: boolean;
  isManual?: boolean;
}
interface State {
  user: UserVerificationProfile;
  errors: ErrorType;
  isReasonDialogVisible: boolean;
}

enum fieldsTypes {
   Brand = 'brand',
   Email = 'email',
   Phone = 'phone',
   Website = 'website',
   Business = 'business'
}

export default class UserVerificationModal extends React.Component<ManageModalProps, State> {
  constructor(props: ManageModalProps) {
    super(props);
    const { isManual } = this.props;
    this.state = {
      user: !isManual ? verificationData : userDataEmpty,
      errors: {
        name: '',
        email: '',
        phone: '',
        website: '',
      },
      isReasonDialogVisible: false,
    };
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleDialogConfirm = this.handleDialogConfirm.bind(this);
    this.handleDenial = this.handleDenial.bind(this);
    this.handleVerify = this.handleVerify.bind(this);
    this.handleTextField = this.handleTextField.bind(this);
  }

  componentDidMount(): void {
    const { isManual } = this.props;
    this.setState({ user: !isManual ? verificationData : userDataEmpty });
  }

  closeDenialDialog(): void {
    this.setState({ isReasonDialogVisible: false });
  }

  handleDialogClose(): void{
    this.closeDenialDialog();
  }

  handleDialogConfirm(): void{
    this.closeDenialDialog();
  }

  handleDenial(e: MouseEvent): void {
    e.preventDefault();
    this.setState({ isReasonDialogVisible: true });
  }

  handleVerify(e: MouseEvent): void {
    e.preventDefault();
    const { onHide } = this.props;
    this.closeDenialDialog();
    onHide();
  }

  handleTextField(e: React.ChangeEvent<HTMLInputElement>, field: string): void {
    const { value } = e.target;
    let key = '';
    let errList: Array<string> = [];
    switch (field) {
      case fieldsTypes.Brand:
        key = 'name';
        break;
      case fieldsTypes.Email:
        key = 'email';
        errList = validate(value, ValidationTypes.Email);
        break;
      case fieldsTypes.Phone:
        key = 'phone';
        errList = validate(value, ValidationTypes.Phone);
        break;
      case fieldsTypes.Business:
        key = 'description';
        break;
      case fieldsTypes.Website:
      default:
        key = 'website';
        break;
    }
    const error = errList && errList.length > 0 ? errList[0] : '';

    this.setState((prevState) => {
      const user = { ...prevState.user };
      user[key] = value;
      return { user };
    });
    this.setState((prevState) => {
      const errors = { ...prevState.errors };
      errors[key] = error;
      return { errors };
    });
  }

  render(): React.ReactNode {
    const {
      onHide,
      show,
      userId,
      isManual,
    } = this.props;
    const { user, isReasonDialogVisible, errors } = this.state;
    const titleClass = `justify-content-center ${styles.modalTitle}`;

    const width = { label: 12, value: 12 };
    const onHiding = (): void => { this.closeDenialDialog(); onHide(); };
    const profileUrl = `${MENU.ADMIN_MAIN.USER_MANAGEMENT_PROFILE.split(':')[0]}:${userId}`;

    const isManualVerification = isManual;

    const profileLinkContainer = (
      <span className={styles.fieldProfile}>
        <NavLink
          to={{ pathname: profileUrl }}
        >
          Open user management
        </NavLink>
      </span>
    );

    const profileLink = isManualVerification ? null : profileLinkContainer;
    const inputsDisabled = !isManualVerification;

    const ApprovalDialog = (
      <ReasonDialog
        title="Denial of Verification"
        onHide={this.handleDialogClose}
        onConfirm={this.handleDialogConfirm}
        show={isReasonDialogVisible}
      />
    );

    return (
      <Modal show={show} onHide={onHiding} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton className={styles.modalHeader}>
          <Modal.Title className={titleClass}>
            {user.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <Container className={styles.modalContent}>
            <Form>
              <MgnUserTextRow label="Selebrity of Brand name" value={user.name} onChange={(e): void => { this.handleTextField(e, fieldsTypes.Brand); }} width={width} disabled={inputsDisabled} />
              <MgnUserTextRow label="Email address" value={user.email} width={width} onChange={(e): void => { this.handleTextField(e, fieldsTypes.Email); }} disabled={inputsDisabled} errorNotice={errors.email} />
              <MgnUserTextRow label="Phone number" value={user.phone} width={width} onChange={(e): void => { this.handleTextField(e, fieldsTypes.Phone); }} disabled={inputsDisabled} errorNotice={errors.phone} />
              <MgnUserTextRow label="Website" value={user.website} width={width} onChange={(e): void => { this.handleTextField(e, fieldsTypes.Website); }} disabled={inputsDisabled} />
              <MgnUserMultiRow label="Desciption of business" value={user.description} onChange={(e): void => { this.handleTextField(e, fieldsTypes.Business); }} disabled={inputsDisabled} />
              <Row>
                <Col>
                  {profileLink}
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer className={styles.modalFooter}>
          <Row className="justify-content-right">
            <Col className={styles.modalFooterButtons}>
              <CancelButton title="Decline" onClick={this.handleDenial} isTextBold />
              <ActionButton title="Verify" onClick={this.handleVerify} isTextBold />
            </Col>
          </Row>
        </Modal.Footer>
        {ApprovalDialog}
      </Modal>
    );
  }
}
