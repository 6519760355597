import Slider from '@mui/material/Slider';
import * as React from 'react';

interface RangeSliderProps {
    action: (event: Event, newValue: number | number[]) => void
    defaultValue: any
}

const RangeSlider: React.FC<RangeSliderProps> = ({ action, defaultValue }) => {
    const [value, setValue] = React.useState<number[]>(defaultValue || [0, 100]);

    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number[]);
        action(event, newValue)
    };

    return (
        <Slider
            getAriaLabel={() => 'Age range'}
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
        />
    );
}

export default RangeSlider;