import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { SelectOptionType } from 'types/form/select';

const animatedComponents = makeAnimated();

interface MultiSelectProps {
  value?: Array<SelectOptionType>;
  options: Array<SelectOptionType>;
  defaultValues?: Array<SelectOptionType>;
  onChange: (event: any) => void;
  placeholder?: string;
  disabled?: boolean;
  isSingleSelect?: boolean;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  placeholder = 'Select',
  onChange,
  disabled = false,
  value = null,
  defaultValues = [],
  isSingleSelect = false,
}) => {
  if (value !== null) {
    return (
      <Select
        isMulti={!isSingleSelect}
        placeholder={placeholder}
        closeMenuOnSelect={false}
        components={animatedComponents}
        value={value}
        defaultValue={defaultValues}
        options={options}
        onChange={onChange}
        isDisabled={disabled}
      />
    );
  }
  return (
    <Select
      isMulti={!isSingleSelect}
      placeholder={placeholder}
      closeMenuOnSelect={false}
      components={animatedComponents}
      defaultValue={defaultValues}
      options={options}
      onChange={onChange}
      isDisabled={disabled}
    />
  );
};

export default MultiSelect;
