import * as React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ListPagation from 'components/UI/table/listPagination';

import styles from '../Tags.module.scss';
import { Button } from 'react-bootstrap';
import { TagsResponseData } from 'types/crm';
import crmService from 'services/crm';

interface TagsListProps {
    data: TagsResponseData['content']
    total: number
    getData: (query?: string, offset?: number) => void
}

const TagsList: React.FC<TagsListProps> = ({ data, total, getData }) => {

    const handleDeleteTag = (name: string) => {
        crmService.deleteTag(name).then(() => {
            getData();
        })
    }

    const deleteCol = (cell, row): React.ReactNode => (
        <Button onClick={() => handleDeleteTag(row.name)} size="sm" variant='danger'>Delete</Button>
    );

    const handlePagination = (page: number) => {
        const offset = (page * 10) - 10;
        getData('', offset)
    }

    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            classes: `${styles.userTableCol} ${styles.name}`,
        },
        {
            dataField: 'delete',
            text: 'Action',
            classes: styles.userTableCol,
            formatter: deleteCol,
        },
    ]

    const rows = data.map((item: string) => ({ name: item }));
    const numberOfPages = Math.floor(total / 10);

    return (
        <>
            <BootstrapTable
                keyField="id"
                data={rows}
                columns={columns}
                headerClasses={styles.userTableHeader}
                BootstrapTable="bootstrap4"
            />
            {numberOfPages > 1 && (
                <ListPagation
                    firstPage={1}
                    pageCount={numberOfPages}
                    onClick={(page) => handlePagination(page)}
                    isBlocked={false}
                />
            )}
        </>
    );
}

export default TagsList;