import React from 'react';
import { Link } from 'react-router-dom';

import logo from 'assets/logo.svg';
import styles from './authLayout.module.scss';

const LayoutHeader: React.FC = () => (
  <header className={styles.layoutHeader}>
    <Link className={styles.logo} to="/">
      <img className={styles.img} alt="Albums Admin" src={logo} />
      <span className={styles.logoText}>Admin Panel</span>
    </Link>
  </header>
);

export default LayoutHeader;
