import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Routes from 'routes';
import NetConnectionChecker from 'components/UI/netChecker';
import Settings from 'helpers/constants/generalSettings';
import UI_CONSTANTS from 'helpers/constants/uiConstants';
import styles from './App.module.scss';

const App: React.FC = () => {
  const NetChecker = Settings.NET_CONNECTION_CHECKER.ENABLED ? <NetConnectionChecker enabled /> : null;
  return (
    <div className={styles.app}>
      <Routes />
      <ToastContainer
        enableMultiContainer
        hideProgressBar
        containerId={UI_CONSTANTS.NOTIFY_CONTAINER.MAIN_ID}
        position={toast.POSITION.BOTTOM_CENTER}
      />
      {NetChecker}
    </div>
  );
};

export default App;
