import ActionButton from 'components/UI/buttons/actionButton';
import FormTextInput from 'components/UI/texts/text';
import * as React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

interface TagsSearchProps {
  searchAction: (value: string) => void;
}

const TagsSearch: React.FC<TagsSearchProps> = ({searchAction}) => {
  const [val, setVal] = React.useState('');

  return (
    <Form onSubmit={() => searchAction(val)} noValidate style={{ margin: '20px 0' }}>
      <Row className="justify-content-between">
        <Col>
          <FormTextInput
            value={val}
            onChange={(e) => setVal(e.target.value)}
            controlId="user-search"
            label="Search tags"
            type="text"
            placeholder="Enter the tags name"
            inputIcon="search"
            disabled={false}
          />
        </Col>
      </Row>
      <Row className="justify-content-between">
        <Col>
          <ActionButton
            title="Show results"
            onClick={() => searchAction(val)}
            disabled={false}
            isTextBold
            customStyle={{ padding: '9px 24px 9px 24px', marginRight: '10px' }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default TagsSearch;
