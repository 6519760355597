import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { AlbumsType } from 'types/album';
import { PictureItem } from 'types/content-item';
import ActionButton from 'components/UI/buttons/actionButton';
import { trackScrolling } from 'helpers/utils/scroll';
import AlbumsService from 'services/album';
import ListResults from 'components/UI/table/Results';
import SETTINGS from 'helpers/constants/generalSettings';

import styles from './albumProfile.module.scss';
import AlbumDetails from './AlbumDetails';
import AlbumItem from './AlbumItem';
import AlbumPictureManagementModal from './AlbumPictureManagement';

type AlbumProps = {
  userId: string;
  albumId: string;
}

type AlbumState = {
  pictureModalShow: boolean;
  pictures: Array<PictureItem>;
  picturesTotal: number;
  portionLoading: boolean;
  albumDetails: AlbumsType[] | any;
  selectedItem: PictureItem;
  isAlbumContentVisible: boolean;
  loadingItems: boolean;
  currentPage: number;
  albumName: string;
  pageCount: number;
}

const PAGE_SIZE = SETTINGS.ALBUM_CONTENT.PER_PAGE;

export default class AlbumManagement extends React.Component<AlbumProps, AlbumState> {
  albumService: AlbumsService;

  scrollContainer: string;

  constructor(props: AlbumProps) {
    super(props);
    this.state = {
      pictureModalShow: false,
      pictures: [],
      picturesTotal: 0,
      portionLoading: false,
      albumDetails: [],
      selectedItem: {
        pictureId: '',
        pictureSource: '',
        userId: '',
        albumId: '',
      },
      isAlbumContentVisible: false,
      loadingItems: false,
      currentPage: 1,
      albumName: '',
      pageCount: 1,
    };
    this.albumService = new AlbumsService();
    this.scrollContainer = 'picturesContainer';
    this.handlePictureManagementClose = this.handlePictureManagementClose.bind(this);
    this.handleAlbumItemEnter = this.handleAlbumItemEnter.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleShowAlbumContent = this.handleShowAlbumContent.bind(this);
  }

  componentDidMount(): void {
    this.loadImageItems();
    document.addEventListener('scroll', () => { trackScrolling(this.scrollContainer, this.handleScroll); });
  }

  componentWillUnmount(): void {
    document.removeEventListener('scroll', () => { trackScrolling(this.scrollContainer, this.handleScroll); });
  }

  async loadAlbumDetails(): Promise<any> {
    const { userId } = this.props;
    const response = await this.albumService.getUserAlbums(userId);
    if (response.data !== undefined) {
      const { data } = response;
      this.setState({
        albumDetails: data,
      });
    }
  }

  async loadImageItems(newPage?: number): Promise<any> {
    const curPage = newPage || 1;
    const { userId, albumId } = this.props;
    this.setState({ loadingItems: true });
    const response = await this.albumService.getUserAlbumsContentItems(userId, albumId, curPage, PAGE_SIZE);
    if (response.data !== undefined && response.data.length > 0) {
      const { pagination } = response;
      this.setState({
        pageCount: pagination.pageCount,
        picturesTotal: response.total,
        albumName: response.albumName,
      });
      this.addToContentItems(response.data);
    }
    this.setState({ loadingItems: false });
  }

  addToContentItems(newItems: PictureItem[]): void {
    this.setState((prevState) => ({
      ...prevState,
      pictures: prevState.pictures ? [...prevState.pictures.concat(newItems)] : prevState.pictures,
    }));
  }

  handleScroll(): void {
    const {
      currentPage,
      isAlbumContentVisible,
      pageCount,
      loadingItems,
    } = this.state;
    if (!isAlbumContentVisible || loadingItems) { return; }
    if (currentPage > pageCount * PAGE_SIZE) {
      document.removeEventListener('scroll', () => { trackScrolling(this.scrollContainer, this.handleScroll); });
      return;
    }
    // load next portion from backend
    this.setState((prevState) => ({
      ...prevState,
      currentPage: prevState.currentPage + 1,
      portionLoading: true,
    }));
    this.loadImageItems(currentPage + 1);
  }

  handlePictureManagementClose(reloadItems: boolean): void{
    this.setState({ pictureModalShow: false });
    if (reloadItems) {
      this.loadImageItems();
    }
  }

  handleAlbumItemEnter(item: PictureItem): void{
    this.setState({ pictureModalShow: true, selectedItem: item });
  }

  handleShowAlbumContent(): void {
    this.setState({ isAlbumContentVisible: true });
  }

  render(): React.ReactNode {
    const { userId, albumId } = this.props;
    const {
      pictureModalShow,
      pictures,
      selectedItem,
      isAlbumContentVisible,
      picturesTotal,
      albumName = '',
    } = this.state;
    const width = {
      xs: 12,
      sm: 6,
      md: 4,
      lg: 3,
    };

    const AlbumsButton = (
      <Col className={styles.albumsCol}>
        <ActionButton title="Show album content" isTextBold onClick={this.handleShowAlbumContent} />
      </Col>
    );
    const AlbumItemsEmpty = (<Col className={styles.albumItemsEmpty}><ListResults notice="No album items" customRowStyle={{ margin: '0', padding: '9px', height: '45px' }} /></Col>);
    const AlbumItems = pictures.length > 0 ? pictures.map((item) => (
      <Col key={item.pictureId} xs={width.xs} sm={width.sm} md={width.md} lg={width.lg} className={styles.albumsCol}>
        <AlbumItem
          pictureId={item.pictureId}
          coverPicture={item.pictureSource}
          title=""
          onAlbumItemEnter={(): void => { this.handleAlbumItemEnter(item); }}
        />
      </Col>
    )) : AlbumItemsEmpty;
    const AlbumItemsSection = isAlbumContentVisible ? AlbumItems : AlbumsButton;
    return (
      <Container fluid>
        <Row>
          <Col xs={6} md={6} lg={6} className={styles.profileDetailsContainer}>
            <AlbumDetails
              userId={userId}
              albumId={albumId}
            />
          </Col>

          <Col xs={6} md={6} lg={6}>
            <Row className="mt-2">
              <Col className={styles.albumName}>
                <h3 className={styles.profileHeader}>{albumName}</h3>
              </Col>
            </Row>

            <Row className="mt-4 mb-2">
              <Col className={styles.albumsMediaTitle}>
                <span className={styles.profileContextTitle}>Media</span>
                <span className={styles.albumMediaCount}>{picturesTotal}</span>
              </Col>
            </Row>

            <Row className="mt-2 mb-2">
              <Col>
                <Container id={this.scrollContainer} style={{ padding: 0 }}>
                  <Row className={styles.albumsRow}>
                    {AlbumItemsSection}
                  </Row>
                </Container>
              </Col>
            </Row>
          </Col>

        </Row>

        <AlbumPictureManagementModal
          picture={selectedItem}
          userId={userId}
          albumId={albumId}
          onHide={this.handlePictureManagementClose}
          show={pictureModalShow}
        />
      </Container>
    );
  }
}
