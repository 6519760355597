/* eslint-disable no-useless-escape */ // regex check
/* eslint-disable @typescript-eslint/ban-types */ // Object type
import { FormErrors } from 'types/form/formErrors';

/* eslint-disable no-restricted-syntax */ // getErrorsFromValidationMap syntax

interface ValidationObjectMapType {
  key: string;
  title: string;
  value: string;
  isRequired: boolean;
  validators: Array<RulesMap>;
}

type RulesMap = {
  minLength?: number;
  maxLength?: number;
  isEmail?: boolean;
}

export function validateURL(webpage: string): boolean {
  return /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(webpage);
}

export function validatePhone(phone: string): boolean {
  const re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
  return re.test(phone);
}

export function validateEmail(email: string): boolean {
  const re = /^[a-z][a-zA-Z0-9_.]*(\.[a-zA-Z][a-zA-Z0-9_.]*)?@[a-z][a-zA-Z-0-9]*\.[a-z]+(\.[a-z]+)?$/;
  return re.test(email);
}

export function validateMinLength(textField: string, minValue: number): boolean {
  return textField.length < minValue;
}

export function validateMaxLength(textField: string, maxValue: number): boolean {
  return textField.length > maxValue;
}

export function getErrorListFromObject(validationObj: Object): Array<string> {
  const errorArray: Array<string> = [];
  Object.keys(validationObj).forEach((key) => {
    errorArray.push(validationObj[key]);
  });
  return errorArray;
}

export function getErrorsFromValidationMap<FieldsType>(validationMap: Array<ValidationObjectMapType>): FormErrors<FieldsType | any> {
  const errors: FormErrors<FieldsType | any> = {};

  for (const field of validationMap) {
    if (field.validators.length > 0) {
      for (const rule of field.validators) {
        if (field.isRequired && field.value) {
          if (rule.minLength && field.value.length < rule.minLength) {
            errors[field.key] = `${field.title} must be more than ${rule.minLength} chars`;
          }
          if (rule.maxLength && field.value.length > rule.maxLength) {
            errors[field.key] = `${field.title} must be not larger than ${rule.maxLength} chars`;
          }
          if (rule.isEmail && !validateEmail(field.value)) {
            errors[field.key] = `${field.title} is not valid E-mail`;
          }
        } else {
          errors[field.key] = `${field.title} is not typed!`;
        }
      }
    }
  }
  return errors;
}
