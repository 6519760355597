import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import { categoriesData, gendersData, subCategoriesData } from 'statics/CRM/data';
import countries from '../countries';
import TagsBox from '../RowEditor/TagsBox';

import { GetUserParamsType } from 'types/crm';

import styles from './Filter.module.scss';
import RangeSlider from './rangeSlider';
import { isObjectEmpty } from 'helpers/utils/crm';

interface CrmFilterProps {
    data: GetUserParamsType['filters']
    setFilter: (data: GetUserParamsType['filters']) => void
    handleFilter: () => void
    setIsFilterOn: (b: boolean) => void
}

const CrmFilter: React.FC<CrmFilterProps> = ({ data, setFilter, handleFilter, setIsFilterOn }) => {
    const filterInitialState: GetUserParamsType['filters'] = {};

    const handleFilterChange = (name: string, value: string) => {
        filterInitialState[name] = value
        setFilter({ ...data, ...filterInitialState })
    }

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        filterInitialState['ageFrom'] = newValue[0];
        filterInitialState['ageTo'] = newValue[1];
        setFilter({ ...data, ...filterInitialState })
    }

    const handleChangeTags = (tags: Array<string>) => {
        setFilter({...data, tags})
    }

    const handleResetFilter = async() => {
        window.location.reload()
    }

    return (
        <>
            <span onClick={() => setIsFilterOn(false)} className={styles.crmFilterBg}></span>
            <div className={styles.crmFilter}>
                <Row>
                    <Col md={12} className="mb-3">
                        <h4>Filter</h4>
                    </Col>
                    <Col lg={6} className="mb-3">
                        <label htmlFor="">Category</label>
                        <select value={data?.category} onChange={(e) => handleFilterChange('category', e.target.value)}>
                            <option value="">Select...</option>
                            {categoriesData.map(item => <option key={item}>{item}</option>)}
                        </select>
                    </Col>
                    <Col lg={6} className="mb-3">
                        <label htmlFor="">Sub category</label>
                        <select value={data?.subCategory} onChange={(e) => handleFilterChange('subCategory', e.target.value)}>
                            <option value="">Select...</option>
                            {subCategoriesData.map(item => <option key={item}>{item}</option>)}
                        </select>
                    </Col>
                    <Col md={6} className="mb-3">
                        <label htmlFor="">Gender</label>
                        <select onChange={(e) => handleFilterChange('gender', e.target.value)} value={data?.gender}>
                            <option value="">Select...</option>
                            {gendersData.map(item => <option key={item}>{item}</option>)}
                        </select>
                    </Col>
                    <Col md={6} className="mb-3">
                        <label htmlFor="">Country</label>
                        <select value={data?.country} onChange={(e) => handleFilterChange('country', e.target.value)} name="" id="">
                            <option value="">Select...</option>
                            {countries.map(country => <option key={country.code}>{country.name}</option>)}
                        </select>
                    </Col>
                    <Col md={12} className="mb-3">
                        <label htmlFor="">Age</label>
                        <div className='pl-2 pr-2'>
                            <RangeSlider defaultValue={!isObjectEmpty(data) ? [data?.ageFrom, data?.ageTo] : undefined} action={handleSliderChange} />
                        </div>
                    </Col>
                    <Col lg={12} className="mb-5">
                        <TagsBox label={'Filter by tags'} defaultTags={data?.tags || []} updateSelectedTags={handleChangeTags} />
                    </Col>
                    <Col>
                        <Button onClick={() => handleFilter()} variant='primary' className='mr-2'>Filter</Button>
                        <Button onClick={() => handleResetFilter()} variant='outline-secondary'>Reset Filter</Button>
                        <Button onClick={() => setIsFilterOn(false)} variant='link'>Close</Button>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default CrmFilter;