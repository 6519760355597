// Arrays utility helper
export function liteShuffle<T>(arr: Array<T>): Array<T> {
  if (!arr || arr.length <= 0) { return arr; }
  return arr.sort(() => Math.random() - 0.5);
}

export function checkObjectHasKeys(obj: any): boolean {
  const keys = Object.keys(obj);
  return keys && keys.length > 0;
}
