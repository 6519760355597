/* eslint-disable dot-notation */ // needed
/* eslint-disable no-restricted-syntax */ // needed
/* eslint-disable class-methods-use-this */ // needed
// User service (get users, user, profile, block, delete)
import { SelectOptionType } from 'types/form/select';

import API_PATH from 'helpers/constants/apiPaths';
import { api } from 'helpers/server/api';

type AllCountriesSuccess = {
  data: Array<Country>;
}

type Country = {
  code: string;
  name: string;
}

type CountrySelectType = SelectOptionType;

// Countries related service which has utility important methods
export default class CountriesService {
  async getAllCountries(convertToSelectList = false): Promise<AllCountriesSuccess | any> {
    const response = await api.get(`/v1/${API_PATH.COMMON.COUNTRIES}`, {});
    if (convertToSelectList && response && response['countries']) {
      const countriesList = this.convertCountriesToSelectList(response['countries']);
      return { data: countriesList };
    }
    return { data: response };
  }

  // transform countries (code, name) to  (label, name) for select
  convertCountriesToSelectList(countries: Array<Country>): Array<SelectOptionType> {
    let countriesSelect: Array<SelectOptionType> = [];
    if (countries) {
      countriesSelect = countries.map((item) => ({ label: item.name, value: item.code }));
    }
    return countriesSelect;
  }

  convertCountryCodesToSelectList(countries: string[], all: Array<SelectOptionType>): Array<CountrySelectType> {
    const countriesSelect: Array<SelectOptionType> = [];
    if (countries) {
      for (const countryCode of countries) {
        const countryName = all.filter((item) => item.value === countryCode.toUpperCase());
        if (all.length > 0) {
          countriesSelect.push(countryName[0]);
        }
      }
    }
    return countriesSelect;
  }
}
