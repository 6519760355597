/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import MENU from 'helpers/constants/menuConstants';
import SETTINGS from 'helpers/constants/generalSettings';
import { AlbumsType, AlbumType, AlbumPaginationType } from 'types/album';
import ListResults from 'components/UI/table/Results';
import { saveWithExpiry, getWithExpiry } from 'helpers/utils/storageSaver';

import ActionButton from 'components/UI/buttons/actionButton';
import AlbumService from 'services/album';
import { trackScrolling } from 'helpers/utils/scroll';

import styles from '../albumManagement.module.scss';
import innerStyles from './Albums.module.scss';
import AlbumItem from '../AlbumItem';

type ProfileProps = {
  userId: string;
}

type ProfileState = {
  redirectToAlbum: boolean;
  albumDetails: AlbumsType[];
  pagination: AlbumPaginationType;
  albums: AlbumType[];
  albumUrl: string;
  portionLoading: boolean;
  isAlbumVisible: boolean;
  currentPage: number;
  selectedAlbumName: string;
}

const PER_PAGE = SETTINGS.ALBUM_MANAGEMENT.ITEMS_PER_PAGE;

export default class UserManagementAlbums extends React.Component<ProfileProps, ProfileState> {
  albumService: AlbumService;

  AlbumContainerId: string;

  constructor(props: ProfileProps) {
    super(props);
    this.state = {
      redirectToAlbum: false,
      albumDetails: [],
      albums: [],
      albumUrl: '',
      isAlbumVisible: false,
      portionLoading: false,
      pagination: { offset: 0, total: 1, size: 1 },
      currentPage: 1,
      selectedAlbumName: '',
    };
    this.AlbumContainerId = 'albumContainer';
    this.albumService = new AlbumService();
    this.handleAlbumEnter = this.handleAlbumEnter.bind(this);
    this.handleShowAlbums = this.handleShowAlbums.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount(): void {
    this.loadUserAlbums();
    this.checkShowAlbums();
    document.addEventListener('scroll', () => { trackScrolling(this.AlbumContainerId, this.handleScroll); });
  }

  componentDidUpdate(prevProps): void {
    const { userId } = this.props;
    if (userId !== prevProps.userId) {
      this.setState({ albums: [], isAlbumVisible: false });
      this.loadUserAlbums();
    }
  }

  componentWillUnmount(): void {
    document.removeEventListener('scroll', () => { trackScrolling(this.AlbumContainerId, this.handleScroll); });
  }

  handleScroll(): void {
    const { currentPage, pagination } = this.state;
    if (currentPage * PER_PAGE > pagination.total) {
      document.removeEventListener('scroll', () => { trackScrolling(this.AlbumContainerId, this.handleScroll); });
      return;
    }
    // load next portion from backend
    this.setState((prevState) => ({
      ...prevState,
      currentPage: prevState.currentPage + 1,
      portionLoading: true,
    }));
    this.loadUserAlbums();
  }

  async loadUserAlbums(): Promise<any> {
    const { userId } = this.props;
    const { currentPage } = this.state;
    const response = await this.albumService.getUserAlbums(userId, currentPage, PER_PAGE);
    if (response.data !== undefined && response.data.length > 0) {
      this.setState({
        albumDetails: response.data,
        pagination: response.pagination,
        portionLoading: false,
      });
      this.addToAlbums(this.albumService.getFormattedAlbums(response.data));
    }
  }

  addToAlbums(newAlbums: AlbumType[]): void {
    this.setState((prevState) => ({
      ...prevState,
      albums: prevState.albums ? [...prevState.albums.concat(newAlbums)] : prevState.albums,
    }));
  }

  handleAlbumEnter(albumId: string, albumName: string): void {
    const { userId } = this.props;
    const path = `${MENU.ADMIN_MAIN.ALBUM_MANAGEMENT.split(':')[0]}:${userId}/:${albumId}`;
    this.setState({ albumUrl: path, redirectToAlbum: true, selectedAlbumName: albumName });
  }

  checkShowAlbums(): void {
    const { userId } = this.props;
    const savedKey = getWithExpiry(`UserShowAlbums-${userId}`);
    if (savedKey !== '' && savedKey === userId) {
      this.setState({ isAlbumVisible: true });
    }
  }

  handleShowAlbums(): void {
    const { userId } = this.props;
    saveWithExpiry(`UserShowAlbums-${userId}`, userId, 10);
    this.setState({ isAlbumVisible: true });
  }

  render(): React.ReactNode {
    const {
      redirectToAlbum,
      albumUrl,
      albums,
      isAlbumVisible,
      selectedAlbumName,
    } = this.state;
    const width = {
      xs: 12,
      sm: 6,
      md: 4,
      lg: 4,
    };

    const AlbumsEmpty = (<Col className={styles.userAlbumsEmpty}><ListResults notice="No albums" customRowStyle={{ margin: '0', padding: '9px', height: '45px' }} /></Col>);
    const AlbumItems = albums.length > 0 ? albums.map((item) => {
      const { userId } = this.props;
      return (
        <Col key={item.id} xs={width.xs} sm={width.sm} md={width.md} lg={width.lg} className={styles.userAlbumsCol}>
          <AlbumItem
            id={item.id}
            title={item.name}
            source={item.url}
            level={item.level}
            userId={userId}
            sharedUserId={item.sharedUserId}
            sharedUserName={item.sharedUserName}
            isHidden={item.isHidden}
            onAlbumEnter={(): void => { this.handleAlbumEnter(item.id, item.name); }}
          />
        </Col>
      );
    }) : AlbumsEmpty;

    if (redirectToAlbum) {
      return (
        <Redirect
          to={{ pathname: albumUrl, state: { albumName: selectedAlbumName } }}
        />
      );
    }

    const AlbumsList = (): React.ReactElement => (
      <Row className="mt-2 mb-2">
        <Col>
          <Row id={this.AlbumContainerId} className={styles.userAlbumsRow}>
            {AlbumItems}
          </Row>
        </Col>
      </Row>
    );
    const AlbumsButton = (): React.ReactElement => (
      <div className={innerStyles.showAlbums}>
        <ActionButton title="Show albums" onClick={this.handleShowAlbums} isTextBold />
      </div>
    );

    const AlbumsContent = isAlbumVisible ? AlbumsList() : AlbumsButton();

    return (
      <Container fluid>
        {AlbumsContent}
      </Container>
    );
  }
}
