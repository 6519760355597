import { toast, ToastOptions, ToastContent } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UI_CONTSTANTS from 'helpers/constants/uiConstants';

export type ToastLevelType = 'warn' | 'info' | 'error' | 'success';

interface RenderSettings {
    message: string;
    level: ToastLevelType;
    containerId: string | number;
    toastProperties: ToastOptions;
}

const renderNotification = (
  message: string, level: ToastLevelType, containerId: string | number, toastProperties: ToastOptions,
): ToastContent => {
  let toastTypeMessanger = toast.error;
  switch (level) {
    case UI_CONTSTANTS.NOTIFY_LEVEL.SUCCESS:
      toastTypeMessanger = toast.success;
      break;
    case UI_CONTSTANTS.NOTIFY_LEVEL.INFO:
      toastTypeMessanger = toast.info;
      break;
    case UI_CONTSTANTS.NOTIFY_LEVEL.WARN:
      toastTypeMessanger = toast.warn;
      break;
    default:
      toastTypeMessanger = toast.error;
  }
  return (
    toastTypeMessanger(
      message,
      {
        containerId,
        ...toastProperties,
      },
    )
  );
};

export const showNotification = (data: RenderSettings): void => {
  if (!data || data.message === '') { return; }
  renderNotification(data.message, data.level, data.containerId, data.toastProperties);
};

export const closeNotification = (id? : string | number): void => {
  toast.dismiss(id);
};
