import React from 'react';
import { Row, Col } from 'react-bootstrap';
import FormTextAreaInput from 'components/UI/texts/textArea';
import '../override.scss';

interface MngUserTextRow {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  width?: widthType;
  disabled?: boolean;
}

type widthType = {
    label: number;
    value: number;
}

const MgnUserMultiRow: React.FC<MngUserTextRow> = ({
  label, value, width, onChange, disabled = true,
}) => {
  const widths = width || { value: 12 };
  return (
    <Row>
      <Col xs={widths.value} md={widths.value}>
        <FormTextAreaInput
          controlId={value}
          value={value}
          label={label}
          placeholder=""
          onChange={onChange}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
};

export default MgnUserMultiRow;
