import React from 'react';
import { Container } from 'react-bootstrap';
import CategoryOrderManagement from 'components/Discovery/CategoryOrder';

const DiscoveryCategoriesPage = (): React.ReactElement => (
  <Container fluid>
    <CategoryOrderManagement title="Category order in Discovery" />
  </Container>
);

export default DiscoveryCategoriesPage;
