import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './close.module.scss';

interface ModalCloseProps {
  label?: React.ReactElement;
  onHide: Function;
}

const ModalCustomClose: React.FC<ModalCloseProps> = ({
  label = <span aria-hidden="true">&times;</span>, onHide,
}) => (
  <Row className={styles.detailsClose}>
    <Col>
      <button
        type="button"
        data-dismiss="modal"
        onClick={(): void => { onHide(); }}
        aria-label="Close"
      >
        {label}
      </button>
    </Col>
  </Row>
);

export default ModalCustomClose;
