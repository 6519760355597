import React from 'react';
import { FormGroup } from 'react-bootstrap';
import styles from './checkboxWithInput.module.scss';

interface CheckboxAProps {
  controlId: string;
  placeholder: string;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled: boolean;
  onClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxWithInput: React.FC<CheckboxAProps> = ({
  controlId, checked = false, placeholder, disabled, onClick,
}) => {
  const inputClass = `form-control input-lg ${styles.fieldCheckbox}`;
  return (
    <FormGroup controlId={controlId}>
      <input
        type="text"
        className={inputClass}
        placeholder={placeholder}
      />
      <input
        type="checkbox"
        className={styles.eyeCheckbox}
        checked={checked}
        onChange={onClick}
        disabled={disabled}
      />
    </FormGroup>
  );
};

export default CheckboxWithInput;
