import ActionButton from 'components/UI/buttons/actionButton';
import FormTextInput from 'components/UI/texts/text';
import * as React from 'react';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';

import styles from '../../Crm.module.scss';

interface CrmSearchProps {
    searchAction: (value: string) => void;
    exportAction: () => void;
    importAction: (file: FileList | null) => void;
    itemsCount: number;
}

const CrmSearch: React.FC<CrmSearchProps> = ({ searchAction, exportAction, importAction, itemsCount }) => {
  const [val, setVal] = React.useState('');

  return (
    <Form onSubmit={() => searchAction(val)} noValidate style={{ margin: '20px 0' }}>
      <Row className="justify-content-between">
        <Col md={12}>
          <FormTextInput
            value={val}
            onChange={(e) => setVal(e.target.value)}
            controlId="user-search"
            label="Search"
            type="text"
            placeholder="Enter the username"
            inputIcon="search"
            disabled={false}
          />
        </Col>
        <Col md={12}><div className='mb-3'>Number of users: {itemsCount}</div></Col>
      </Row>
      <Row className="justify-content-between">
        <Col className="d-flex justify-content-left">
          <ActionButton
            title="Show results"
            onClick={() => searchAction(val)}
            disabled={false}
            isTextBold
            customStyle={{ padding: '9px 24px 9px 24px' }}
          />
        </Col>
        <Col style={{ justifyContent: 'right' }} className="d-flex">
          <input
            accept="text/csv"
            onChange={(e) => importAction(e.currentTarget.files)}
            hidden
            type="file"
            id="import"
          />
          <label className={styles.importButton} htmlFor="import">
            Import items
          </label>
          <Button className="ml-2" onClick={() => exportAction()} variant="info">Export items</Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CrmSearch;
