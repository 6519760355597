import React from 'react';
import { Route, HashRouter, Switch } from 'react-router-dom';
import MENU from 'helpers/constants/menuConstants';

// public section
import PageNotFound from 'pages/Public/NotFound';
import PageSignIn from 'pages/Public/SignIn';
import PageNotAuthorized from 'pages/Public/NotAuthorized';

// user section
import DashBoard from 'pages/Dashboard';
import UserManagementPage from 'pages/UserManagement';
import UserVerificationPage from 'pages/UserVerification';

import AlbumManagementPage from 'pages/AlbumManagement';
import UserProfilePage from 'pages/UserProfile';
import ContentMediaPage from 'pages/Content/Media';
import ContentUsersPage from 'pages/Content/Users';
import ContentAlbumsPage from 'pages/Content/Albums';

import DiscoveryCategoriesPage from 'pages/Discovery/Categories';
import ProfilePage from 'pages/Profile';
// const DiscoveryCategoriesPage = lazy(() => import('pages/Discovery/Categories'));

import CRMUsersListPage from 'pages/CRM';
import AuthRoute from './authRoute';
import DefaultRoute from './defaultRoute';
import CRMTagsPage from '../pages/CRM/CRMTags/index';

const Routes = (): React.ReactElement => (
  <HashRouter>
    <Switch>
      <AuthRoute path={MENU.PUBLIC.LOGIN} exact pageComponent={PageSignIn} />
      <AuthRoute path={MENU.PUBLIC.UNAUTHORIZED} pageComponent={PageNotAuthorized} />

      <DefaultRoute path={MENU.ADMIN_MAIN.DASHBOARD} pageComponent={DashBoard} />
      <DefaultRoute path={MENU.ADMIN_MAIN.USER_MANAGEMENT} pageComponent={UserManagementPage} />
      <DefaultRoute path={MENU.ADMIN_MAIN.USER_VERIFICATION} pageComponent={UserVerificationPage} />

      <DefaultRoute path={MENU.ADMIN_MAIN.USER_MANAGEMENT_PROFILE} pageComponent={UserProfilePage} />
      <DefaultRoute path={MENU.ADMIN_MAIN.ALBUM_MANAGEMENT} pageComponent={AlbumManagementPage} />
      <DefaultRoute path={MENU.ADMIN_MAIN.CONTENT_MEDIA} pageComponent={ContentMediaPage} />
      <DefaultRoute path={MENU.ADMIN_MAIN.CONTENT_USERS} pageComponent={ContentUsersPage} />
      <DefaultRoute path={MENU.ADMIN_MAIN.CONTENT_ALBUM} pageComponent={ContentAlbumsPage} />
      <DefaultRoute path={MENU.ADMIN_MAIN.DISCOVERY_CATEGORY_ORDER} pageComponent={DiscoveryCategoriesPage} />
      <DefaultRoute path={MENU.ADMIN_MAIN.CRM_USERS_LIST} pageComponent={CRMUsersListPage} />
      <DefaultRoute path={MENU.ADMIN_MAIN.CRM_TAGS} pageComponent={CRMTagsPage} />

      <DefaultRoute path={MENU.ADMIN_MAIN.PROFILE} pageComponent={ProfilePage} />

      <Route component={PageNotFound} />
    </Switch>
  </HashRouter>
);

export default Routes;
