/* eslint-disable react/no-unused-state */

import React, { MouseEvent } from 'react';
import {
  Modal,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import ActionButton from 'components/UI/buttons/actionButton';
import CancelButton from 'components/UI/buttons/cancelButton';
import styles from './dialog.module.scss';

type DialogProps = {
  title?: string;
  content: string;
  onConfirm: (e?: React.MouseEvent) => void;
  onHide: (e?: React.MouseEvent) => void;
  labelApply?: string;
  labelCancel?: string;
  show: boolean;
}

const DialogConfirm: React.FC<DialogProps> = ({
  title = 'Confirm Dialog',
  labelCancel = 'Cancel',
  labelApply = 'Apply',
  onHide,
  content,
  show,
  onConfirm,
}) => {
  const onHiding = (): void => { onHide(); };
  const titleClass = `justify-content-center ${styles.modalTitle}`;

  const handleApply = (e: MouseEvent): void => {
    e.preventDefault();
    onConfirm();
  };

  const handleClose = (e: MouseEvent): void => {
    e.preventDefault();
    onHide(e);
  };

  return (
    <Modal show={show} onHide={onHiding} className={styles.modal} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton className={styles.modalHeader}>
        <Modal.Title id="contained-modal-title-vcenter" className={titleClass}>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <Container>
          <span>{content}</span>
        </Container>
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <Row className="justify-content-center">
          <Col xs={6} md={6} lg={6} className={styles.modalFooterButtons}>
            <CancelButton title={labelCancel} onClick={handleClose} />
            <ActionButton title={labelApply} onClick={handleApply} />
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default DialogConfirm;
