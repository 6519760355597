import React from 'react';
import MultiSelect from 'components/UI/selects/multiSelect';
import { SelectOptionType } from 'types/form/select';
import styles from './albumTagCategories.module.scss';

// New Category where user selects (for now) one category

interface AlbumCategoryProps {
  options: SelectOptionType[];
  placeholder?: string;
  onCategoryChange: Function;
  values: SelectOptionType[];
  mode: string;
}

class AlbumTagCategories extends React.Component<AlbumCategoryProps, {}> {
  constructor(props: AlbumCategoryProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange = (selected: SelectOptionType[]): void => {
    const { onCategoryChange } = this.props;
    onCategoryChange(selected);
  }

  render(): React.ReactElement {
    const {
      options,
      placeholder = 'select category...',
      values,
      mode = 'apply',
    } = this.props;

    const SelectMode = (
      <MultiSelect
        options={options}
        placeholder={placeholder}
        onChange={(event): void => { this.onChange(event); }}
        value={values}
        isSingleSelect
      />
    );
    const Categories = values && values.length > 0 ? values.map((item) => (
      <div key={item.value} className={styles.categoryViewItem}>{item.label}</div>
    )) : null;
    const ViewMode = (
      <div className={styles.categoryView}>{Categories}</div>
    );

    const Controls = mode === 'edit' ? SelectMode : ViewMode;
    return (
      <>
        {Controls}
      </>
    );
  }
}

export default AlbumTagCategories;
