import React from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import arrowBack from 'assets/form/arrowBack.svg';
import styles from './backButton.module.scss';

interface BackButtonProps {
  title: string;
  pathTo: string;
  additionalParams?: Record<string, any>;
  classType?: string;
}

const BackButton: React.FC<BackButtonProps> = ({
  title, pathTo, additionalParams = undefined,
}) => {
  const params = additionalParams || '';
  return (
    <Button variant="outline-light">
      <NavLink
        to={{ pathname: pathTo, state: params }}
        activeClassName={styles.selectBackActive}
        className={styles.selectBack}
      >
        <span><img alt="back arrow" src={arrowBack} /></span>
        <span className={styles.backTitle}>{title}</span>
      </NavLink>
    </Button>
  );
};

export default BackButton;
