import React from 'react';
import { Container } from 'react-bootstrap';
import CRMUserListComponent from 'components/CRM/UsersList';

const CRMUsersListPage = (): React.ReactElement => (
  <Container fluid>
    <CRMUserListComponent />
  </Container>
);

export default CRMUsersListPage;
